<template>
  <div class="select-inset-label">
    <label>Organization</label>
    <NSelect
      placeholder="Organization"
      v-model:value="$store.state.organization"
      :options="options"
      @update:value="
        (value) => {
          updateOrg(value);
        }
      "
    ></NSelect>
  </div>
</template>

<script>
import { NSelect } from "naive-ui";
import { getNestedOrgs } from "@/Helpers.js";
import router from "@/router";

export default {
  name: "ManageAccount",
  components: { NSelect },
  data() {
    return {
      options: [],
      setOptionsActive: false,
    };
  },
  watch: {
    "$store.state.orgHierarchy": function () {
      if (
        this.$store.state.organizationOptions &&
        this.$store.state.organizationOptions.length > 0 &&
        this.$store.state.orgHierarchy &&
        this.$store.state.orgHierarchy.length > 0
      ) {
        this.setOptions();
      }
    },
    "$store.state.organizationOptions": function () {
      if (
        this.$store.state.organizationOptions &&
        this.$store.state.organizationOptions.length > 0 &&
        this.$store.state.orgHierarchy &&
        this.$store.state.orgHierarchy.length > 0
      ) {
        this.setOptions();
      }
    },
  },
  methods: {
    updateOrg(newValue) {
      const routeName = router.currentRoute.value.name;

      // define what routes are being redirected by page
      const redirectRoutesOnOrgChange = {
        "tag-consent-details": true,
        "tag-consent-tag-details": true,
        "tag-consent-site-details": true,
        "platform-governance-details": true,
        "site-detail": true,
        violations: true,
        "violations-detail": true,
        "rule-overview": true,
      };

      this.$store.commit("setOrganization", newValue);

      // if this route exists in the redirectRoutesOnOrgChange obj
      // then they are redirecting from the page iteself
      // and does not need to force a reload
      // we if are unable to find the route in the above object
      // then force a reload
      if (!redirectRoutesOnOrgChange[routeName]) {
        location.reload();
      }
    },
    setOptions() {
      // don't run this function if it's already running
      if (this.setOptionsActive) {
        return;
      }
      this.setOptionsActive = true;
      const orgHierarchy = this.$store.state.orgHierarchy;
      const organizationOptions = this.$store.state.organizationOptions;
      const divider = "_ADTECH_SORT_DIVIDER_";
      let combined = [];

      // combine orgHierarchy and organizationOptions into one array
      // and add styling options and sort id
      organizationOptions.map((org, index) => {
        let nestedObj = getNestedOrgs(org, organizationOptions, orgHierarchy);
        let reversedNestedArray = [].concat(nestedObj.nested_array).reverse();
        const sort_id = reversedNestedArray.join(divider);
        combined[index] = {
          value: nestedObj.value,
          label: nestedObj.label,
          parent_orgid: nestedObj.parent_orgid,
          sort_id: sort_id,
          style: {
            padding: `0 0 0 ${nestedObj.nested_array.length * 10}px`,
          },
        };
      });

      // nested formating
      let sortedOptions = this.sortArrayOfOptions(combined);
      this.options = sortedOptions;
      this.setOptionsActive = false;
    },
    sortArrayOfOptions(array) {
      return array.sort((a, b) => {
        const sortA = a.sort_id.toUpperCase(); // ignore upper and lowercase
        const sortB = b.sort_id.toUpperCase(); // ignore upper and lowercase
        if (sortA < sortB) {
          return -1;
        }
        if (sortA > sortB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    },
  },
  mounted() {
    if (
      this.$store.state.organizationOptions &&
      this.$store.state.organizationOptions.length > 0 &&
      this.$store.state.orgHierarchy &&
      this.$store.state.orgHierarchy.length > 0
    ) {
      this.setOptions();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
</style>
