<template>
  <FilterDropdown
    id="media-performance-filter"
    :filtersAppliedCount="filtersAppliedCount"
    :returnFiltersToDefault="this.returnFiltersToDefault"
    :disabled="this.loading"
  >
    <div class="media-performance-filters">
      <div class="select-with-label">
        <label>Time Period</label>
        <NSelect
          placeholder="All"
          :value="activeDateRange ? activeDateRange.rangeValue : 'All'"
          :options="formatDateRanges(dateRanges)"
          :on-update:value="updateDateRange"
        ></NSelect>
      </div>
      <div class="select-with-label">
        <label>Advertiser</label>
        <NSelect
          placeholder="All"
          :value="activeAdvertiser ? activeAdvertiser.name : 'All'"
          :options="formatOtherFilters(advertisers)"
          :on-update:value="updateAdvertisers"
        ></NSelect>
      </div>
      <div class="select-with-label">
        <label> Insertion Order </label>
        <NSelect
          placeholder="All"
          :value="activeInsertionOrder ? activeInsertionOrder.name : 'All'"
          :options="formatOtherFilters(insertionOrders)"
          :render-option="renderOptions"
          :on-update:value="updateInsertionOrder"
        ></NSelect>
      </div>
    </div>
  </FilterDropdown>
</template>

<script>
import { NSelect, NTooltip } from "naive-ui";
import { h } from "vue";
import moment from "moment";
import FilterDropdown from "@/components/Shared/FilterDropdown.vue";

export default {
  name: "MediaPerformanceFilters",
  components: {
    NSelect,
    FilterDropdown,
  },
  props: {
    dateRanges: {
      type: Array,
      required: true,
    },
    activeDateRange: {
      type: Object,
      required: false,
    },
    updateDateRange: {
      type: Function,
      required: true,
    },
    advertisers: {
      type: Array,
      required: false,
    },
    activeAdvertiser: {
      type: Object,
      required: false,
    },
    updateAdvertisers: {
      type: Function,
      required: true,
    },
    insertionOrders: {
      type: Array,
      required: false,
    },
    activeInsertionOrder: {
      type: Object,
      required: false,
    },
    updateInsertionOrder: {
      type: Function,
      required: true,
    },
    returnFiltersToDefault: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    renderOptions({ node, option }) {
      if (option.label === "All") {
        return node;
      } else {
        return h(
          NTooltip,
          { class: "insertion-order-tooltip" },
          {
            trigger: () => node,
            default: () => `${option.label}`,
          }
        );
      }
    },
    formatDateRanges(array) {
      return array
        .map((item) => {
          return {
            ...item,
            label: moment(item.rangeValue).format("MMMM YYYY"),
            value: item.rangeValue,
          };
        })
        .reverse();
    },
    formatOtherFilters(array) {
      array.sort((a, b) => a.name.localeCompare(b.name));
      return [
        { label: "All", value: "All" },
        ...array.map((item) => {
          return {
            ...item,
            label: item.name,
            value: item.name,
          };
        }),
      ];
    },
  },
  computed: {
    filtersAppliedCount() {
      let filtersAppliedCount = 0;
      if (
        this.activeDateRange &&
        this.activeDateRange.rangeValue !==
          this.dateRanges[this.dateRanges.length - 1].rangeValue
      ) {
        filtersAppliedCount = filtersAppliedCount + 1;
      }
      if (this.activeAdvertiser) {
        filtersAppliedCount = filtersAppliedCount + 1;
      }
      if (this.activeInsertionOrder) {
        filtersAppliedCount = filtersAppliedCount + 1;
      }
      return filtersAppliedCount;
    },
  },
  data() {
    return {
      timePeriodOptions: [
        {
          label: "October 2023",
          value: "10/23",
        },
        {
          label: "September 2023",
          value: "09/23",
        },
      ],
      advertiserOptions: [
        {
          label: "All",
          value: "all",
        },
        {
          label: "Example #1",
          value: "example-1",
        },
      ],
      insertionOrderOptions: [
        {
          label: "All",
          value: "all",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.media-performance-filters {
  width: 100%;

  .info-icon {
    height: 14px;
    width: 14px;
    display: inline-block;
    position: relative;
    top: 2px;
    margin-left: 5px;
  }
}
</style>
