<template>
  <div class="progress-bar">
    <div class="fill" :style="getInlineStyle()"></div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    percent: {
      type: Number,
      required: true,
    },
    animate: {
      type: Boolean,
    },
    highNumBad: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    getColor(highNumBad, score) {
      const hexCodes = {
        green: "#76ae00",
        yellow: "#e0c019",
        orange: "#f45d29",
        red: "#d1001f",
      };
      let color = {};

      if (highNumBad) {
        if (score === 0) {
          color = { hex: hexCodes.green, name: "green" };
        } else if (score <= 10) {
          color = { hex: hexCodes.orange, name: "orange" };
        } else {
          color = { hex: hexCodes.red, name: "red" };
        }
      } else {
        if (score > 10 && score <= 50) {
          color = { hex: hexCodes.orange, name: "orange" };
        } else if (score > 50 && score <= 90) {
          color = { hex: hexCodes.yellow, name: "yellow" };
        } else if (score > 90) {
          color = { hex: hexCodes.green, name: "green" };
        } else {
          color = { hex: hexCodes.red, name: "red" };
        }
      }

      return color;
    },
    getInlineStyle() {
      const colorObj = this.getColor(this.highNumBad, this.percent);
      return {
        width: `${this.percent}%`,
        "background-color": colorObj.hex,
        transition: this.animate ? "width 0.3s" : "none",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
.progress-bar {
  background-color: $light-gray;
  height: 5px;
  width: 100%;

  .fill {
    height: 5px;
    width: 0px;
    transition: $transition width;
  }
}
</style>
