<template>
  <div class="widget-collection governance-scoreboard widget full">
    <div class="title-with-btn">
      <h2 class="title">Governance Scoreboard</h2>
      <router-link
        :to="`/platform-governance/rule-overview?org=${encodeURIComponent(
          this.$store.state.organization
        )}`"
        class="button"
      >
        Rule Overview
      </router-link>
    </div>
    <div class="scores">
      <div
        class="score"
        v-for="(category, index) in categories"
        :key="category.rulecategory"
      >
        <h4>{{ category.rulecategory }}</h4>
        <LinkWithIcon
          v-if="NODE_ENV === 'demo'"
          icon="link"
          :right="true"
          text="Manage Rules"
          link="/"
        />
        <BigNumber :number="category.score + '%'" />
        <LinkWithIcon
          v-if="NODE_ENV === 'demo'"
          icon="link"
          :right="true"
          text="View Violations"
          link="/"
        />
        <Delta
          v-if="previousCategories[index]"
          :past_count="previousCategories[index].score"
          :current_count="category.score"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AdtechService from "@/services/AdtechService";

// fixtures
import ruleCategoriesCurrentFixture from "@/fixtures/ruleCategoriesCurrent.json";
import ruleCategoriesPastFixture from "@/fixtures/ruleCategoriesPast.json";
import ruleOverviewFixture from "@/fixtures/ruleOverview.json";

import BigNumber from "@/components/Shared/BigNumber.vue";
import Delta from "@/components/Shared/Delta.vue";
import LinkWithIcon from "@/components/Shared/LinkWithIcon.vue";

export default {
  name: "GovernanceScoreboard",
  components: {
    BigNumber,
    Delta,
    LinkWithIcon,
  },
  props: ["scans"],
  data() {
    return {
      NODE_ENV: process.env.NODE_ENV,
      categories: [],
      previousCategories: [],
    };
  },
  methods: {
    calcScores(data) {
      let totalScore = 0;

      const categoriesArray = data.map((cat, index) => {
        const denominator = cat.pass_count + cat.fail_count;
        const score =
          denominator > 0
            ? Math.round(
                (cat.pass_count / (cat.pass_count + cat.fail_count)) * 100
              )
            : 0;
        totalScore = totalScore + score;
        return {
          score: score,
          rulecategory:
            process.env.NODE_ENV === "demo"
              ? ruleOverviewFixture[index].name
              : cat.rulecategory,
        };
      });
      totalScore =
        categoriesArray.length > 0
          ? Math.round(totalScore / categoriesArray.length)
          : 0;
      categoriesArray.push({
        score: totalScore,
        rulecategory: "Overview",
      });

      return categoriesArray;
    },
    getCategories() {
      // TODO: error handling at component level
      if (this.scans && this.scans[0] && process.env.NODE_ENV !== "demo") {
        AdtechService.call("reports.scanRuleCategories", {
          scanid: this.scans[0].scanid,
        }).then((response) => {
          const categoriesArray = this.calcScores(
            response.result.ruleCategories
          );
          this.categories = categoriesArray;
        });
      }

      if (process.env.NODE_ENV === "demo") {
        const categoriesArray = this.calcScores(ruleCategoriesCurrentFixture);
        this.categories = categoriesArray;
      }
    },
    getPreviousCategories() {
      if (this.scans && this.scans[1] && process.env.NODE_ENV !== "demo") {
        AdtechService.call("reports.scanRuleCategories", {
          scanid: this.scans[1].scanid,
        }).then((response) => {
          const categoriesArray = this.calcScores(
            response.result.ruleCategories
          );
          this.previousCategories = categoriesArray;
        });
      }

      if (process.env.NODE_ENV === "demo") {
        const categoriesArray = this.calcScores(ruleCategoriesPastFixture);
        this.previousCategories = categoriesArray;
      }
    },
  },
  mounted() {
    this.getCategories();
    this.getPreviousCategories();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
.scores {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid $light-gray;
  border-radius: $border-radius-1;
  margin-top: 20px;
  text-align: center;

  .score {
    flex: 1 0 16.666%;
    box-shadow: none;
    border-radius: 0px;
    padding: 0px;
    grid-column: span 6;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;

    &:last-child {
      .big-number {
        background-color: $navy;
        color: $white;
      }
    }

    h4 {
      font-size: 18px;
      margin: 0;
    }

    .big-number {
      max-width: 130px;
      width: calc(100% - 60px);
      margin-top: 10px;
      margin-bottom: 10px;
    }

    @include media(1480px, down) {
      flex: 0 0 33.333%;
    }

    @include media($medium, down) {
      flex: 0 0 50%;
    }

    @include media($small-highest, down) {
      flex: 0 0 33%;
    }

    @include media($small, down) {
      flex: 0 0 50%;
    }

    @include media($x-small-highest, down) {
      flex: 0 0 100%;

      .big-number {
        width: 100%;
      }
    }
  }
}
</style>
