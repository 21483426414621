import axios from "axios";
import store from "@/store/index.js";
const serviceBaseUrl = process.env.VUE_APP_ADTECH_SERVICE_BASE_URL;

export default {
	async call(endpoint, data) {
		const apiCall = await axios.post(
			serviceBaseUrl,
			{
				jsonrpc: "2.0",
				method: endpoint,
				params: data,
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + store.state.userData.accessToken,
				},
			}
		);
		return apiCall.data;
	},

	async httpDownload(endpoint, data, filename) {
		const urlNoJsonrpc = serviceBaseUrl.replace("/jsonrpc", "");

		return axios({
			url: `${urlNoJsonrpc}/${endpoint}`,
			method: "POST",
			responseType: "blob",
			data: data,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + store.state.userData.accessToken,
			},
		}).then((apiCall) => {
			const url = window.URL.createObjectURL(new Blob([apiCall.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", filename);
			document.body.appendChild(link);
			return link.click();
		});
	},

	async getFilterOptions(orgId, scanId) {
		console.log("Getting filter options for org:", orgId, "scan:", scanId);

		if (process.env.NODE_ENV === "demo") {
			console.log("Demo mode - returning mock filter options");
			return {
				result: {
					data: [
						{
							dimension: "Agency",
							values: ["zenith", "publicis", "dentsu"],
						},
						// ... other mock data
					],
				},
			};
		}

		try {
			const response = await this.call("scanentities.listDimensions", {
				orgid: orgId,
				scanid: scanId,
			});

			console.log("Filter options API raw response:", response);

			if (response.error) {
				console.error("Error in getFilterOptions:", response.error);
				return { error: response.error };
			}

			// Transform the response
			const dimensions = Object.entries(response.result).map(
				([dimension, values]) => ({
					dimension,
					values,
				})
			);

			console.log("Transformed dimensions:", dimensions);

			return {
				result: {
					data: dimensions,
				},
			};
		} catch (error) {
			console.error("Error in getFilterOptions:", error);
			return { error };
		}
	},

	async getScanRuleRuns(scanId, fields = [], filter = {}) {
		if (process.env.NODE_ENV === "demo") {
			try {
				const demoData = await import("@/fixtures/ruleRuns.json");
				return demoData.default;
			} catch (error) {
				console.error("Error loading demo data:", error);
				return { error };
			}
		}

		try {
			const response = await this.call("reports.custom.scanRuleRuns", {
				scanid: scanId,
				fields,
				filter,
			});

			if (response.error) {
				console.error("Error fetching rule runs:", response.error);
				return { error: response.error };
			}

			return response;
		} catch (error) {
			console.error("Error in getScanRuleRuns:", error);
			return { error };
		}
	},

	async getScanRuleRunsAggregate(scanId, filter = {}, groupBy = []) {
		if (process.env.NODE_ENV === "demo") {
			try {
				const demoData = await import("@/fixtures/ruleRunsAggregate.json");
				return demoData.default; 
			} catch (error) {
				console.error("Error loading demo data:", error);
				return { error };
			}
		}

		try {
			const response = await this.call("reports.custom.scanRuleRunsAggregate", {
				scanid: scanId,
				filter,
				groupBy,
			});

			if (response.error) {
				console.error("Error fetching rule runs aggregate:", response.error);
				return { error: response.error };
			}

			return response;
		} catch (error) {
			console.error("Error in getScanRuleRunsAggregate:", error);
			return { error };
		}
	},
};
