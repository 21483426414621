<template>
  <div :class="getClasses()" :id="`${id}-container`">
    <NTooltip placement="bottom" trigger="manual" :show="showTooltip">
      <template #trigger>
        <button
          class="button white-btn"
          :id="`${id}-trigger`"
          @click="handleUpdateShow"
        >
          <DisplaySvg name="filter" class="filter-icon" />
          Filter
          <span v-if="filtersAppliedCount > 0 && !this.disabled">
            &nbsp;({{ filtersAppliedCount }})
          </span>
        </button>
      </template>
      <div class="dropdown-inputs" :id="`${id}-children`">
        <div class="dropdown-header" v-if="this.returnFiltersToDefault">
          <span
            >{{ filtersAppliedCount }} filter<span
              v-if="filtersAppliedCount !== 1"
              >s</span
            >
            selected</span
          >
          <button
            class="button-tiny white-btn"
            @click="restFilters"
            v-if="filtersAppliedCount > 0 && !this.disabled"
          >
            Reset
          </button>
        </div>
        <!-- children -->
        <slot />
      </div>
    </NTooltip>
  </div>
</template>

<script>
import { NTooltip } from "naive-ui";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";

export default {
  name: "FilterDropdown",
  components: {
    NTooltip,
    DisplaySvg,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    filtersAppliedCount: {
      type: Number,
      required: false,
    },
    returnFiltersToDefault: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      showTooltip: false,
    };
  },
  methods: {
    restFilters() {
      document.body.removeEventListener("click", this.closeTooltip);
      this.showTooltip = false;
      this.returnFiltersToDefault();
    },
    getClasses() {
      return {
        "filter-dropdown": true,
        disabled: this.disabled,
      };
    },
    didClickComeFromWithinDropdown(e) {
      let container = document.getElementById(`${this.id}-container`);
      let childrenContainer = document.getElementById(`${this.id}-children`);
      let child = e.target;
      return (
        (container && container.contains(child)) ||
        (childrenContainer && childrenContainer.contains(child))
      );
    },
    didClickComeFromDropdownTrigger(e) {
      let triggerContainer = document.getElementById(`${this.id}-trigger`);
      let child = e.target;
      return triggerContainer && triggerContainer.contains(child);
    },
    handleUpdateShow(e) {
      const clickCameFromWithin = this.didClickComeFromWithinDropdown(e);
      const clickCameFromTrigger = this.didClickComeFromDropdownTrigger(e);

      if (
        (this.showTooltip && !clickCameFromWithin) ||
        (this.showTooltip && clickCameFromTrigger)
      ) {
        document.body.removeEventListener("click", this.closeTooltip);
        this.showTooltip = false;
      } else if (!this.showTooltip) {
        document.body.addEventListener("click", this.closeTooltip, true);
        this.showTooltip = true;
      }
    },
    closeTooltip(e) {
      const clickCameFromWithin = this.didClickComeFromWithinDropdown(e);
      const clickCameFromTrigger = this.didClickComeFromDropdownTrigger(e);

      if (this.showTooltip && !clickCameFromWithin && !clickCameFromTrigger) {
        document.body.removeEventListener("click", this.closeTooltip);
        this.showTooltip = false;
      }
    },
  },
  beforeUnmount() {
    document.body.removeEventListener("click", this.closeTooltip);
  },
};
</script>

<style lang="scss">
@import "@/styles/_helpers.scss";
.filter-dropdown {
  display: inline-block;
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  .filter-icon {
    width: 20px;
    height: 20px;
    color: $navy;
    margin: 0 auto;
  }
  .button {
    > div {
      margin-right: 10px;
      height: 12px;
      width: 16px;
    }
  }
}
</style>
