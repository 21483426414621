<template>
  <div class="ellipsis-dropdown">
    <NTooltip placement="bottom" trigger="manual" :show="showTooltip">
      <template #trigger>
        <DisplaySvg
          name="ellipsis"
          class="ellipsis-icon"
          @click="handleUpdateShow"
        />
      </template>
      <div class="dropdown-links">
        <div class="score" v-for="option in options" :key="option.key">
          <router-link
            v-if="option.link"
            :to="option.link"
            :class="getClasses(option)"
          >
            {{ option.text }}
          </router-link>
          <div
            v-if="option.onClick"
            @click="tooltipClick(option)"
            :class="getClasses(option)"
          >
            {{ option.text }}
          </div>
        </div>
      </div>
    </NTooltip>
  </div>
</template>

<script>
import { NTooltip } from "naive-ui";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";

export default {
  name: "EllipsisDropdown",
  components: {
    NTooltip,
    DisplaySvg,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showTooltip: false,
    };
  },
  methods: {
    handleUpdateShow() {
      if (this.showTooltip) {
        document.body.removeEventListener("click", this.closeTooltip);
        this.showTooltip = false;
      } else {
        document.body.addEventListener("click", this.closeTooltip, true);
        this.showTooltip = true;
      }
    },
    tooltipClick(option) {
      this.showTooltip = false;
      document.body.removeEventListener("click", this.closeTooltip);
      option.onClick();
    },
    closeTooltip() {
      document.body.removeEventListener("click", this.closeTooltip);
      this.showTooltip = false;
    },
    getClasses(option) {
      let classes = { "ellipsis-link": true };
      if (option.class) {
        classes[option.class] = true;
      }
      return classes;
    },
  },
  beforeUnmount() {
    document.body.removeEventListener("click", this.closeTooltip);
  },
};
</script>

<style lang="scss">
@import "@/styles/_helpers.scss";
.ellipsis-dropdown {
  .ellipsis-icon {
    width: 20px;
    height: 20px;
    color: $navy;
    margin: 0 auto;
  }
}
.ellipsis-link {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  color: $navy;
  cursor: pointer;
  transition: color $transition;
  @include hover {
    color: $blue;
  }
}
</style>
