<template>
  <div class="HorizontalBarGraphic">
    <div
      v-for="item in computedBarData"
      :key="item.value"
      :class="getClasses(item)"
      :style="computedTotal != 0 ? `width: ${(item.value / computedTotal) * 100}%` : `width: 100%`"
    >
    <span>{{ computedTotal === 0 ? '0 Violations' : `${item.value} ${item.name}` }}</span>
    </div>
  </div>
  <div class="pill-buttons">
    <Pill
      v-for="item in computedBarData"
      :key="item.value"
      link="/"
      :text="`${item.value} ${item.name}`"
      :color="`${item.color}`"
      className="bar-label-pill"
    />
  </div>
</template>

<script>
import Pill from "@/components/Shared/Pill.vue";

const DEMO_DATA = [
  {
    value: 430,
    color: "yellow",
    name: "Low",
  },
  {
    value: 235,
    color: "orange",
    name: "Medium",
  },
  {
    value: 102,
    color: "red",
    name: "Critical",
  },
];

export default {
  name: "HorizontalBarGraphic",
  components: { Pill },
  props: {
    barData: {
      type: Array,
      default: null
    },
    total: {
      type: Number,
      default: null
    }
  },
  computed: {
    isDemoEnv() {
      return process.env.NODE_ENV === 'demo';
    },
    computedBarData() {
      if (this.isDemoEnv || !this.barData) {
        return DEMO_DATA;
      }
      return this.barData;
    },
    computedTotal() {
      if (this.total !== null) {
        return this.total;
      }
      return this.computedBarData.reduce((sum, item) => sum + item.value, 0);
    }
  },
  methods: {
    getClasses(item) {
      let classes = { item: true };
      if (this.computedTotal === 0) {
        classes['bar-navy'] = true;
      } else if (item.color) {
        classes[`bar-${item.color}`] = true;
      }
      return classes;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/_helpers.scss";
.HorizontalBarGraphic {
  display: flex;
  height: 26px;
  color: $navy;
  margin: 20px 0 20px 0;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    span {
      background: $white;
      padding: 2px 8px;
      border-radius: 20px;
      opacity: 0;
      transition: opacity 0.3s;

      font-size: 13px;
      line-height: 12px;
      white-space: nowrap;
      border: 1px solid $navy;
      position: relative;
      top: -16px;
    }

    @include hover {
      span {
        opacity: 1;
      }
    }
  }

  .bar-red {
    background-color: $red;
  }
  .bar-orange {
    background-color: $orange;
  }
  .bar-yellow {
    background-color: $yellow;
  }
  .bar-green {
    background-color: $green;
  }
  .bar-blue {
    background-color: $blue;
  }
  .bar-navy {
    background-color: $navy;
  }
  .bar-light-blue {
    background-color: $light-blue;
  }
  .bar-teal {
    background-color: $teal;
  }
  .bar-dark-teal {
    background-color: $dark-teal;
  }
}
.pill-buttons {
  margin-bottom: 20px;
  .bar-label-pill {
    margin-right: 20px;
  }
}
</style>
