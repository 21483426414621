<template>
  <div class="view-container download-reports">
    <section class="view-header">
        <div class="view-title">
          Platform Governance &amp; Standards - Improve Compliance Score
        </div>
        <div class="view-sublinks" v-if="!error && !loading">
          <LinkWithIcon
            icon="bar-plus-line-graph-data-up"
            text="Overview"
            link="/platform-governance/overview"
          />
          <LinkWithIcon
            icon="list-ui"
            text="Details"
            :link="`/platform-governance/details?org=${encodeURIComponent(
              this.$store.state.organization
            )}`"
          />
          <LinkWithIcon
            icon="warning"
            text="Violations"
            :link="`/platform-governance/violations?org=${encodeURIComponent(
              this.$store.state.organization
            )}`"
          />
          <LinkWithIcon
            icon="line-graph-data-up"
            text="Improve Score"
            :link="`/platform-governance/improve-score?org=${encodeURIComponent(
              this.$store.state.organization
            )}`"
          />
        </div>
      </section>
    <div class="workarea">
      <div class="widget full">
        <div class="top-content">
          <p>
            Select the desired scan using the provided dropdown. The most recent
            scan is selected by default. Click the download button below the
            desired report to obtain data in a CSV format.
          </p>
          <div class="select-with-label" v-if="scansArray.length > 0">
            <label>Selected Scan</label>
            <NSelect
              :placeholder="formatScanDate(scansArray[0].start_time)"
              :value="selectedScan ? selectedScan.scanid : scansArray[0].scanid"
              :options="formatScansArray(scansArray)"
              :on-update:value="updateSelectedScan"
            ></NSelect>
          </div>
        </div>

        <div className="error-and-loading" v-if="error || loading">
          <div class="site-error" v-if="error || !loading">{{ error }}</div>
          <div class="site-loading" v-if="!error && loading">
            <LoadingSpinner /> Loading...
          </div>
        </div>

        <div class="downloads" v-if="!loading && !error">
          <div class="report">
            <h2>Scan Violations</h2>
            <div>
              <p>
                This report mirrors the usual "rule violations" table, but also
                includes some columns joined from other tables.
              </p>

              <LinkWithIcon
                className="button download-csv-btn"
                icon="download"
                text="Download CSV"
                :loading="loadingScanDownload === 'scan-violations'"
                :onClick="
                  () => {
                    downloadScan('scan-violations');
                  }
                "
              />

              <div
                class="error-scan-download"
                v-if="errorScanDownload.value === 'scan-violations'"
              >
                {{ errorScanDownload.error }}
              </div>
            </div>
          </div>
          <div class="report">
            <h2>Scan Rule Runs</h2>
            <div>
              <p>
                This report mirrors the usual "rule runs" table, but also
                includes some columns joined from other tables.
              </p>

              <LinkWithIcon
                className="button download-csv-btn"
                icon="download"
                text="Download CSV"
                :loading="loadingScanDownload === 'scan-rule-runs'"
                :onClick="
                  () => {
                    downloadScan('scan-rule-runs');
                  }
                "
              />

              <div
                class="error-scan-download"
                v-if="errorScanDownload.value === 'scan-rule-runs'"
              >
                {{ errorScanDownload.error }}
              </div>
            </div>
          </div>
          <div class="report">
            <h2>Containers</h2>
            <div>
              <p>
                This report lists one row per container in the given orgid (and
                suborgs).
              </p>
              <p>This data is not affected by a selected scan.</p>

              <LinkWithIcon
                className="button download-csv-btn"
                icon="download"
                text="Download CSV"
                :loading="loadingScanDownload === 'containers'"
                :onClick="
                  () => {
                    downloadScan('containers');
                  }
                "
              />

              <div
                class="error-scan-download"
                v-if="errorScanDownload.value === 'containers'"
              >
                {{ errorScanDownload.error }}
              </div>
            </div>
          </div>
          <div class="report">
            <h2>Scan Overview</h2>
            <div>
              <p>Provides top-level metrics for a scan.</p>
              <p>
                <strong>Includes:</strong> Number of pass/fail containers,
                number of violations, total severity score, and more.
              </p>

              <LinkWithIcon
                className="button download-csv-btn"
                icon="download"
                text="Download CSV"
                :loading="loadingScanDownload === 'scan-overview'"
                :onClick="
                  () => {
                    downloadScan('scan-overview');
                  }
                "
              />

              <div
                class="error-scan-download"
                v-if="errorScanDownload.value === 'scan-overview'"
              >
                {{ errorScanDownload.error }}
              </div>
            </div>
          </div>
          <div class="report">
            <h2>Scan Rule Categories</h2>
            <div>
              <p>Lists rule categories with metrics for each category.</p>
              <p>
                <strong>Includes:</strong> Container-level stats (number of
                pass/fail/error containers per category) and rule-level stats
                (number of pass/fail/error rules, total violation score).
              </p>

              <LinkWithIcon
                className="button download-csv-btn"
                icon="download"
                text="Download CSV"
                :loading="loadingScanDownload === 'scan-rule-categories'"
                :onClick="
                  () => {
                    downloadScan('scan-rule-categories');
                  }
                "
              />

              <div
                class="error-scan-download"
                v-if="errorScanDownload.value === 'scan-rule-categories'"
              >
                {{ errorScanDownload.error }}
              </div>
            </div>
          </div>
          <div class="report">
            <h2>Scan Containers</h2>
            <div>
              <p>
                Returns a list of containers involved in the scan with specific
                metrics for each.
              </p>
              <p>
                <strong>Includes:</strong> Number of pass/fail/error rules,
                number of violations, total severity score, etc.
              </p>

              <LinkWithIcon
                className="button download-csv-btn"
                icon="download"
                text="Download CSV"
                :loading="loadingScanDownload === 'scan-containers'"
                :onClick="
                  () => {
                    downloadScan('scan-containers');
                  }
                "
              />

              <div
                class="error-scan-download"
                v-if="errorScanDownload.value === 'scan-containers'"
              >
                {{ errorScanDownload.error }}
              </div>
            </div>
          </div>
          <div class="report">
            <h2>Scan Container Tags</h2>
            <div>
              <p>
                Offers stats per container tag (as defined in the
                AnalyticsContainer entity).
              </p>
              <p><strong>Format:</strong> One row per container tag.</p>
              <p>
                <strong>Metrics:</strong> Includes both container-level and
                rule-level stats
              </p>

              <LinkWithIcon
                className="button download-csv-btn"
                icon="download"
                text="Download CSV"
                :loading="loadingScanDownload === 'scan-container-tags'"
                :onClick="
                  () => {
                    downloadScan('scan-container-tags');
                  }
                "
              />

              <div
                class="error-scan-download"
                v-if="errorScanDownload.value === 'scan-container-tags'"
              >
                {{ errorScanDownload.error }}
              </div>
            </div>
          </div>
          <div class="report">
            <h2>Scan Container Rule Categories</h2>
            <div>
              <p>Provides stats per rule category, broken down by container.</p>
              <p>
                <strong>Includes:</strong> One row per rule category per
                container.
              </p>

              <LinkWithIcon
                className="button download-csv-btn"
                icon="download"
                text="Download CSV"
                :loading="
                  loadingScanDownload === 'scan-container-rule-categories'
                "
                :onClick="
                  () => {
                    downloadScan('scan-container-rule-categories');
                  }
                "
              />

              <div
                class="error-scan-download"
                v-if="
                  errorScanDownload.value === 'scan-container-rule-categories'
                "
              >
                {{ errorScanDownload.error }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import moment from "moment";
import { NSelect } from "naive-ui";
import AdtechService from "@/services/AdtechService";
import LinkWithIcon from "@/components/Shared/LinkWithIcon.vue";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";

export default {
  name: "DownloadReports",
  data() {
    return {
      error: null,
      loading: true,
      loadingScanDownload: null,
      errorScanDownload: {},
      scansArray: [],
      selectedScan: null,
    };
  },
  components: {
    LinkWithIcon,
    NSelect,
    LoadingSpinner,
  },
  methods: {
    formatScanDate(date) {
      return moment(date).format("MM/DD/YYYY h:mm a");
    },
    formatScansArray(array) {
      return array.map((item) => {
        return {
          ...item,
          label: this.formatScanDate(item.start_time),
          value: item.scanid,
        };
      });
    },
    updateSelectedScan(value) {
      let selectedScanObj = this.scansArray.find((o) => o.scanid === value);
      this.selectedScan = selectedScanObj;
    },
    downloadScan(value) {
      this.loadingScanDownload = value;

      let apiCall = null;
      let options = {};

      // Scan Violations
      if (value === "scan-violations") {
        apiCall = "reports/scanViolations";
        options.scanid = this.selectedScan.scanid;
      }

      // Scan Rule Runs
      if (value === "scan-rule-runs") {
        apiCall = "reports/scanRuleRuns";
        options.scanid = this.selectedScan.scanid;
      }

      // Containers
      if (value === "containers") {
        apiCall = "reports/containers";
        options.orgid = this.$store.state.organization;
      }

      // Scan Overview
      if (value === "scan-overview") {
        apiCall = "reports/scanOverview";
        options.scanid = this.selectedScan.scanid;
      }

      // Scan Rule Categories
      if (value === "scan-rule-categories") {
        apiCall = "reports/scanRuleCategories";
        options.scanid = this.selectedScan.scanid;
      }

      // Scan Containers
      if (value === "scan-containers") {
        apiCall = "reports/scanContainers";
        options.scanid = this.selectedScan.scanid;
      }

      // Scan Container Tags
      if (value === "scan-container-tags") {
        apiCall = "reports/scanContainerTags";
        options.scanid = this.selectedScan.scanid;
      }

      // Scan Container Rule Categories
      if (value === "scan-container-rule-categories") {
        apiCall = "reports/scanContainerRuleCategories";
        options.scanid = this.selectedScan.scanid;
      }

      if (apiCall) {
        let filename = `${value}_${moment(this.selectedScan.start_time).format(
          "MM-DD-YYYY--h:mma"
        )}.csv`;

        if (value === "containers") {
          filename = `${value}_${this.$store.state.organization}.csv`;
        }
        return AdtechService.httpDownload(
          `${apiCall}`,
          {
            ...options,
          },
          filename
        )
          .then(() => {
            // no response for the download call
            // if no errors assume success
            this.loadingScanDownload = null;
          })
          .catch((error) => {
            this.loadingScanDownload = null;
            return this.triggerError({
              message: `${apiCall} - download report - catch`,
              error: error,
              value: value,
            });
          });
      }
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.loading = false;
      this.errorScanDownload = {
        value: obj.value,
        error: `Oops. Your report download (${obj.value}) was unable to be generated.`,
      };
    },
  },
  watch: {
    "$store.state.scans": function (newScans) {
      this.$nextTick(async () => {
        if (newScans && newScans.error) {
          this.loading = false;
          this.error = newScans.error;
        }

        if (newScans && newScans.length > 0) {
          this.scansArray = newScans;
          this.selectedScan = newScans[0];
          this.loading = false;
        }
      });
    },
    "$store.state.featurePermissions": function () {
      this.$nextTick(async () => {
        if (this.$store.state.featurePermissions) {
          // redirect to home if they do not have this feature enabled
          if (!this.$store.state.featurePermissions.platformGovernance) {
            router.push("/");
          }
        }
      });
    },
    "$store.state.organization": function () {
      if (
        encodeURIComponent(this.$route.query.org) !==
        encodeURIComponent(this.$store.state.organization)
      ) {
        router.push("/platform-governance/overview");
      }
    },
  },
  mounted() {
    if (this.$store.state.scans && this.$store.state.scans.length > 0) {
      this.scansArray = this.$store.state.scans;
      this.selectedScan = this.$store.state.scans[0];
      this.loading = false;
    }

    if (this.$store.state.featurePermissions) {
      // redirect to home if they do not have this feature enabled
      if (!this.$store.state.featurePermissions.platformGovernance) {
        router.push("/");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.download-reports {
  .widget {
    display: block;
  }

  .top-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid $medium-gray;
    p {
      width: calc(100% - 300px);
      @include media($medium, down) {
        width: 100%;
      }
    }
  }

  .error-and-loading {
    margin-top: 40px;
  }

  .error-scan-download {
    border-radius: $border-radius-2;
    background-color: rgba($red, 0.2);
    padding: 10px;
    border: 1px solid $red;
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;
  }

  .downloads {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;

    margin-top: 40px;
    .report {
      background-color: $light-gray;
      border-radius: $border-radius-2;
      border: 1px solid $medium-gray;
      overflow: hidden;

      grid-column: span 1;
      @include media($medium, down) {
        grid-column: span 2;
      }
      @include media($small-highest, down) {
        grid-column: span 1;
      }
      @include media($small, down) {
        grid-column: span 2;
      }

      h2 {
        padding: 15px;
        margin: 0;
        width: 100%;
        background-color: $white;
        font-size: 20px;
        font-weight: 500;
        line-height: 16px;
        border-bottom: 1px solid $medium-gray;
      }
      > div {
        padding: 15px;
      }
    }
  }
}
</style>
