<template>
  <div class="view-container platform-governance-improve">
    <section class="view-header">
      <div class="view-title-with-btn">
        <div class="view-title">
          Platform Governance &amp; Standards - Improve Compliance Score
        </div>
        <PlatformGovernanceFilter
          :loading="loading"
          @filter-change="handleFilterChange"
        />
      </div>
      <div class="view-sublinks" v-if="!error && !loading">
        <LinkWithIcon
          icon="bar-plus-line-graph-data-up"
          text="Overview"
          link="/platform-governance/overview"
        />
        <LinkWithIcon
          icon="list-ui"
          text="Details"
          :link="`/platform-governance/details?org=${encodeURIComponent(
            this.$store.state.organization
          )}`"
        />
        <LinkWithIcon
          icon="warning"
          text="Violations"
          :link="`/platform-governance/violations?org=${encodeURIComponent(
            this.$store.state.organization
          )}`"
        />
        <LinkWithIcon
          icon="line-graph-data-up"
          text="Improve Score"
          :link="`/platform-governance/improve-score?org=${encodeURIComponent(
            this.$store.state.organization
          )}`"
          v-bind:active="true"
        />
      </div>
      <div>
        <LinkWithIcon
          icon="download"
          text="View Report Downloads" 
          className="view-report-downloads-link"
          :link="`/platform-governance/download-reports?org=${encodeURIComponent(
            this.$store.state.organization
          )}`"
        />
      </div>
    </section>

    <div class="workarea" v-if="!error && !loading">
      <!-- Score Widget -->
      <div class="half">
        <div class="widget">
          <div class="title">Compliance Score</div>
          <DoughnutChartScoreHalf
            :score="72"
            size="200"
          />
          <div class="info-section">
            <h3>What is a Compliance Score?</h3>
            <p>
              Compliance Score is a weighted measure of whether or not your platforms are
              conforming to your standards as monitored by AdTech DNA's platform rules, accounting
              for the severity level of each individual rule. The higher your compliance score, the more
              your platforms adheres to best practices and any standards you have set with your AdTech
              DNA team.
            </p>
          </div>
        </div>
      </div>

      <!-- Spend Widget -->
      <div class="half">
        <div class="widget">
          <div class="warning-container">
            <div class="warning-box">
              <DisplaySvg name="warning-solid" class="warning-icon" />
              <span class="warning-amount">$10,419</span>
            </div>
          </div>
          <div class="info-section">
            <h3>What is Misconfigured Spend?</h3>
            <p>
              Misconfigured Spend is an extension of Compliance Score. Specifically for media
              buying platforms, Misconfigured Spend checks the actual spend of non-compliant
              configuration, then adjusts the likely impact based on the severity level of associated
              rules.
            </p>
          </div>
        </div>
      </div>

      <!-- Combined Improvement Info and Severity Pills -->
      <div class="full">
        <div class="widget improvement-widget">
          <div class="improvement-content">
            <div class="text-content">
              <h2>How to improve with AdTech DNA</h2>
              <p>
                To improve your Compliance Score and prevent further Misconfigured Spend,
                ultimately you will need to address the configuration issues flagged by
                AdTech DNA.
              </p>
              <p>
                Use the <a href="#" class="link">Platform Standards and Governance</a> page to help you navigate
                through the platforms and configuration settings that need to be
                addressed and <a href="#" class="link">export reports</a> to share with your media agency.
              </p>
              <p>
                We recommend using the <a href="#" class="link">Violations Overview page sorted by violation
                severity</a> to help highlight the most severe or highest spend-impact issues
                across your connected platforms.
              </p>
            </div>
            <div class="severity-indicators">
              <img src="../../assets/images/improve-score-pills.png" alt="Severity indicators" class="severity-image" />
            </div>
          </div>

          <div class="help-section">
            <DisplaySvg name="chat" class="chat-icon" />
            <div class="help-content">
              <h3>Need Additional Help?</h3>
              <p>Reach out to your InfoTrust advisor for additional help.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading/Error state remains the same -->
    <div class="widget full" v-if="error || loading">
      <div class="site-error" v-if="error && !loading">{{ error }}</div>
      <div class="site-loading" v-if="!error && loading">
        <LoadingSpinner /> Loading...
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
import LinkWithIcon from "@/components/Shared/LinkWithIcon.vue";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import DoughnutChartScoreHalf from "@/components/Shared/DoughnutChartScoreHalf.vue";
import PlatformGovernanceFilter from "@/components/PlatformGovernance/PlatformGovernanceFilter.vue";

export default {
  name: "PlatformGovernanceImproveView",
  components: {
    DisplaySvg,
    LinkWithIcon,
    LoadingSpinner,
    DoughnutChartScoreHalf,
    PlatformGovernanceFilter
  },
  data() {
    return {
      error: null,
      loading: false
    };
  },
  methods: {
    handleFilterChange(filters) {
      // Update store with new filters
      this.$store.dispatch('updatePlatformGovernanceFilters', filters);
    }
  },
  mounted() {
    // Initialize filters
    this.$store.dispatch('initializePlatformGovernanceFilters');

    if (this.$store.state.scans && this.$store.state.scans.error) {
      this.loading = false;
      this.error = this.$store.state.scans.error;
    }

    if (this.$store.state.featurePermissions) {
      if (!this.$store.state.featurePermissions.platformGovernance) {
        router.push("/");
      }
    }
  },
  watch: {
    '$store.state.platformGovernanceFilters': {
      handler(newFilters) {
        console.log('Filters changed:', newFilters);
        // Add any additional filter change handling if needed
      },
      deep: true
    },
    "$store.state.featurePermissions": function () {
      this.$nextTick(async () => {
        if (this.$store.state.featurePermissions) {
          if (!this.$store.state.featurePermissions.platformGovernance) {
            router.push("/");
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.platform-governance-improve {
  .view-title-with-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
  }

  .widget {
    background: $white;
    border-radius: $border-radius-1;
    box-shadow: $shadow;
    padding: 1.5rem;
    height: 100%;

    .widget-title {
      color: $navy;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 1.5rem;
    }
  }

  .half {
    margin-bottom: 1.5rem;
  }

  .info-section {
    background: $light-gray;
    padding: 1.5rem;
    border-radius: $border-radius-2;
    margin-top: 1.5rem;

    h3 {
      color: $navy;
      font-size: 16px;
      font-weight: 500;
      margin: 0 0 1rem;
    }

    p {
      color: $navy;
      font-size: 18px;
      line-height: 1.6;
      margin: 0;
    }
  }

  .warning-container {
    margin-bottom: 5.5rem;
    margin-top: 5.5rem;
    width: 60%;
  }

  .warning-box {
    position: relative;
    background: $yellow-lighter;
    padding: 1.5rem 1rem;
    border-radius: $border-radius-2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    border-top: 5px solid $yellow;

    .warning-icon {
      width: 40px;
      height: 40px;
      color: $yellow;
      margin-right: 1rem;
    }

    .warning-amount {
      font-size: 40px;
      font-weight: 600;
      color: $yellow-darker;
    }
  }

  .improvement-widget {
    .improvement-content {
      display: flex;
      gap: 2rem;
      margin-bottom: 2rem;

      .text-content {
        flex: 1;
        background: $light-gray;
        padding: 1.5rem;
        border-radius: $border-radius-2;

        p {
          color: $navy;
          margin-bottom: 1rem;
        }
      }

      .severity-indicators {
        padding-top: 3rem;
        
        .severity-image {
          max-width: 300px;
          height: auto;
        }
      }
    }
  }

  h2 {
    color: $navy;
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 1.5rem;
  }

  .link {
    color: $blue;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }

  .help-section {
    padding: 1.5rem;
    background-color: $blue-lighter;
    border-radius: $border-radius-2;
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    margin-top: 2rem;
    border-left: 5px solid $blue;

    .chat-icon {
      width: 24px;
      height: 24px;
      color: $navy;
      flex-shrink: 0;
    }

    .help-content {
      h3 {
        color: $navy;
        font-size: 20px;
        font-weight: 600;
        margin: 0;
      }

      p {
        color: $navy;
        font-size: 14px;
        line-height: 1.5;
        margin: 0.5rem 0 0;
      }
    }
  }
}
</style>