<template>
  <div :class="getClasses()">
    <div class="score">
      {{ displayNumber || displayNumber === 0 ? displayNumber : `${score}%` }}
    </div>
    <Doughnut :data="chartData" :options="chartOptions" :style="chartStyles" />
  </div>
</template>

<script>
import "chart.js/auto";
import { Doughnut } from "vue-chartjs";

export default {
  name: "DoughnutChartScore",
  components: {
    Doughnut,
  },
  props: {
    score: {
      type: Number,
      required: true,
    },
    displayNumber: {
      type: Number,
      required: false,
    },
    highNumBad: {
      type: Boolean,
      required: false,
    },
    size: {
      type: String,
      validator(value) {
        return ["large", "medium", "small"].includes(value);
      },
      default: "large",
    },
  },
  methods: {
    getColor(highNumBad, score) {
      const hexCodes = {
        green: "#76ae00",
        yellow: "#e0c019",
        orange: "#f45d29",
        red: "#d1001f",
      };
      let color = {};

      if (highNumBad) {
        if (score === 0) {
          color = { hex: hexCodes.green, name: "green" };
        } else if (score <= 10) {
          color = { hex: hexCodes.orange, name: "orange" };
        } else {
          color = { hex: hexCodes.red, name: "red" };
        }
      } else {
        if (score > 10 && score <= 50) {
          color = { hex: hexCodes.orange, name: "orange" };
        } else if (score > 50 && score <= 90) {
          color = { hex: hexCodes.yellow, name: "yellow" };
        } else if (score > 90) {
          color = { hex: hexCodes.green, name: "green" };
        } else {
          color = { hex: hexCodes.red, name: "red" };
        }
      }

      return color;
    },
    getClasses() {
      const colorObj = this.getColor(this.highNumBad, this.score);
      return {
        graph: true,
        large: this.size === "large",
        medium: this.size === "medium",
        small: this.size === "small",
        greenZero: this.highNumBad && colorObj.name === "green",
      };
    },
  },
  data() {
    const color = this.getColor(this.highNumBad, this.score).hex;
    return {
      chartData: {
        datasets: [
          {
            data: Array.isArray(this.score) ? this.score : [this.score, (this.score - 100) * -1],
            backgroundColor: [color, "#f4f6f6"],
            borderColor: [color, "#f4f6f6"],
            borderWidth: 1,
            rotation: 180,
          },
        ],
      },
      chartOptions: {
        // This chart will not respond to any events
        // events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove']
        events: [],
      },
      chartStyles: { borderJoinStyle: "none" },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.graph {
  &.greenZero {
    position: relative;
    color: $green;
    &::before {
      content: url("data:image/svg+xml,%3Csvg viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.99555 11.65L6.77758 9.5C6.57125 9.3 6.33053 9.20831 6.05542 9.22498C5.78032 9.24164 5.53959 9.34999 5.33327 9.54999C5.12694 9.74999 5.02379 9.98333 5.02379 10.25C5.02379 10.5167 5.12694 10.75 5.33327 10.95L7.5513 13.1C7.96396 13.5 8.44535 13.7 8.99555 13.7C9.54575 13.7 10.0272 13.5 10.4399 13.1L15.5465 8.09998C15.7528 7.89998 15.8559 7.66669 15.8559 7.40002C15.8559 7.13336 15.7528 6.90001 15.5465 6.70001C15.3401 6.50001 15.0994 6.40002 14.8243 6.40002C14.5492 6.40002 14.3085 6.50001 14.1021 6.70001L8.99555 11.65ZM10.4399 20C7.58568 20 5.15273 19.025 3.14105 17.075C1.12936 15.125 0.123535 12.7667 0.123535 10C0.123535 7.23333 1.12936 4.87499 3.14105 2.92499C5.15273 0.974988 7.58568 0 10.4399 0C13.294 0 15.727 0.974988 17.7387 2.92499C19.7504 4.87499 20.7562 7.23333 20.7562 10C20.7562 12.7667 19.7504 15.125 17.7387 17.075C15.727 19.025 13.294 20 10.4399 20Z' fill='%2376ae00'/%3E%3C/svg%3E%0A");
      height: 30px;
      width: 30px;
      right: 8px;
      top: 8px;
      display: block;
      position: absolute;
    }
    > div {
      &:first-child {
        color: $green;
      }
    }
  }
  &.large {
    width: 200px;
    height: 200px;
    position: relative;

    .score {
      letter-spacing: -2px;
      font-size: 38px;
      font-weight: 700;
      width: 97px;
      text-align: center;
      left: 52px;
      position: absolute;
      color: $navy;
      height: 34px;
      line-height: 34px;
      top: 88px;
    }

    @include media($large, down) {
      width: 170px;
      height: 170px;

      .score {
        left: 39px;
        top: 76px;
      }
    }

    @include media($large-lowest, down) {
      width: 200px;
      height: 200px;
      .score {
        left: 52px;
        top: 88px;
      }
    }

    @include media($small-lowest, down) {
      width: 170px;
      height: 170px;
      .score {
        left: 39px;
        top: 76px;
      }
    }
  }

  &.medium {
    width: 120px;
    height: 120px;
    position: relative;

    .score {
      letter-spacing: -1px;
      font-size: 24px;
      font-weight: 700;
      width: 67px;
      text-align: center;
      top: 55px;
      left: 24px;
      position: absolute;
      height: 22px;
      color: $navy;
    }
  }
  &.small {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;

    .score {
      letter-spacing: -2px;
      font-size: 28px;
      font-weight: 700;
      position: relative;
      top: 6px;
      margin-right: 10px;
      color: $navy;
    }
  }
}
</style>
