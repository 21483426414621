<template>
  <div class="delta">
    <div :class="getClasses()">
      {{ deltaData.delta }} ({{ deltaData.difference }})
    </div>
  </div>
</template>

<script>
import { getDelta } from "@/Helpers.js";

export default {
  name: "Deltas",
  props: ["past_count", "current_count", "highNumBad"],
  methods: {
    getClasses() {
      return {
        positive:
          (this.highNumBad && this.deltaData.direction === "negative") ||
          (!this.highNumBad && this.deltaData.direction === "positive")
            ? true
            : false,
        negative:
          (this.highNumBad && this.deltaData.direction === "positive") ||
          (!this.highNumBad && this.deltaData.direction === "negative")
            ? true
            : false,
      };
    },
  },
  data() {
    const deltaData = getDelta(this.current_count, this.past_count);
    return {
      deltaData: deltaData,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.delta {
  margin-top: 5px;
  .negative {
    color: $orange;
  }

  .positive {
    color: $green;
  }

  .neutral {
    color: $yellow;
  }
}
</style>
