<template>
  <div
    :class="`widget leaderboard ${size}`"
    v-if="this.scanContainers && this.scanContainers.length > 0"
  >
    <h2 class="title">
      {{ title }}
    </h2>
    <div class="scores-wrapper">
      <div class="tabs">
        <div
          class="tab"
          :class="{ selected: tab == 'lowest' }"
          @click="tab = 'lowest'"
        >
          Lowest Performers
        </div>
        <div
          class="tab"
          :class="{ selected: tab == 'highest' }"
          @click="tab = 'highest'"
        >
          Highest Performers
        </div>
      </div>
      <div class="containers">
        <div
          class="item"
          v-for="container in filteredScanContainers"
          :key="container.containerid"
        >
          <div class="label">{{ container.name }}</div>
          <ProgressBar :percent="container.complianceScore" :animate="true" />
        </div>
      </div>
      <router-link
        v-if="buttonText != null && buttonLink != null"
        :to="buttonLink"
        class="button"
      >
        {{ buttonText }}
      </router-link>
    </div>
  </div>
</template>

<script>
import AdtechService from "@/services/AdtechService.js";
import ProgressBar from "@/components/Shared/ProgressBar.vue";
import ScanContainersFixture from "@/fixtures/containers";

export default {
  name: "LeaderboardWidget",
  components: { ProgressBar },
  props: {
    size: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    buttonText: { type: String },
    buttonLink: { type: String },
    scans: {},
  },
  data() {
    return {
      scanContainers: [],
      tab: "lowest",
    };
  },
  computed: {
    filteredScanContainers() {
      let filteredContainers = [];
      if (!this.scanContainers || this.scanContainers.length == 0)
        return filteredContainers;

      // calculate the compliance score
      this.scanContainers.forEach((container) => {
        const passCount = parseInt(container.pass_count);
        const failCount = parseInt(container.fail_count);
        const totalAttempts = passCount + failCount;
        filteredContainers.push({
          containerid: container.containerid,
          name: this.$store.getters.getContainerMeta(
            container.containerid,
            "name"
          ),
          complianceScore:
            totalAttempts > 0 ? (passCount / totalAttempts) * 100 : 0,
        });
      });

      // check if we get the bottom 5 or top 5
      if (this.tab === "lowest") {
        filteredContainers.sort(function (a, b) {
          return a.complianceScore - b.complianceScore;
        });
        return filteredContainers.slice(0, 5);
      }

      if (this.tab === "highest") {
        filteredContainers.sort(function (a, b) {
          return b.complianceScore - a.complianceScore;
        });
        return filteredContainers.slice(0, 5);
      }

      return filteredContainers;
    },
  },
  methods: {
    getScanContainers() {
      // TODO: error handling at component level
      if (this.scans && this.scans[0] && process.env.NODE_ENV !== "demo") {
        AdtechService.call("reports.scanContainers", {
          scanid: this.scans[0].scanid,
        }).then((response) => {
          if ("error" in response) {
            this.scanContainers = ScanContainersFixture;
          } else {
            this.scanContainers = response.result.scanContainers;
          }
        });
      }

      if (process.env.NODE_ENV === "demo") {
        this.scanContainers = ScanContainersFixture;
      }
    },
  },
  mounted() {
    this.getScanContainers();
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
.tabs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  .tab {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    padding: 0 5px;
    color: $dark-gray;
    cursor: pointer;

    &:first-child {
      padding: 0 5px 0 0;
    }

    &:last-child {
      padding: 0 0 0 5px;
    }

    &.selected {
      color: $navy;
      font-weight: 700;
    }
  }
}

.containers {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .label {
    color: $navy;
  }
  .item {
    display: flex;
    align-items: center;
    width: 100%;

    > div {
      &:first-child {
        width: 140px;
        word-break: break-all;
        padding-right: 16px;
      }
      &:last-child {
        width: calc(100% - 140px);
      }

      @include media($x-small, down) {
        &:first-child {
          width: 120px;
        }
        &:last-child {
          width: calc(100% - 120px);
        }
      }
    }
  }
}

.leaderboard {
  .scores-wrapper {
    max-width: 360px;
    width: 100%;
    margin-top: 30px;
  }

  h2 {
    margin: 0 0 20px 0;
    width: 100%;
    text-align: left;
  }
  .button {
    margin: 20px 0 0 auto;
    display: block;
    width: 135px;
    text-align: center;
  }
}
</style>
