<template>
  <div class="view-container tag-consent-details">
    <section class="view-header">
      <div class="view-title">Tag &amp; Consent Monitoring</div>
      <div class="title-sub">
        <div class="view-sublinks">
          <LinkWithIcon
            icon="bar-plus-line-graph-data-up"
            text="Overview"
            link="/tag-consent/overview"
          />
          <LinkWithIcon
            icon="list-ui"
            text="Details"
            :link="`/tag-consent/details?org=${encodeURIComponent(
              this.$store.state.organization
            )}`"
            v-bind:active="true"
          />
        </div>
      </div>
    </section>
    <div class="workarea">
      <div class="widget full">
        <div class="table-wrapper">
          <div class="table-header">
            <h2 class="title">Violation Analysis</h2>
            <div class="filters" v-if="!error && !loading">
              <n-tabs v-model:value="activeTab" type="segment" animated>
                <n-tab-pane name="tag" tab="By Tag"></n-tab-pane>
                <n-tab-pane name="site" tab="By Site"></n-tab-pane>
              </n-tabs>
              <a href="" class="button filter-button">
                <DisplaySvg name="filter" class="filter-icon" />
                Filter
              </a>
              <NInput v-model:value="searchQuery" placeholder="Search"></NInput>
            </div>
          </div>
          <div v-if="error || loading">
            <div class="site-error" v-if="error || !loading">{{ error }}</div>
            <div class="site-loading" v-if="!error && loading">
              <LoadingSpinner /> Loading...
            </div>
          </div>
          <div v-if="!error && !loading">
            <NDataTable
              :columns="tableColumns"
              :data="filteredData"
              :bordered="false"
              :pagination="pagination"
              class="full tag-consent-detail-table"
            ></NDataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import LinkWithIcon from "@/components/Shared/LinkWithIcon.vue";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
import ProgressBar from "@/components/Shared/ProgressBar.vue";
import { NDataTable, NInput, NTabs, NTabPane } from "naive-ui";
import EllipsisDropdown from "@/components/Shared/EllipsisDropdown.vue";
import MetadataService from "@/services/MetadataService";
import { mapDataToReadable } from "@/Helpers.js";
import { getConsentErrorMessage } from "@/ErrorMessaging";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { h } from "vue";

// fixtures
import consentSitesFixture from "@/fixtures/consentSites.json";
import consentTagsFixture from "@/fixtures/consentTags.json";

export default {
  name: "TagConsentDetailsView",
  components: {
    LinkWithIcon,
    NDataTable,
    NInput,
    NTabs,
    NTabPane,
    DisplaySvg,
    LoadingSpinner,
  },
  watch: {
    "$store.state.containers": function () {
      if (
        this.$store.state.containers &&
        this.sitesData.length == 0 &&
        this.sitesTotal === 0 &&
        this.tagsData.length == 0 &&
        this.tagsTotal === 0
      ) {
        this.getAllData();
      }
    },
    "$store.state.featurePermissions": function () {
      this.$nextTick(async () => {
        if (this.$store.state.featurePermissions) {
          // redirect to home if they do not have this feature enabled
          if (!this.$store.state.featurePermissions.tagConsent) {
            router.push("/");
          }
        }
      });
    },
    "$store.state.organization": function () {
      if (
        encodeURIComponent(this.$route.query.org) !==
        encodeURIComponent(this.$store.state.organization)
      ) {
        router.push("/tag-consent/overview");
      }
    },
  },
  computed: {
    filteredData() {
      return this.tableData.filter((item) =>
        Object.values(item).some((value) =>
          value
            .toString()
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        )
      );
    },
    pagination() {
      return {
        pageSize: 10,
        disabled: this.filteredData.length <= 10 ? true : false,
      };
    },
    tableColumns() {
      if (this.activeTab == "site") {
        return this.tableColumnsSite;
      } else {
        return this.tableColumnsTag;
      }
    },
    tableData() {
      if (this.activeTab == "site") {
        return this.sitesData;
      } else {
        return this.tagsData;
      }
    },
  },
  data() {
    const getScoreBar = (score, denominator) => {
      return h(
        "div",
        {
          class: "score-bar",
        },
        [
          h(ProgressBar, {
            percent: Math.round((score / denominator) * 100),
            animate: false,
            highNumBad: true,
          }),
          h("p", {}, `${score} / ${denominator}`),
        ]
      );
    };

    const orgId = this.$store.state.organization;

    return {
      error: null,
      loading: true,
      searchQuery: "",
      tableColumnsTag: [
        {
          title: "Tag Name",
          key: "tagLoadOrigin",
          width: 500,
          sorter: "default",
        },
        {
          title: "# of Violating Sites",
          key: "tagViolating",
          width: 180,
          render: (row) => {
            return getScoreBar(row.tagViolating, this.sitesTotal);
          },
          sorter: (row1, row2) => row1.tagViolating - row2.tagViolating,
          defaultSortOrder: "descend",
        },
        {
          title: "",
          key: "action",
          width: 60,
          render(row) {
            return h(EllipsisDropdown, {
              options: [
                {
                  key: row.tagLoadOrigin,
                  link: `/tag-consent/tag/${
                    row.tagLoadOrigin
                  }?org=${encodeURIComponent(orgId)}`,
                  text: "View Details",
                },
              ],
            });
          },
        },
      ],
      tableColumnsSite: [
        {
          title: "Site Name",
          key: "siteName",
          width: 500,
          sorter: "default",
        },
        {
          title: "# of Violating Tags",
          key: "tagViolating",
          width: 180,
          render: (row) => {
            return getScoreBar(row.tagViolating, this.tagsTotal);
          },
          sorter: (row1, row2) => row1.tagViolating - row2.tagViolating,
          defaultSortOrder: "descend",
        },
        {
          title: "",
          key: "action",
          width: 60,
          render(row) {
            return h(EllipsisDropdown, {
              options: [
                {
                  key: `${row.siteId}-${row.siteName}`,
                  link: `/tag-consent/site/${
                    row.siteId
                  }?name=${encodeURIComponent(
                    row.siteName
                  )}&org=${encodeURIComponent(orgId)}`,
                  text: "View Details",
                },
              ],
            });
          },
        },
      ],
      activeTab: "tag",
      sitesData: [],
      sitesTotal: 0,
      tagsData: [],
      tagsTotal: 0,
    };
  },
  methods: {
    getTagsData() {
      if (
        !this.$store.state.consentMonitoringContainer ||
        !this.$store.state.consentMonitoringContainer.containerid
      ) {
        this.triggerError({
          message:
            "TagConsentDetailsView - getTagsData - this.$store.state.consentMonitoringContainer.containerid UNDEFINED",
        });
        return Promise.resolve();
      }

      return MetadataService.call("runConsentMonitoringQuery", {
        containerid: this.$store.state.consentMonitoringContainer.containerid,
        query: `
        SELECT
          tagViolating,
          COUNT(DISTINCT(siteName)) as numSites
        FROM __dashboard_view__
        GROUP BY tagViolating;
        `,
      });
    },
    getSitesData() {
      if (
        !this.$store.state.consentMonitoringContainer ||
        !this.$store.state.consentMonitoringContainer.containerid
      ) {
        this.triggerError({
          message:
            "TagConsentDetailsView - getSitesData - this.$store.state.consentMonitoringContainer.containerid UNDEFINED",
        });
        return Promise.resolve();
      }

      return MetadataService.call("runConsentMonitoringQuery", {
        containerid: this.$store.state.consentMonitoringContainer.containerid,
        query: `
        SELECT
          siteName,
          COUNT(DISTINCT(tagViolating)) as tagViolating,
          siteId
        FROM __dashboard_view__
        GROUP BY siteName, siteId
        ORDER BY tagViolating DESC;
        `,
      });
    },
    getSitesTotal() {
      if (
        !this.$store.state.consentMonitoringContainer ||
        !this.$store.state.consentMonitoringContainer.containerid
      ) {
        this.triggerError({
          message:
            "TagConsentDetailsView - getSitesTotal - this.$store.state.consentMonitoringContainer.containerid UNDEFINED",
        });
        return Promise.resolve();
      }

      return MetadataService.call("runConsentMonitoringQuery", {
        containerid: this.$store.state.consentMonitoringContainer.containerid,
        query: `
        SELECT
          COUNT(DISTINCT(siteName)) as totalSites
        FROM __dashboard_view__
        `,
      });
    },
    getTagsTotal() {
      if (
        !this.$store.state.consentMonitoringContainer ||
        !this.$store.state.consentMonitoringContainer.containerid
      ) {
        this.triggerError({
          message:
            "TagConsentDetailsView - getTagsTotal - this.$store.state.consentMonitoringContainer.containerid UNDEFINED",
        });
        return Promise.resolve();
      }

      return MetadataService.call("runConsentMonitoringQuery", {
        containerid: this.$store.state.consentMonitoringContainer.containerid,
        query: `
        SELECT
          COUNT(DISTINCT(tagViolating)) as totalTags
        FROM __dashboard_view__
        `,
      });
    },
    getAllData() {
      this.loading = true;

      if (process.env.NODE_ENV === "demo") {
        this.sitesTotal = 44;
        this.tagsTotal = 78;
        this.sitesData = consentSitesFixture;
        this.tagsData = consentTagsFixture;
        this.loading = false;
        return;
      }

      const sitesDataPromise = this.getSitesData();
      const sitesTotalPromise = this.getSitesTotal();
      const tagsDataPromise = this.getTagsData();
      const tagsTotalPromise = this.getTagsTotal();

      Promise.all([
        sitesDataPromise,
        sitesTotalPromise,
        tagsDataPromise,
        tagsTotalPromise,
      ])
        .then(
          ([
            sitesDataResponse,
            sitesTotalResponse,
            tagsDataResponse,
            tagsTotalResponse,
          ]) => {
            if (sitesDataResponse.error || !sitesDataResponse.result) {
              this.triggerError({
                message:
                  "runConsentMonitoringQuery - sitesData - error obj returned from api",
                error: sitesDataResponse.error,
              });
              return Promise.resolve();
            }

            if (tagsDataResponse.error || !tagsDataResponse.result) {
              this.triggerError({
                message:
                  "runConsentMonitoringQuery - tagsData - error obj returned from api",
                error: tagsDataResponse.error,
              });
              return Promise.resolve();
            }

            if (sitesTotalResponse.error || !sitesTotalResponse.result) {
              this.triggerError({
                message:
                  "runConsentMonitoringQuery - sitesTotal - error obj returned from api",
                error: sitesTotalResponse.error,
              });
              return Promise.resolve();
            }

            if (tagsTotalResponse.error || !tagsTotalResponse.result) {
              this.triggerError({
                message:
                  "runConsentMonitoringQuery - tagsTotal - error obj returned from api",
                error: tagsTotalResponse.error,
              });
              return Promise.resolve();
            }

            if (!sitesDataResponse.error && sitesDataResponse.result) {
              const sitesLookup = {
                0: "siteName",
                1: "tagViolating",
                2: "siteId",
              };
              this.sitesData = mapDataToReadable(
                sitesDataResponse.result.queryResult.data,
                sitesLookup
              );
            }

            if (!tagsDataResponse.error && tagsDataResponse.result) {
              const tagsLookup = {
                0: "tagLoadOrigin",
                1: "tagViolating",
              };
              this.tagsData = mapDataToReadable(
                tagsDataResponse.result.queryResult.data,
                tagsLookup
              );
            }

            if (!sitesTotalResponse.error && sitesTotalResponse.result) {
              this.sitesTotal =
                sitesTotalResponse.result.queryResult.data[0].f[0].v;
            }

            if (!tagsTotalResponse.error && tagsTotalResponse.result) {
              this.tagsTotal =
                tagsTotalResponse.result.queryResult.data[0].f[0].v;
            }

            this.loading = false;
          }
        )
        .catch((error) => {
          this.triggerError({
            message:
              "runConsentMonitoringQuery - sitesData sitesTotal tagsData tagsTotal - catch",
            error: error,
          });
        });
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.error = getConsentErrorMessage(this.$store.state.organization);
      this.loading = false;
    },
  },
  mounted() {
    if (this.$store.state.containers) {
      this.getAllData();
    }

    if (this.$store.state.featurePermissions) {
      // redirect to home if they do not have this feature enabled
      if (!this.$store.state.featurePermissions.tagConsent) {
        router.push("/");
      }
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/_variable.scss";
@import "@/styles/_helpers.scss";

.tag-consent-details .n-tabs .n-tabs-pane-wrapper {
  display: none !important;
  height: 0px;
}

.tag-consent-details .n-tabs-tab__label {
  color: $navy;
  text-transform: uppercase;
  font-weight: bold;
}

.tag-consent-details .filter-button {
  display: none;
}
</style>
