<template>
  <div class="view-container platform-governance-details">
    <section class="view-header">
      <div class="view-title-with-btn">
        <div class="view-title">
          Platform Governance &amp; Standards - Site Details
        </div>
        <PlatformGovernanceFilter
          :loading="loading"
          @filter-change="handleFilterChange"
          v-if="$store.state.scans && $store.state.scans.length > 0"
        />
      </div>
      <div class="view-sublinks" v-if="!error && !loading">
        <LinkWithIcon
          icon="bar-plus-line-graph-data-up"
          text="Overview"
          link="/platform-governance/overview"
        />
        <LinkWithIcon
          icon="list-ui"
          text="Details"
          :link="`/platform-governance/details?org=${encodeURIComponent(
            this.$store.state.organization
          )}`"
          v-bind:active="true"
        />
        <LinkWithIcon
          icon="warning"
          text="Violations"
          :link="`/platform-governance/violations?org=${encodeURIComponent(
            this.$store.state.organization
          )}`"
        />
        <!-- <LinkWithIcon
          icon="line-graph-data-up"
          text="Improve Score"
          :link="`/platform-governance/improve-score`"
        /> -->
      </div>
      <div>
        <LinkWithIcon
          icon="download"
          text="View Report Downloads"
          className="view-report-downloads-link"
          :link="`/platform-governance/download-reports?org=${encodeURIComponent(
            this.$store.state.organization
          )}`"
        />
      </div>
    </section>
    <div class="workarea">
      <div class="widget full">
        <div v-if="error || loading">
          <h2 class="title" :style="{ textAlign: 'left', paddingTop: '17px' }">
            Digital Property Score &amp; Risk Factors
          </h2>
          <div class="site-error" v-if="error || !loading">{{ error }}</div>
          <div class="site-loading" v-if="!error && loading">
            <LoadingSpinner /> Loading...
          </div>
        </div>
        <div class="table-wrapper" v-if="!error && !loading">
          <div class="table-header">
            <h2 class="title">Digital Property Score &amp; Risk Factors</h2>
            <div class="filters">
              <NInput v-model:value="searchQuery" placeholder="Search">
                <template #suffix>
                  <DisplaySvg name="search" class="search-icon" />
                </template>
              </NInput>
            </div>
          </div>
          <div class="table-content">
            <div class="table-scroll-container">
              <NDataTable
                  :columns="tableColumns"
                  :data="paginatedData"
                  :bordered="false"
                  :pagination="false"
                  :row-props="rowProps"
                  :row-class-name="rowClassName"
                class="full platform-governance-details-table"
              ></NDataTable>
            </div>
          </div>
          <div class="table-pagination">
            <NPagination
              v-model:page="page"
              :page-count="pageCount"
              :page-size="pageSize"
              :item-count="filteredData.length"
              @update:page="onPageChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import LinkWithIcon from "@/components/Shared/LinkWithIcon.vue";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
import ProgressBar from "@/components/Shared/ProgressBar.vue";
import ArrowDropdown from "@/components/Shared/ArrowDropdown.vue";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import PlatformGovernanceFilter from "@/components/PlatformGovernance/PlatformGovernanceFilter.vue";
import { getDashboardErrorMessage } from "@/ErrorMessaging";
import { NDataTable, NInput, NPagination } from "naive-ui";
import { h } from "vue";
import store from "@/store/index.js";

export default {
  name: "PlatformGovernanceDetailsView",
  components: {
    LinkWithIcon,
    DisplaySvg,
    NDataTable,
    NInput,
    NPagination,
    LoadingSpinner,
    PlatformGovernanceFilter,
  },
  data() {
    return {
      error: null,
      loading: true,
      searchQuery: "",
      tableColumns: [],
      tableData: [],
      page: 1,
      pageSize: 10,
    };
  },
  computed: {
    filteredData() {
      if (!this.tableData || !Array.isArray(this.tableData)) return [];
      
      if (!this.searchQuery) {
        return this.tableData;
      }

      return this.tableData.filter((item) =>
        Object.values(item).some((value) =>
          value?.toString().toLowerCase().includes(this.searchQuery.toLowerCase())
        )
      );
    },

    paginatedData() {
      const start = (this.page - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredData ? this.filteredData.slice(start, end) : [];
    },
    
    pageCount() {
      return Math.ceil(this.filteredData.length / this.pageSize);
    }
  },
  methods: {
    async handleFilterChange() {
    this.loading = true;

    try {
      // Fetch the aggregated data, grouping by containerid and rule.categories
      const response = await store.dispatch("fetchFilteredRuleRunsAggregate", {
        groupBy: ["containerid", "rule.categories"]
      });

      if (response.error) {
        this.triggerError({
          message: "Error fetching filtered data",
          error: response.error
        });
        return;
      }

      // Process the aggregate data
      const aggregateData = response?.result?.['custom.scanRuleRunsAggregate'] || [];
      const transformedData = this.transformAggregateData(aggregateData);
      this.getTableColumns(transformedData);
      this.tableData = transformedData;
    } catch (error) {
      this.triggerError({
        message: "Error applying filters",
        error
      });
    } finally {
      this.loading = false;
    }
  },

  transformAggregateData(aggregateData) {
  const containerGroups = {};

  // Aggregate data by containerId
  aggregateData.forEach(item => {
    const containerId = item.group.containerid;

    if (!containerGroups[containerId]) {
      containerGroups[containerId] = {
        id: containerId,
        site: item.group.container_name || 'Unknown Site',
        total_pass_count: 0,
        total_fail_count: 0,
        categories: {}
      };
    }

    const passCount = item.pass_count || 0;
    const failCount = item.fail_count || 0;
    containerGroups[containerId].total_pass_count += passCount;
    containerGroups[containerId].total_fail_count += failCount;
  });

  return Object.values(containerGroups).map(container => {
    const total = container.total_pass_count + container.total_fail_count;
    const dataQuality = total > 0 ? (container.total_pass_count / total) * 100 : 0;

    return {
      id: container.id,
      site: container.site,
      total: `${container.total_pass_count}/${total}`,
      total_pass_count: container.total_pass_count,
      total_denominator: total,
      data_quality: dataQuality.toFixed(2),
      data_quality_pass_count: container.total_pass_count,
      data_quality_denominator: total
    };
  });

},

getTableColumns() {
  const getScoreBar = (score, pass_count, denominator) => {
    return h(
      "div",
      { class: "score-bar" },
      [
        h(ProgressBar, {
          percent: score,
          animate: false,
        }),
        h("p", {}, `${pass_count}/${denominator}`),
      ]
    );
  };

  const tableColumns = [
    {
      title: "ID",
      render(row) {
        return row.id ? row.id : 'N/A';
      },
    },
    {
      title: "Site",
      key: "site",
      render(row) {
        return h(
          "p",
          { style: { textAlign: "left", margin: "0" } },
          row.site || 'Unknown Site'
        );
      },
    },
    {
      title: "Total",
      render(row) {
        const totalPercentage = row.total_denominator > 0
          ? (row.total_pass_count / row.total_denominator) * 100
          : 0;
        return getScoreBar(
          totalPercentage,
          row.total_pass_count,
          row.total_denominator
        );
      },
    },
    {
      title: "Data Quality",
      render(row) {
        const dataQualityPercentage = row.total_denominator > 0
          ? (row.total_pass_count / row.total_denominator) * 100
          : 0;
        return getScoreBar(
          dataQualityPercentage,
          row.total_pass_count,
          row.total_denominator
        );
      }
    }
  ];

  const orgId = this.$store.state.organization;
  tableColumns.push({
    title: "",
    key: "action",
    render: (row) => {
      return h(
        ArrowDropdown,
        {
          options: [
            {
              key: row.id,
              link: `/platform-governance/details/${row.id}?org=${encodeURIComponent(orgId)}`,
              text: "View Details",
            },
          ],
        }
      );
    },
  });

  this.tableColumns = tableColumns;
},


    triggerError(obj) {
      console.error(obj);
      this.error = getDashboardErrorMessage(this.$store.state.organization);
      this.loading = false;
    },

    rowProps(row) {
      return {
        style: "cursor: pointer;",
        onClick: () => {
          const orgId = this.$store.state.organization;
          router.push(`/platform-governance/details/${row.id}?org=${encodeURIComponent(orgId)}`);
        },
      };
    },

    rowClassName() {
      return 'fixed-height-row';
    },

    onPageChange(page) {
      this.page = page;
    }
  },
  watch: {
    "$store.state.scans": {
      immediate: true,
      handler(newScans) {
        this.$nextTick(async () => {
          if (newScans?.error) {
            this.loading = false;
            this.error = newScans.error;
            return;
          }

          if (newScans?.length > 0) {
            await this.handleFilterChange({});
          }
        });
      }
    },
    "$store.state.organization"() {
      if (encodeURIComponent(this.$route.query.org) !== encodeURIComponent(this.$store.state.organization)) {
        router.push("/platform-governance/overview");
      }
    }
  },
  async mounted() {
    if (this.$store.state.featurePermissions?.platformGovernance === false) {
      router.push("/");
    }
  }
};
</script>

<style lang="scss">
.platform-governance-details {
  .view-title-with-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
  }

  .widget {
    display: block;
  }

  .table-wrapper {
    height: 850px;
    display: flex;
    flex-direction: column;
  }

  .table-header {
    padding: 16px;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
  }

  .table-content {
    flex-grow: 1;
    overflow: hidden;
    position: relative;
  }

  .table-scroll-container {
    height: 100%;
    overflow-y: auto;
    
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(100, 100, 100, 0.5);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    scrollbar-width: thin;
    scrollbar-color: rgba(100, 100, 100, 0.5) transparent;
  }

  .n-data-table {
    min-width: 600px;
  }

  .n-data-table-base-table {
    min-height: 100%;
  }

  .table-pagination {
    padding: 16px;
    background-color: #fff;
    border-top: 1px solid #e0e0e0;
  }

  .search-icon {
    height: 16px;
    width: 16px;
    position: relative;
    top: -5px;
    color: $dark-gray;
  }

  .filter-icon {
    height: 16px;
    width: 16px;
    margin-right: 10px;
    position: relative;
    top: 2px;
  }

  .filter-button {
    margin-right: 20px;
  }

  .fixed-height-row {
    height: 60px;
  }

  .fixed-height-row td {
    padding: 10px !important;
    vertical-align: middle !important;
  }

  .n-data-table-td {
    word-break: break-word;
  }

  .platform-governance-details-table {
    .n-data-table-td {
      padding: 4px 8px;
    }
  }
}
</style>