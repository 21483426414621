<template>
  <section class="login-area">
    <div class="widget">
      <DisplaySvg name="logo-full" class="logo" />
      <h1>
        <span>Oops.</span> <br />
        Unable to Login
      </h1>
      <p class="login-error-text">
        Please coordinate with your InfoTrust advisor if this issue persists.
      </p>
    </div>
  </section>
</template>

<script>
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";

export default {
  name: "LoginFailureView",
  components: {
    DisplaySvg,
  },
  watch: {
    "$store.state.userData": function () {
      this.checkForLoggedIn();
    },
  },
  methods: {
    checkForLoggedIn() {
      if (this.$store.state.userData != null) {
        this.$router.push({ path: "/dashboard" });
      }
    },
  },
  mounted() {
    this.checkForLoggedIn();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
.login-area {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 64px);

  .widget {
    width: 100%;
    max-width: 400px;
  }

  h1 {
    line-height: 38px;
    text-align: center;
    span {
      font-size: 2rem;
      color: $orange;
    }
  }
  .logo {
    margin-top: 20px;
    width: 210px;
  }
  .login-button {
    margin-bottom: 20px;
    padding: 12px 20px 10px 20px;

    @include hover {
      background-color: $light-gray;
      color: $blue;
      border: 1px solid $blue;
    }
    .google-icon {
      width: 18px;
      position: relative;
      top: 1px;
      margin-right: 20px;
    }

    span {
      @include media($x-small-highest, down) {
        display: none;
      }
    }
  }
  .login-error-text {
    margin: -26px 0 20px 0;
    text-align: center;
  }
}
</style>
