<template>
  <div class="alertBox">
    <div>
      <div class="warning-icon-wrapper">
        <DisplaySvg name="warning-solid" class="warning-icon" />
      </div>
    </div>
    <div>
      <h4>{{ title }}</h4>
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script>
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
export default {
  name: "AlertBox",
  components: { DisplaySvg },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
.alertBox {
  background-color: $yellow-lighter;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  margin: 30px 0;

  > div {
    &:first-child {
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:last-child {
      width: calc(100% - 100px);
      padding: 20px 20px 20px 0;
    }
  }
  h4 {
    color: $yellow-darker;
    margin: 0 0 5px 0;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    text-align: left !important;
  }
  p {
    margin: 0;
    color: $navy;
    text-align: left !important;
  }

  &:before {
    content: "";
    height: 100%;
    width: 10px;
    position: absolute;
    background-color: $yellow;
  }

  .warning-icon-wrapper {
    background-color: $yellow;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .warning-icon {
    width: 30px;
    color: $white;
  }
}
</style>
