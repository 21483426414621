<template>
  <div class="view-container tag-consent-tag-detail-page">
    <div class="alert warning" v-if="this.loadingRow || this.loadingRow === 0">
      <div class="site-loading"><LoadingSpinner /> Loading Row Data...</div>
    </div>
    <div class="alert error" v-if="this.alert">
      {{ this.alert }}
    </div>
    <section class="view-header">
      <div class="half">
        <div class="title-eyebrow">
          <router-link to="/tag-consent/overview" class="eyebrow-link">
            Tag &amp; Consent Monitoring
          </router-link>
          <DisplaySvg name="caret-right" class="caret" />
          <router-link
            :to="`/tag-consent/details?org=${encodeURIComponent(
              this.$store.state.organization
            )}`"
            class="eyebrow-link"
          >
            Details
          </router-link>
        </div>
        <div class="view-title-with-btn">
          <div class="view-title">
            <span class="subtitle">Tag Detail</span>
            {{ this.$route.params.id }}
          </div>
          <router-link to="" class="button share" v-if="NODE_ENV === 'demo'">
            <DisplaySvg name="share" class="share-icon" />Share
          </router-link>
        </div>
      </div>
      <div class="half"></div>
    </section>

    <div class="workarea">
      <div class="widget full">
        <div v-if="error || loading">
          <div class="site-error" v-if="error || !loading">{{ error }}</div>
          <div class="site-loading" v-if="!error && loading">
            <LoadingSpinner /> Loading...
          </div>
        </div>

        <div class="table-wrapper" v-if="!error && !loading">
          <div class="top-section">
            <h2>
              {{ this.getTotalViolationCount(this.tableData) }} Total Violations
            </h2>
            <p>{{ this.tableData.length }} Page URLs</p>
          </div>
          <NDataTable
            :columns="tableColumns"
            :data="tableData"
            :pagination="pagination"
            :on-update:page="resetOpenSubTables"
            :bordered="false"
            :on-update:sorter="resetOpenSubTables"
            class="full site-detail-table"
          ></NDataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
import { h } from "vue";
import { NDataTable } from "naive-ui";
import MetadataService from "@/services/MetadataService";
import { mapDataToReadable } from "@/Helpers.js";
import { ref } from "vue";
import { getConsentErrorMessage } from "@/ErrorMessaging";
import TagConsentTagDetailFixture from "@/fixtures/consentTagsDetail.json";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";

const isExpandedTableOpen = ref([]);

export default {
  name: "TagConsentTagDetailView",
  components: {
    NDataTable,
    LoadingSpinner,
    DisplaySvg,
  },
  watch: {
    "$store.state.containers": function () {
      if (this.$store.state.containers) {
        this.getData();
      }
    },
    "$store.state.featurePermissions": function () {
      this.$nextTick(async () => {
        if (this.$store.state.featurePermissions) {
          // redirect to home if they do not have this feature enabled
          if (!this.$store.state.featurePermissions.tagConsent) {
            router.push("/");
          }
        }
      });
    },
    "$store.state.organization": function () {
      if (
        encodeURIComponent(this.$route.query.org) !==
        encodeURIComponent(this.$store.state.organization)
      ) {
        router.push("/tag-consent/overview");
      }
    },
  },
  data() {
    const getSubTable = (index, expandedData) => {
      if (expandedData == null) return "";

      const truncateString = (str, maxLength) => {
        if (str && str.length > maxLength) {
          return str.slice(0, maxLength - 3) + "...";
        }
        return str;
      };

      const pagination = {
        pageSize: 5,
        disabled: expandedData.length <= 5 ? true : false,
      };
      const subTableColumns = [
        {
          title: "Pages",
          key: "pageURl",
          render(row) {
            return h(
              "a",
              {
                href: row.pageURl,
                class: "table-inline-link-no-center",
              },
              [
                truncateString(row.pageURl, 100),
                h(DisplaySvg, {
                  name: "link",
                  class: "link-icon",
                }),
              ]
            );
          },
        },
      ];

      const isRowExpanded = isExpandedRowOpen(index);

      return h(
        "div",
        {
          style: {
            top: "24px",
            position: "absolute",
            left: 0,
            width: "100%",
            display: isRowExpanded ? "block" : "none",
            opacity: isRowExpanded ? "1" : 0,
            transition: "opacity 0.3s",
          },
        },
        [
          h(NDataTable, {
            columns: subTableColumns,
            data: expandedData,
            pagination: pagination,
            bordered: false,
            class: "site-detail-sub-table",
          }),
        ]
      );
    };

    const expandTable = (value, row) => {
      if (this.loadingRow && this.loadingRow !== 0) {
        this.alert =
          "Please wait for your previous query to finish loading before opening another.";
        setTimeout(() => {
          this.alert = null;
        }, 3000);
      } else {
        let array = isExpandedTableOpen.value;
        const findItem = array.map((e) => e.id).indexOf(value);
        if (findItem >= 0) {
          array.splice(findItem, 1);
          isExpandedTableOpen.value = array;
          return;
        }

        this.loadingRow = value;
        let expandedData = null;

        if (process.env.NODE_ENV === "demo") {
          expandedData = row.violationExpanded;
          array.push({ id: value, expandedData: expandedData });
          isExpandedTableOpen.value = array;
          this.loadingRow = false;
          return;
        }

        this.getViolatingPages(row.siteName)
          .then((response) => {
            if (response.error) {
              console.log("%cError", "color: white; background-color: red", {
                message:
                  "runConsentMonitoringQuery - getViolatingPages - error obj returned from api",
                error: response.error,
              });
              this.alert = "Oops. This row could not be loaded.";
              setTimeout(() => {
                this.alert = null;
              }, 3000);
              this.loadingRow = false;
              return Promise.resolve();
            }

            if (response?.result !== undefined) {
              expandedData = mapDataToReadable(
                response.result.queryResult.data,
                {
                  0: "pageURl",
                }
              );
            }

            array.push({ id: value, expandedData: expandedData });
            isExpandedTableOpen.value = array;
            this.loadingRow = false;
          })
          .catch((error) => {
            console.log("%cError", "color: white; background-color: red", {
              message: "runConsentMonitoringQuery - getViolatingPages - catch",
              error: error,
            });
            this.alert = "Oops. This row could not be loaded.";
            setTimeout(() => {
              this.alert = null;
            }, 3000);
            this.loadingRow = false;
          });
      }
    };

    const getLoadingRow = (index) => {
      return this.loadingRow === index;
    };

    const isExpandedRowOpen = (value) => {
      const findItem = isExpandedTableOpen.value
        .map((e) => e.id)
        .indexOf(value);
      if (findItem >= 0) {
        return isExpandedTableOpen.value[findItem];
      } else {
        return false;
      }
    };

    return {
      NODE_ENV: process.env.NODE_ENV,
      alert: null,
      loading: true,
      loadingRow: false,
      error: null,
      authCredentials: [],
      expandedData: null,
      tableColumns: [
        {
          title: "Site",
          key: "siteName",
          render(row) {
            return h(
              "a",
              {
                href: row.siteName,
                target: "_blank",
                style: {
                  margin: 0,
                  fontSize: "14px",
                  lineHeight: "18px",
                  height: "18px",
                  overflow: "hidden",
                  wordBreak: "break-all",
                  display: "block",
                },
              },
              [row.siteName]
            );
          },
        },
        {
          title: "# of Violating Pages",
          key: "violations",
          width: 260,
          render(row) {
            return h("div", { style: { textAlign: "center", margin: "0" } }, [
              row.violations,
            ]);
          },
          sorter: (row1, row2) => row1.violations - row2.violations,
          defaultSortOrder: "descend",
        },
        {
          title: "Violating Pages",
          width: 160,
          key: "violations",
          render(row, index) {
            const isRowExpanded = isExpandedRowOpen(index);
            const subTable = getSubTable(index, isRowExpanded.expandedData);
            const arrayLength =
              isRowExpanded && isRowExpanded.expandedData
                ? isRowExpanded.expandedData.length
                : row.violations;
            const offset = arrayLength > 5 ? 64 : 91;
            const loadingRow = getLoadingRow(index);

            const height =
              arrayLength > 5 ? 5 * 51 + offset : arrayLength * 40 + offset;

            const buttonStyles = {
              width: "88px",
              display: "flex",
              color: isRowExpanded ? "#fff" : "#143755",
              backgroundColor: isRowExpanded ? "#143755" : "#fff",
            };

            return h(
              "div",
              {
                class: "expand-col",
                style: {
                  paddingBottom: `${isRowExpanded ? height : 0}px`,
                  transition: "padding 0.3s",
                },
              },
              [
                h(
                  "button",
                  {
                    class: "button-tiny",
                    style: buttonStyles,
                    disabled: loadingRow >= 0 && loadingRow !== false,
                    onClick: () => {
                      expandTable(index, row);
                    },
                  },
                  [
                    "expand",
                    h(DisplaySvg, {
                      name: isRowExpanded ? "x" : "expand",
                      class: "expand-icon",
                      style: {
                        color: !isRowExpanded ? "#143755" : "#ffffff",
                        paddingTop: isRowExpanded ? "2px" : "0",
                      },
                    }),
                  ]
                ),
                subTable,
              ]
            );
          },
        },
      ],
      tableData: [],
    };
  },
  methods: {
    getTotalViolationCount(tableData) {
      let count = 0;
      if (tableData && tableData.length > 0) {
        tableData.map((item) => {
          count = Number(item.violations) + count;
        });
      }

      return count;
    },
    getData() {
      if (process.env.NODE_ENV === "demo") {
        this.tableData = TagConsentTagDetailFixture.sites;
        this.loading = false;
        return;
      }

      if (
        !this.$store.state.consentMonitoringContainer ||
        !this.$store.state.consentMonitoringContainer.containerid ||
        this.$store.state.containers == null ||
        this.$store.state.containers.error
      ) {
        console.log("%cError", "color: white; background-color: red", {
          message:
            "TagConsentTagDetailView - this.$store.state.consentMonitoringContainer.containerid UNDEFINED",
        });
        this.loading = false;
        this.error = getConsentErrorMessage(this.$store.state.organization);

        return Promise.resolve();
      }

      const tag = this.$route.params.id;
      MetadataService.call("runConsentMonitoringQuery", {
        containerid: this.$store.state.consentMonitoringContainer.containerid,
        query: `
        SELECT
        siteName,
        COUNT(DISTINCT(pageURl)) as violations
        FROM __dashboard_view__
        WHERE tagViolating = '${tag}'
        GROUP BY siteName;
        `,
      })
        .then((response) => {
          if (response.error) {
            console.log("%cError", "color: white; background-color: red", {
              message:
                "runConsentMonitoringQuery - getData TagConsentTagDetailView - error obj returned from api",
              error: response.error,
            });
            this.loading = false;
            this.error = getConsentErrorMessage(this.$store.state.organization);
            return Promise.resolve();
          }

          const lookup = {
            0: "siteName",
            1: "violations",
          };
          this.tableData = mapDataToReadable(
            response.result.queryResult.data,
            lookup
          );
          this.loading = false;
        })
        .catch((error) => {
          console.log("%cError", "color: white; background-color: red", {
            message:
              "runConsentMonitoringQuery - getData TagConsentTagDetailView - catch",
            error: error,
          });
          this.loading = false;
          this.error = getConsentErrorMessage(this.$store.state.organization);
        });
    },
    getViolatingPages(siteName) {
      if (
        !this.$store.state.consentMonitoringContainer ||
        !this.$store.state.consentMonitoringContainer.containerid
      ) {
        console.log("%cError", "color: white; background-color: red", {
          message:
            "TagConsentTagDetailView - this.$store.state.consentMonitoringContainer.containerid UNDEFINED",
        });
        this.alert = "Oops. This row could not be loaded.";
        setTimeout(() => {
          this.alert = null;
        }, 3000);
        this.loadingRow = false;
        return Promise.resolve();
      }

      return MetadataService.call("runConsentMonitoringQuery", {
        containerid: this.$store.state.consentMonitoringContainer.containerid,
        query: `
        SELECT
        pageURl
        FROM __dashboard_view__
        WHERE siteName = '${siteName}'
        GROUP BY pageURl;
        `,
      });
    },
    resetOpenSubTables() {
      isExpandedTableOpen.value = [];
    },
  },
  computed: {
    pagination() {
      return {
        pageSize: 10,
        disabled: this.tableData.length <= 10 ? true : false,
      };
    },
  },
  mounted() {
    if (this.$store.state.containers) {
      this.getData();
    }

    if (this.$store.state.featurePermissions) {
      // redirect to home if they do not have this feature enabled
      if (!this.$store.state.featurePermissions.tagConsent) {
        router.push("/");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
.tag-consent-tag-detail-page {
  .alert {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: $shadow;
    left: 50vw;
    width: 320px;
    top: 150px;
    z-index: 99;
    &.warning {
      border: 2px solid $yellow;
    }
    &.error {
      border: 2px solid $red;
    }
    @include media($small-highest, down) {
      left: calc(50vw - 160px);
    }
  }

  .top-section {
    margin-bottom: 30px;
    margin-top: 15px;
    h2 {
      display: inline;
      margin: 10px 10px 10px 0;
      color: $orange;
      font-size: 18px;
      line-height: 18px;
      font-weight: 700;
    }
    p {
      display: inline;
      margin: 10px 10px 8px 10px;
      color: $navy;
      font-size: 15px;
      line-height: 18px;
      position: relative;
      &:after {
        content: "";
        height: 18px;
        width: 1px;
        background-color: $navy;
        position: absolute;
        left: -10px;
        top: 0;
      }
    }
  }
  .subtitle {
    text-transform: uppercase;
    display: block;
    font-size: 14px;
    margin: 0 0 10px 0;
    opacity: 0.6;
  }
  .widget {
    display: block;
    min-height: 500px;
  }
  .table-wrapper {
    min-width: 875px;
  }
}
</style>
