<template>
  <div class="manage-account-profile widget full">
    <div class="account">
      <div class="user-circle">
        {{ getUserInitials() }}
      </div>
      <div class="user-info">
        <label>{{ userData.user.name }}</label>
      </div>
    </div>

    <div class="table">
      <div class="row">
        <div class="info">
          <div class="label">Name</div>
          <div class="value" v-if="!isEditNameInputVisible">
            {{ userData.user.name }}
          </div>
          <div class="value" v-if="isEditNameInputVisible">
            <NInput v-model:value="nameInputValue" placeholder="Name" />
          </div>
        </div>

        <div class="action">
          <button
            class="button white-btn"
            @click="toggleEditInput"
            v-if="!showNameSuccess && isEditNameInputVisible"
          >
            Cancel
          </button>
          <button
            class="button white-btn"
            @click="toggleEditInput"
            v-if="!showNameSuccess && !isEditNameInputVisible"
          >
            Edit
          </button>
          <button
            class="button blue-btn"
            @click="saveInput"
            v-if="isEditNameInputVisible && !showNameSuccess"
          >
            Save
          </button>
          <div class="success" v-if="showNameSuccess">
            Updated <DisplaySvg name="checkmark" class="success-icon" />
          </div>
          <div class="error" v-if="editNameError">{{ editNameError }}</div>
        </div>
      </div>
      <div class="row">
        <div class="info full">
          <div class="label">Sign In Method</div>
          <div class="value">
            <div class="sign-in-method">
              <DisplaySvg name="google" class="google-icon" />Google
            </div>
            <div>{{ userData.user.email }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CredentialService from "@/services/CredentialService";
import { reactive } from "vue";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
import { NInput } from "naive-ui";

export default {
  name: "ManageAccount",
  components: { DisplaySvg, NInput },
  data() {
    return {
      editNameError: null,
      isEditNameInputVisible: false,
      showNameSuccess: false,
      userData: reactive(this.$store.state.userData),
      nameInputValue: this.$store.state.userData.user.name,
    };
  },
  methods: {
    getUserInitials() {
      if (this.$store.state.userData == null) return;
      const parts = this.$store.state.userData.user.name.split(" ");
      if (parts.length === 1) {
        return parts[0].charAt(0);
      } else if (parts.length > 1) {
        return parts[0].charAt(0) + parts[parts.length - 1].charAt(0);
      }
      return null;
    },
    toggleEditInput() {
      this.editNameError = null;
      this.isEditNameInputVisible = !this.isEditNameInputVisible;
    },
    saveInput() {
      this.editNameError = null;
      if (this.nameInputValue && this.nameInputValue !== "") {
        CredentialService.call("users.update", {
          userid: this.$store.state.userData.user.userid,
          updates: {
            name: this.nameInputValue,
          },
        })
          .then((response) => {
            if (response.error) {
              this.triggerError({
                message: "users.update - error obj returned from api",
                error: response.error,
              });
              return Promise.resolve();
            }

            const newUserDataObj = {
              accessToken: this.$store.state.userData.accessToken,
              expires: this.$store.state.userData.expires,
              userId: this.$store.state.userData.userid,
              user: response.result.user,
            };
            this.userData = newUserDataObj;
            this.$store.commit("setUserData", newUserDataObj);
            this.showNameSuccess = true;
            this.isEditNameInputVisible = false;
            setTimeout(() => {
              this.showNameSuccess = false;
            }, 1000);
          })
          .catch((error) => {
            this.triggerError({
              message: "users.update - catch",
              error: error,
            });
          });
      } else {
        this.editNameError = "Error - please try again";
      }
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.editNameError = "Error - please try again";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.manage-account-profile {
  .blue-btn {
    background-color: $blue;
    color: $white;
  }

  .white-btn {
    margin-right: 16px;
  }

  &.widget {
    align-items: flex-start;
  }

  .account {
    display: flex;
    align-items: center;
    border-radius: 10px;
    gap: 20px;

    .user-circle {
      flex: 0 0 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      background: $white;
      border-radius: 50%;
      color: $blue;
      font-size: 34px;
      font-weight: 600;
      border: 1px solid $medium-gray;
    }

    .user-info {
      display: flex;
      flex-direction: column;
      flex: 0 1 auto;

      label {
        font-weight: 500;
        color: $navy;
        font-size: 24px;
        line-height: 26px;
      }
    }
  }

  .table {
    width: 100%;
    border-top: 1px solid $medium-gray;
    margin-top: 30px;
    margin-bottom: 16px;
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      padding: 20px 10px;
      border-bottom: 1px solid $medium-gray;
    }

    .info {
      width: 70%;
      display: flex;
      align-items: center;

      &.full {
        width: 100%;
      }

      .label {
        width: 160px;
        margin-right: 20px;
        color: $navy;
        font-weight: 600;
        flex-shrink: 0;
      }

      .value {
        color: $navy;
        width: calc(100% - 160px);
        word-break: break-all;
      }

      @include media(1230px, down) {
        width: 100%;
        flex-wrap: wrap;
        .label {
          width: 100%;
          margin-bottom: 20px;
        }
        .value {
          width: 100%;
        }
      }
    }

    .action {
      width: 30%;
      text-align: right;

      @include media(1230px, down) {
        width: 100%;
        text-align: left;
        margin-top: 20px;
      }
    }
  }

  .sign-in-method {
    margin-bottom: 10px;
  }

  .google-icon {
    height: 18px;
    width: 18px;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: 2px;
  }

  .success {
    background-color: $green;
    border-radius: $border-radius-2;
    padding: 6px 16px;
    font-size: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 500;
    display: flex;
    width: 120px;
    color: $white;
    margin: 0 0 0 auto;
    border: 1px solid $green;
    height: 34px;
    .success-icon {
      color: $white;
      width: 16px;
      height: 16px;
      margin-left: 10px;
    }
  }

  .error {
    font-size: 14px;
    color: $red;
    margin-top: 5px;
  }
}
</style>
