import moment from "moment";

export function getDelta(newVal, oldVal, diffDeciPlaces = 0) {
  if (newVal === null || oldVal === null || oldVal === 0) {
    return {
      delta: "0%",
      direction: "neutral",
      difference: "0",
    };
  }
  // DELTA EQUATION = [X(final) - X(initial)] / X(initial) * 100
  const delta = ((newVal - oldVal) / oldVal) * 100;
  const direction = delta < 0 ? "negative" : delta > 0 ? "positive" : "neutral";
  return {
    delta:
      `${direction === "negative" ? "" : direction === "positive" ? "+" : ""}` +
      delta.toFixed(1) +
      "%", // Rounds to two decimal places
    direction: direction,
    difference:
      newVal - oldVal > 0
        ? `+${(newVal - oldVal).toFixed(diffDeciPlaces)}`
        : `${(newVal - oldVal).toFixed(diffDeciPlaces)}`,
  };
}

export function getMonthStartAndEnd(dateStr) {
  // Parse the month and year from the input string
  const parts = dateStr.split(" ");
  const month = parts[0];
  const year = parseInt(parts[1], 10);

  // Create a date object for the first of the month
  const startDate = new Date(`${month} 1, ${year} 00:00:00`);

  // Create a date object for the last of the month by adding 1 to the month and subtracting 1 millisecond
  const endDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth() + 1,
    0,
    23,
    59,
    59
  );

  // Convert to SQL-friendly formats
  const startDateFormat = `${startDate.getFullYear()}-${(
    startDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`;
  const endDateFormat = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}`;

  return { start: startDateFormat, end: endDateFormat };
}

export function mapDataToReadable(data, lookup) {
  const readableData = [];
  Object.values(data).forEach((item) => {
    const mappedItem = {};
    Object.keys(item.f).forEach((key) => {
      const lookupKey = lookup[key];
      if (lookupKey) {
        // Ensure the lookup key exists
        mappedItem[lookupKey] = item.f[key].v;
      }
    });
    readableData.push(mappedItem);
  });

  return readableData;
}

// custom legend for chart.js charts
export function generateCustomLegend(chartName) {
  const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector("ul");

    if (!listContainer) {
      listContainer = document.createElement("ul");
      listContainer.style.display = "flex";
      listContainer.style.flexDirection = "row";
      listContainer.style.justifyContent = "center";
      listContainer.style.flexWrap = "wrap";
      listContainer.style.margin = "0 0 10px 0";
      listContainer.style.padding = 0;

      legendContainer.appendChild(listContainer);
    }

    return listContainer;
  };

  return {
    id: "htmlLegend",
    afterUpdate(chart, args, options) {
      const ul = getOrCreateLegendList(chart, options.containerID);

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      let items = [];
      if (chartName == "spend-by-channel") {
        items = chart.data.labels.map((item, index) => {
          const itemColor = chart.data.datasets[0].backgroundColor[index];
          return {
            borderRadius: undefined,
            datasetIndex: 1,
            fillStyle: itemColor,
            fontColor: "#666",
            hidden: !chart.getDataVisibility(index),
            lineCap: "butt",
            lineDash: [],
            lineDashOffset: 0,
            lineJoin: "miter",
            lineWidth: 3,
            pointStyle: undefined,
            rotation: undefined,
            strokeStyle: itemColor,
            text: item,
            textAlign: undefined,
            index: index,
          };
        });
      } else {
        items = chart.options.plugins.legend.labels.generateLabels(chart);
      }

      // reorder the labels
      let reorderedItems = [];
      if (chartName === "media-performance") {
        items.map((item) => {
          if (item.text === "Total Spend") {
            reorderedItems[0] = item;
          }
          if (
            item.text === "Total Conversions" ||
            item.text === "Cost Per Completed View"
          ) {
            reorderedItems[1] = item;
          }
          if (item.text === "CPA") {
            reorderedItems[2] = item;
          }
        });
      } else {
        reorderedItems = [...items];
      }

      reorderedItems.forEach((item) => {
        const li = document.createElement("li");
        li.style.alignItems = "center";
        li.style.cursor = "pointer";
        li.style.display = "flex";
        li.style.flexDirection = "row";
        li.style.marginLeft = "10px";
        li.style.marginRight = "10px";

        li.onclick = () => {
          const { type } = chart.config;
          if (
            type === "pie" ||
            type === "doughnut" ||
            chartName === "spend-by-channel"
          ) {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(
              item.datasetIndex,
              !chart.isDatasetVisible(item.datasetIndex)
            );
          }
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement("span");
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + "px";
        boxSpan.style.display = "inline-block";
        boxSpan.style.flexShrink = 0;
        boxSpan.style.height = "12px";
        boxSpan.style.marginRight = "10px";
        boxSpan.style.width = "12px";

        // Text
        const textContainer = document.createElement("p");
        textContainer.style.color = "#143755";
        textContainer.style.margin = 0;
        textContainer.style.padding = 0;
        textContainer.style.textDecoration = item.hidden ? "line-through" : "";

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    },
  };
}

export function getNestedOrgs(currentOrg, O_Array, H_Array) {
  // currentOrg is the current org we are getting hierarchy / nested info for
  // O_Array is org options array passed from state
  // H_Array is org hierarchy array passed from state

  let nested_array = [currentOrg.value];

  // get the index of the current org in the hierarchy array
  const currentOrg_hierarchyIndex = H_Array.findIndex((h) => {
    return h.orgid === currentOrg.value;
  });

  // if this org exists in the hierarchy array
  if (currentOrg_hierarchyIndex >= 0 && H_Array[currentOrg_hierarchyIndex]) {
    // get the index of the parent org in the hierarchy array
    let parent_hierarchyIndex = H_Array.findIndex((h) => {
      return h.orgid === H_Array[currentOrg_hierarchyIndex].parent_orgid;
    });

    // get the index of the parent org in the org options array
    let parent_optionsIndex = O_Array.findIndex((o) => {
      return o.value === H_Array[currentOrg_hierarchyIndex].parent_orgid;
    });

    // while a parent is found in the org options array
    // this means the user has access to this org
    while (parent_optionsIndex >= 0) {
      // push the parent to the nested array
      nested_array.push(O_Array[parent_optionsIndex].value);

      // now check if this parent has a parent
      // if it exists in the hieracy array, then it has another parent
      if (parent_hierarchyIndex >= 0) {
        // save the old parent_hierarchyIndex
        let old_parent_hierarchyIndex = parent_hierarchyIndex;

        // get the new parent_hierarchyIndex
        parent_hierarchyIndex = H_Array.findIndex((h) => {
          return h.orgid === H_Array[old_parent_hierarchyIndex].parent_orgid;
        });

        // get the new  parent_optionsIndex
        parent_optionsIndex = O_Array.findIndex((o) => {
          return o.value === H_Array[old_parent_hierarchyIndex].parent_orgid;
        });
      } else {
        // if this parent has no parent, end the loop
        parent_optionsIndex = -1;
      }
    }

    return {
      value: currentOrg.value,
      label: currentOrg.label,
      parent_orgid: H_Array[currentOrg_hierarchyIndex].parent_orgid,
      nested_array: nested_array,
    };
  } else {
    return {
      value: currentOrg.value,
      label: currentOrg.label,
      parent_orgid: null,
      nested_array: nested_array,
    };
  }
}

export function normalizeTagConsentResults(array) {
  let aggregatedData = {};
  array.forEach((row) => {
    if (
      (aggregatedData[row.f[2].v] &&
        moment(aggregatedData[row.f[2].v].date).isBefore(moment(row.f[1].v))) ||
      !aggregatedData[row.f[2].v]
    ) {
      aggregatedData[row.f[2].v] = {
        date: row.f[1].v,
        violating: parseInt(row.f[3].v),
        nonviolating: parseInt(row.f[4].v),
        total: parseInt(row.f[3].v) + parseInt(row.f[4].v),
      };
    }
  });

  // if any properties are undefined, set to 0
  if (aggregatedData["All Enabled"] === undefined) {
    aggregatedData["All Enabled"] = {
      date: null,
      nonviolating: 0,
      violating: 0,
    };
  }

  if (aggregatedData["None Enabled"] === undefined) {
    aggregatedData["None Enabled"] = {
      date: null,
      nonviolating: 0,
      violating: 0,
    };
  }

  if (aggregatedData["Global Privacy Control"] === undefined) {
    aggregatedData["Global Privacy Control"] = {
      date: null,
      nonviolating: 0,
      violating: 0,
    };
  }

  if (aggregatedData["Global"] === undefined) {
    aggregatedData["Global"] = {
      date: null,
      nonviolating: 0,
      violating: 0,
    };
  }

  return aggregatedData;
}
