export function getConsentErrorMessage(org) {
  return `
  Thank you for utilizing AdTechDNA.
  As of now, there are no Analytics containers set up with Consent Monitoring configured${
    org ? ` for the ${org.toUpperCase()} organization` : ""
  }.
  We encourage you to coordinate with your InfoTrust advisor to make sure your configuration rules are properly established.
`;
}

export function getDashboardErrorMessage(org) {
  return `
  Thank you for utilizing AdTechDNA.
  As of now, there are no Analytics containers set up to retrieve data${
    org ? ` for the ${org.toUpperCase()} organization` : "for your system"
  }.
  We encourage you to coordinate with your InfoTrust advisor to make sure your configuration rules are properly established.
`;
}

export function getDetailErrorMessage(org, item) {
  return `
  Thank you for utilizing AdTechDNA.
  As of now, we are unable to find ${item ? item : "this item"}${
    org ? ` for the ${org.toUpperCase()} organization` : ""
  }.
  If this seems incorrect, we encourage you to coordinate with your InfoTrust advisor to make sure your configuration rules are properly established.
`;
}

export function getRuleOverviewMessage(org) {
  return `
  Thank you for utilizing AdTechDNA.
  As of now, we are unable to find a rule overview${
    org ? ` for the ${org.toUpperCase()} organization` : " for your system"
  }.
  If this seems incorrect, we encourage you to coordinate with your InfoTrust advisor to make sure your configuration rules are properly established.
`;
}

export function getGithubErrorMessage() {
  return `
  We are unable to load the Github Changelog.
  If this error persists, we encourage you to coordinate with your InfoTrust advisor.
`;
}

export function getUserManagementErrorMessage(org) {
  return `
  Oops. Something went wrong when trying to fetch your user data${
    org ? ` for the ${org.toUpperCase()} organization` : " for your system"
  }.
  If this issue persists, we encourage you to coordinate with your InfoTrust advisor.
`;
}

export function getNoOrgErrorMessage() {
  return `Oops. You have no defined organization. We encourage you to coordinate with your InfoTrust advisor to connect with an organization.
`;
}

export function getMediaPerformanceErrorMessage(org) {
  return `
  Thank you for utilizing AdTechDNA. As of now, Media Performance is not configured ${
    org ? ` for the ${org.toUpperCase()} organization` : " for your system"
  } We encourage you to coordinate with your InfoTrust advisor to make sure your configuration rules are properly established.`;
}
