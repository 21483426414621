<template>
  <section class="login-area">
    <div class="widget">
      <DisplaySvg name="logo-full" class="logo" />
      <h1>
        Welcome to <br />
        AdTech DNA
      </h1>
      <a :href="googleLoginUrl" class="button login-button">
        <DisplaySvg name="google" class="google-icon" />
        Login <span>&nbsp;With Google</span>
      </a>
    </div>
  </section>
</template>

<script>
import { useRoute } from "vue-router";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";

export default {
  name: "LoginView",
  components: {
    DisplaySvg,
  },
  watch: {
    "$store.state.userData": function () {
      this.checkForLoggedIn();
    },
  },
  computed: {
    googleLoginUrl() {
      return process.env.VUE_APP_GOOGLE_LOGIN_URL;
    },
  },
  methods: {
    exchangeCodeForToken(code) {
      this.axios
        .post(
          process.env.VUE_APP_AUTHENTICATION_URL,
          {
            method: "auth.exchangeCodeForToken",
            params: { code: code },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          let expires = { expires: Math.floor(Date.now() / 1000) + 3600 };
          this.$store.commit("setUserData", {
            ...response.data.result,
            ...expires,
          });
        });
    },
    checkForLoggedIn() {
      if (this.$store.state.userData != null) {
        this.$router.push({ path: "/dashboard" });
      }
    },
  },
  created() {
    const route = useRoute();
    if (route.query.authCode != null) {
      this.exchangeCodeForToken(route.query.authCode);
    }
  },
  mounted() {
    this.checkForLoggedIn();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
.login-area {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 64px);

  .widget {
    width: 100%;
    max-width: 400px;
  }

  h1 {
    line-height: 38px;
    text-align: center;
  }
  .logo {
    margin-top: 20px;
    width: 210px;
  }
  .login-button {
    margin-bottom: 20px;
    padding: 12px 20px 10px 20px;

    @include hover {
      background-color: $light-gray;
      color: $blue;
      border: 1px solid $blue;
    }
    .google-icon {
      width: 18px;
      position: relative;
      top: 1px;
      margin-right: 20px;
    }

    span {
      @include media($x-small-highest, down) {
        display: none;
      }
    }
  }
}
</style>
