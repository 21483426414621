<template>
  <div class="invite-users">
    <h2 class="title">
      <span v-if="!this.email && !this.role">Add User</span>
      <span v-else>Edit User</span>
    </h2>

    <div class="for-org">
      For Organization: <span>{{ this.org }}</span>
    </div>

    <div class="input-inset-label">
      <label>Email</label>
      <NInput
        v-model:value="inviteEmailInputValue"
        placeholder="jane.doe@infotrustllc.com"
      />
    </div>
    <div class="select-inset-label">
      <label>Role</label>
      <n-select :options="roleOptions" v-model:value="roleInputValue" />
    </div>

    <div class="error" v-if="inviteUserError">{{ inviteUserError }}</div>

    <div class="buttons">
      <button class="button white-btn" @click="closeClick" v-if="closeClick">
        Cancel
      </button>
      <button
        class="button blue-btn"
        @click="inviteUser"
        v-if="!showInviteSuccess"
      >
        <span v-if="!this.email && !this.role">Invite</span>
        <span v-else>Save</span>
      </button>
    </div>

    <div class="success" v-if="showInviteSuccess">
      Invited <DisplaySvg name="checkmark" class="success-icon" />
    </div>
  </div>
</template>

<script>
import CredentialService from "@/services/CredentialService";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
import { NInput, NSelect } from "naive-ui";

export default {
  name: "InviteUsersForm",
  components: { DisplaySvg, NInput, NSelect },
  props: ["closeClick", "email", "org", "role", "userid", "userTable"],
  data() {
    return {
      inviteUserError: null,
      showInviteSuccess: false,
      roleInputValue: this.role ? this.role : "Admin",
      inviteEmailInputValue: this.email ? this.email : null,
      NODE_ENV: process.env.NODE_ENV,

      // right now ability to add "Viewer" role on the backend is not enabled
      // roleOptions: [
      //   { label: "Admin", value: "admin" },
      //   { label: "Viewer", value: "viewer" },
      // ],
      roleOptions: [{ label: "Admin", value: "admin" }],
    };
  },
  methods: {
    emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    inviteUser() {
      // error validation
      this.inviteUserError = null;
      let errors = [];

      const findIndex = this.userTable.findIndex((u) => {
        return u && `${u.email}` === `${this.inviteEmailInputValue}`;
      });

      if (!this.inviteEmailInputValue || this.inviteEmailInputValue === "") {
        errors.push("email address is required");
      } else if (!this.emailIsValid(this.inviteEmailInputValue)) {
        errors.push("email address is invalid");
      } else if (findIndex >= 0 && !this.userid) {
        errors.push(
          `this user already exists under the ${this.org.toUpperCase()} organization`
        );
      }

      if (errors.length > 0) {
        return (this.inviteUserError = errors.join(", "));
      }

      // if this is the demo env, stop here and don't actually make any calls
      if (process.env.NODE_ENV === "demo") {
        if (this.closeClick) {
          this.closeClick();
        }
        return;
      }

      CredentialService.call("invites.invite", {
        inviteEmail: this.inviteEmailInputValue,
        orgid: this.org,
        roles: [
          this.roleInputValue.toLowerCase() === "admin" ? "admin" : "user",
        ],
      })
        .then((inviteResponse) => {
          if (inviteResponse.error) {
            this.triggerError({
              message: "invites.invite - error obj returned from api",
              error: inviteResponse.error,
            });
            return Promise.resolve();
          }

          // if this is a user we are editing and not a new user
          if (this.userid) {
            // after adding the new role, we have to remove the old role
            // if we only add the new role w/o removing, then if we change
            // a user's access from admin to user they may still have admin access
            return CredentialService.call("userroles.delete", {
              userid: this.userid,
              orgid: this.org,
              role:
                this.roleInputValue.toLowerCase() === "admin"
                  ? "user"
                  : "admin",
            })
              .then((deleteResponse) => {
                if (deleteResponse.error) {
                  this.triggerError({
                    message: "userroles.delete - error obj returned from api",
                    error: deleteResponse.error,
                    info: "this is a user we are editing and not a new user",
                  });
                  return Promise.resolve();
                }

                this.showInviteSuccess = true;
                this.inviteEmailInputValue = null;

                if (this.closeClick) {
                  this.closeClick();
                }
              })
              .catch((error) => {
                this.triggerError({
                  message: "userroles.delete - catch",
                  error: error,
                  info: "this is a user we are editing and not a new user",
                });
              });
          }

          this.showInviteSuccess = true;
          this.inviteEmailInputValue = null;

          if (this.closeClick) {
            this.closeClick();
          }
        })
        .catch((error) => {
          this.triggerError({
            message: "invites.invite - catch",
            error: error,
          });
        });
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.inviteUserError = "Error - please try again";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.invite-users {
  width: 100%;

  .for-org {
    color: $dark-gray;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    span {
      color: $navy;
    }
  }

  .blue-btn {
    background-color: $blue;
    color: $white;
  }

  .white-btn {
    margin-right: 16px;
  }

  .small-btn {
    border-radius: 25px;
    margin-bottom: 30px;
  }

  &.widget {
    align-items: flex-start;
  }

  button {
    margin: 20px 0 0 0;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .success {
    margin: 20px 0 0 auto;
    background-color: $green;
    border-radius: $border-radius-2;
    padding: 6px 16px;
    font-size: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 500;
    display: flex;
    width: 120px;
    color: $white;
    border: 1px solid $green;
    height: 34px;
    .success-icon {
      color: $white;
      width: 16px;
      height: 16px;
      margin-left: 10px;
    }
  }

  .error {
    text-align: right;
    width: 100%;
    font-size: 14px;
    color: $red;
    margin-top: 5px;
  }
}
</style>
