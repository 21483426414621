<template>
  <div v-if="!hide" :class="`compliance-score widget half`">
    <h2 class="title">Compliance Score</h2>
    <div :class="NODE_ENV === 'demo' ? 'flex-content' : 'stack-content'">
      <div>
        <DoughnutChartScore :score="score" :size="size" />
      </div>
      <div v-if="NODE_ENV === 'demo'">
        <p>
          AdTech DNA has
          <strong>{{ scans[0].rule_fail_count }} recommendations</strong> to
          help improve your compliance score.
        </p>
        <router-link to="/recommendations" class="button">
          View All
        </router-link>
      </div>
      <div v-if="NODE_ENV !== 'demo'">
        <p>AdTech DNA can help improve your compliance score.</p>
        <router-link
          :to="`/platform-governance/violations?org=${encodeURIComponent(
            this.$store.state.organization
          )}`"
          class="button"
        >
          View Violations
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import DoughnutChartScore from "@/components/Shared/DoughnutChartScore.vue";

export default {
  name: "ComplianceScore",
  components: { DoughnutChartScore },
  props: ["scans"],
  methods: {
    calcScore() {
      if (!this.scans[0] || this.scans[0].checks_count === null) {
        this.hide = true;
        this.score = 0;
      } else {
        this.hide = false;
        this.score = Math.floor(
          this.scans && this.scans[0]
            ? (this.scans[0].rule_pass_count /
                (this.scans[0].rule_pass_count +
                  this.scans[0].rule_fail_count)) *
                100
            : 0
        );
      }
    },
  },
  mounted() {
    if (this.scans) {
      this.calcScore();
    }
  },
  watch: {
    "$store.state.scans": function () {
      this.$nextTick(async () => {
        if (this.$store.state.scans) {
          this.getData();
        }
      });
    },
  },
  data() {
    return {
      size: "large",
      score: 0,
      hide: true,
      NODE_ENV: process.env.NODE_ENV,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
.compliance-score {
  h2 {
    margin-top: 0;
    width: 100%;
    text-align: left;

    @include media($large-lowest, down) {
      text-align: center;
    }

    @include media($medium, down) {
      text-align: left;
    }

    @include media($x-small-highest, down) {
      text-align: center;
    }
  }

  .stack-content {
    text-align: center;
    .graph {
      margin: 0 auto;
    }
  }

  .flex-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    @include media($large-lowest, down) {
      flex-wrap: wrap;
    }

    > div {
      &:first-child {
        width: 200px;

        @include media($large, down) {
          width: 170px;
        }

        @include media($large-lowest, down) {
          margin: 0 auto 10px auto;
          width: 200px;
        }

        @include media($medium, down) {
          margin: 0;
        }

        @include media($small-lowest, down) {
          width: 170px;
        }

        @include media($x-small-highest, down) {
          margin: 0 auto 10px auto;
        }
      }
      &:last-child {
        width: calc(100% - 230px);

        @include media($large, down) {
          width: calc(100% - 200px);
        }

        @include media($large-lowest, down) {
          width: 100%;
          text-align: center;
        }

        @include media($medium, down) {
          width: calc(100% - 230px);
          text-align: left;
        }

        @include media($small-lowest, down) {
          width: calc(100% - 190px);
        }

        @include media($x-small-highest, down) {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
</style>
