<template>
  <div v-if="!hide" :class="`compliance-score widget ${size === 'half' ? 'half' : 'full'}`">
    <!-- Full version -->
    <template v-if="size !== 'half'">
      <h2 class="title">Platform Governance &amp; Standards</h2>
      <div class="flex-content">
        <div class="score-section">
          <DoughnutChartScoreHalf :score="score" :size="size" />
          <h3>Compliance Score</h3>
          <div class="compliance-pill">
            <Pill link="/" text="Room to Improve" color="yellow" />
          </div>
        </div>
        <div class="details-section">
          <p>
            AdTech DNA calculates your compliance score using X, Y, &amp; Z. More
            description of a Compliance Score here.
          </p>
          <router-link to="/platform-governance/overview" class="button">
            How to Improve Score
          </router-link>
          <AlertBox
      :title="formattedMisconfiguredSpend"
      text="Amount Spent on Misconfigured Campaigns"
    />
          <h4>Violations by Severity</h4>
          <HorizontalBarGraphic />
        </div>
      </div>
    </template>

     <!-- Half version -->
     <template v-else>
      <h3 class="widget-title">Compliance Score</h3>
      <div class="half-layout">
        <div class="chart-container">
          <DoughnutChartScoreHalf :score="score" :size="size" />
        </div>
        
        <div class="content-container">
          <p>
            AdTech DNA calculates your compliance score using X, Y, &amp; Z. More
            description of a Compliance Score here.
          </p>
          <router-link to="/platform-governance/overview" class="button">
            How to Improve Score
          </router-link>
        </div>
      </div>

      <div class="alert-container">
        <AlertBox
      :title="formattedMisconfiguredSpend"
      text="Amount Spent on Misconfigured Campaigns"
    />
      </div>
    </template>
  </div>
</template>

<script>
import DoughnutChartScoreHalf from "@/components/Shared/DoughnutChartScoreHalf.vue";
import HorizontalBarGraphic from "@/components/Shared/HorizontalBarGraphic.vue";
import AlertBox from "@/components/Shared/AlertBox.vue";
import Pill from "@/components/Shared/Pill.vue";

export default {
  name: "ComplianceScore",
  components: { DoughnutChartScoreHalf, HorizontalBarGraphic, Pill, AlertBox },
  props: {
    scans: {
      type: Array,
      required: true
    },
    size: {
      type: String,
      default: 'large',
      validator: value => ['large', 'half'].includes(value)
    }
  },
  computed: {
    misconfiguredSpend() {
      // Use demo data if in demo mode
      if (process.env.NODE_ENV === "demo") {
        return 10419; // Demo value
      }

      // Otherwise use real data from scans
      if (!this.scans || !this.scans[0]) return 0;
      return this.scans[0].misconfigured_spend || 0;
    },
    formattedMisconfiguredSpend() {
      // Format the number as currency
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(this.misconfiguredSpend);
    }
  },
  methods: {
    calcScore() {
      if (!this.scans[0] || this.scans[0].checks_count === null) {
        this.hide = true;
        this.score = 0;
      } else {
        this.hide = false;
        this.score = Math.floor(
          this.scans && this.scans[0]
            ? (this.scans[0].rule_pass_count /
                (this.scans[0].rule_pass_count +
                  this.scans[0].rule_fail_count)) *
                100
            : 0
        );
      }
    },
    getData() {
      if (this.scans) {
        this.calcScore();
      }
    }
  },
  mounted() {
    this.getData();
  },
  watch: {
    "$store.state.scans": function() {
      this.$nextTick(async () => {
        if (this.$store.state.scans) {
          this.getData();
        }
      });
    },
    // Add watch for misconfigured spend to debug
    misconfiguredSpend: {
      handler(newValue) {
        console.log('Misconfigured spend updated:', newValue);
      },
      immediate: true
    }
  },
  data() {
    return {
      score: 0,
      hide: true,
      NODE_ENV: process.env.NODE_ENV,
    };
  },
};
</script>

<style lang="scss">
@import "@/styles/_helpers.scss";

.compliance-score {
  .compliance-pill {
    text-align: center;
  }

  h2 {
    margin-top: 0;
    width: 100%;
    text-align: left !important;

    @include media($large-lowest, down) {
      text-align: center !important;
    }
  }

  h3 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    position: relative;
    z-index: 2;
    text-align: center;
    width: 100%;
  }

  h4 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    width: 100%;
    text-transform: uppercase;
  }

  &.half {
    position: relative;
    padding: 1.5rem;
    overflow: hidden;
    
    .widget-title {
      font-size: 24px;
      line-height: 1.2;
      font-weight: 600;
      color: $navy;
      margin: 0 0 1.5rem;
      text-align: left;
    }
    
    .half-layout {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 2rem;
  }

    .chart-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .content-container {
      padding-top: 1.5rem;
      padding-left: 10rem;

      p {
        font-size: 14px;
        line-height: 1.6;
        margin-bottom: 1.5rem;
        color: $navy;
      }

      .button {
        display: inline-flex;
      }
    }

    .alert-container {
    width: 90%;
  }
  }

  &.full {
    .flex-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;

      @include media($large-lowest, down) {
        flex-wrap: wrap;
      }

      .score-section {
        width: 380px;

        @include media($large, down) {
          width: 340px;
        }

        @include media($large-lowest, down) {
          width: 100%;
        }
      }

      .details-section {
        width: calc(100% - 410px);

        @include media($large, down) {
          width: calc(100% - 370px);
        }

        @include media($large-lowest, down) {
          width: 100%;
          text-align: center;
          p {
            text-align: center;
          }
          a {
            width: 215px;
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
</style>