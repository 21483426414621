<template>
    <div class="filters-container">
      <div class="active-filters" v-if="activeFilters.length > 0">
        <div 
          v-for="{ dimension, value } in activeFilters" 
          :key="`${dimension}-${value}`"
          class="filter-tag"
        >
          <span>{{ formatValue(value) }}</span>
          <button
            @click="() => onRemoveFilter(dimension)"
            class="remove-button"
            :aria-label="`Remove ${value} filter`"
          >
            <img src="../../assets/svgs/x.svg" width="12" height="12" alt="Remove" />
          </button>
        </div>
      </div>
      <slot></slot>
    </div>
   </template>
  
  <script>
  export default {
    name: 'AppliedFiltersTags',
    props: {
      filters: {
        type: Object,
        required: true
      },
      onRemoveFilter: {
        type: Function,
        required: true
      }
    },
    computed: {
      activeFilters() {
        return Object.entries(this.filters || {}).reduce((acc, [key, value]) => {
          if (value && value !== 'all') {
            acc.push({ dimension: key, value });
          }
          return acc;
        }, []);
      }
    },
    methods: {
        formatValue(value) {
            return String(value);
        }
        }
  }
  </script>
  
  <style lang="scss" scoped>
  .filters-container {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .active-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .filter-tag {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 4px 10px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    font-size: 13px;
    color: #333;
    
    .remove-button {
  display: flex;
  align-items: center;
  padding: 0;
  color: #666;
  transition: color 0.2s ease;
  line-height: 0;
  border: none; 
  background: none; 
  cursor: pointer; 
  
  &:hover {
    color: #333;
  }
}
  }
  </style>