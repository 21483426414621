<template>
  <div class="widget full metrics-widget">
    <div class="title" v-if="metrics.title">
      <h2>
        {{ metrics.title }}
      </h2>
    </div>
    <div class="content">
      <div class="metric">
        <div class="metric-title">Total Spend</div>
        <div class="metric-box">
          ${{ formatLargeNumber(metrics.total_spend) }}
        </div>
        <div class="metric-delta" v-if="metrics.total_spend_average !== null">
          <span
            :class="
              getClasses(
                formatDelta(metrics.total_spend, metrics.total_spend_average)
                  .direction
              )
            "
          >
            {{
              formatDelta(metrics.total_spend, metrics.total_spend_average)
                .delta
            }}
          </span>
          VS. PREVIOUS
          <NTooltip placement="bottom" trigger="hover">
            <template #trigger>
              <DisplaySvg name="info" class="info-icon" />
            </template>
            <div class="info-text">
              Compared to the previous month's ({{
                formatDate(previousDateRange.dateRangeBegin)
              }}) Total Spend of ${{
                formatLargeNumber(metrics.total_spend_average)
              }}
            </div>
          </NTooltip>
        </div>
      </div>
      <div class="metric">
        <div class="metric-title">Impressions</div>
        <div class="metric-box">
          {{ formatLargeNumber(metrics.impressions) }}
        </div>
        <div class="metric-delta" v-if="metrics.impressions_average !== null">
          <span
            :class="
              getClasses(
                formatDelta(metrics.impressions, metrics.impressions_average)
                  .direction
              )
            "
          >
            {{
              formatDelta(metrics.impressions, metrics.impressions_average)
                .delta
            }}
          </span>
          VS. PREVIOUS
          <NTooltip placement="bottom" trigger="hover">
            <template #trigger>
              <DisplaySvg name="info" class="info-icon" />
            </template>
            <div class="info-text">
              Compared to the previous month's ({{
                formatDate(previousDateRange.dateRangeBegin)
              }}) Impressions of
              {{ formatLargeNumber(metrics.impressions_average) }}
            </div>
          </NTooltip>
        </div>
      </div>
      <div class="metric">
        <div class="metric-title">CPM</div>
        <div class="metric-box">${{ formatCurrency(metrics.cpm, 2) }}</div>
        <div class="metric-delta" v-if="metrics.cpm_average !== null">
          <span
            :class="
              getClasses(
                formatDelta(metrics.cpm, metrics.cpm_average).direction
              )
            "
          >
            {{ formatDelta(metrics.cpm, metrics.cpm_average).delta }}
          </span>
          VS. PREVIOUS
          <NTooltip placement="bottom" trigger="hover">
            <template #trigger>
              <DisplaySvg name="info" class="info-icon" />
            </template>
            <div class="info-text">
              Compared to the previous month's ({{
                formatDate(previousDateRange.dateRangeBegin)
              }}) CPM of ${{ formatCurrency(metrics.cpm_average, 2) }}
            </div>
          </NTooltip>
        </div>
      </div>
      <div class="metric">
        <div class="metric-title">CPCV</div>
        <div class="metric-box">${{ formatCurrency(metrics.cpcv, 4) }}</div>
        <div class="metric-delta" v-if="metrics.cpcv_average !== null">
          <span
            :class="
              getClasses(
                formatDelta(metrics.cpcv, metrics.cpcv_average).direction
              )
            "
          >
            {{ formatDelta(metrics.cpcv, metrics.cpcv_average).delta }}
          </span>
          VS. PREVIOUS
          <NTooltip placement="bottom" trigger="hover">
            <template #trigger>
              <DisplaySvg name="info" class="info-icon" />
            </template>
            <div class="info-text">
              Compared to the previous month's ({{
                formatDate(previousDateRange.dateRangeBegin)
              }}) CPCV of ${{ formatCurrency(metrics.cpcv_average, 4) }}
            </div>
          </NTooltip>
        </div>
      </div>
      <div class="metric">
        <div class="metric-title">Clicks</div>
        <div class="metric-box">
          {{ formatLargeNumber(metrics.clicks) }}
        </div>
        <div class="metric-delta" v-if="metrics.clicks_average !== null">
          <span
            :class="
              getClasses(
                formatDelta(metrics.clicks, metrics.clicks_average).direction
              )
            "
          >
            {{ formatDelta(metrics.clicks, metrics.clicks_average).delta }}
          </span>
          VS. PREVIOUS
          <NTooltip placement="bottom" trigger="hover">
            <template #trigger>
              <DisplaySvg name="info" class="info-icon" />
            </template>
            <div class="info-text">
              Compared to the previous month's ({{
                formatDate(previousDateRange.dateRangeBegin)
              }}) Clicks of {{ formatLargeNumber(metrics.clicks_average) }}
            </div>
          </NTooltip>
        </div>
      </div>
      <div class="metric">
        <div class="metric-title">CTR</div>
        <div class="metric-box">{{ formatPercent(metrics.ctr, 4) }}%</div>
        <div class="metric-delta" v-if="metrics.ctr_average !== null">
          <span
            :class="
              getClasses(
                formatDelta(metrics.ctr, metrics.ctr_average).direction
              )
            "
          >
            {{ formatDelta(metrics.ctr, metrics.ctr_average).delta }}
          </span>
          VS. PREVIOUS
          <NTooltip placement="bottom" trigger="hover">
            <template #trigger>
              <DisplaySvg name="info" class="info-icon" />
            </template>
            <div class="info-text">
              Compared to the previous month's ({{
                formatDate(previousDateRange.dateRangeBegin)
              }}) CTR of ${{ formatPercent(metrics.ctr_average, 4) }}
            </div>
          </NTooltip>
        </div>
      </div>
      <div class="metric">
        <div class="metric-title">Total Conversions</div>
        <div class="metric-box">
          {{ formatLargeNumber(metrics.conversions) }}
        </div>
        <div class="metric-delta" v-if="metrics.conversions_average !== null">
          <span
            :class="
              getClasses(
                formatDelta(metrics.conversions, metrics.conversions_average)
                  .direction
              )
            "
          >
            {{
              formatDelta(metrics.conversions, metrics.conversions_average)
                .delta
            }}
          </span>
          VS. PREVIOUS
          <NTooltip placement="bottom" trigger="hover">
            <template #trigger>
              <DisplaySvg name="info" class="info-icon" />
            </template>
            <div class="info-text">
              Compared to the previous month's ({{
                formatDate(previousDateRange.dateRangeBegin)
              }}) Total Conversions of
              {{ formatLargeNumber(metrics.conversions_average) }}
            </div>
          </NTooltip>
        </div>
      </div>
      <div class="metric">
        <div class="metric-title">CPA</div>
        <div class="metric-box">${{ formatCurrency(metrics.cpa, 2) }}</div>
        <div class="metric-delta" v-if="metrics.cpa_average !== null">
          <span
            :class="
              getClasses(
                formatDelta(metrics.cpa, metrics.cpa_average).direction
              )
            "
          >
            {{ formatDelta(metrics.cpa, metrics.cpa_average).delta }}
          </span>
          VS. PREVIOUS
          <NTooltip placement="bottom" trigger="hover">
            <template #trigger>
              <DisplaySvg name="info" class="info-icon" />
            </template>
            <div class="info-text">
              Compared to the previous month's ({{
                formatDate(previousDateRange.dateRangeBegin)
              }}) CPA of ${{ formatCurrency(metrics.cpa_average, 2) }}
            </div>
          </NTooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { NTooltip } from "naive-ui";
import { getDelta } from "@/Helpers.js";
import DisplaySvg from "@/components/Shared/DisplaySvg";

export default {
  name: "MetricsWidget",
  components: { NTooltip, DisplaySvg },
  props: ["metrics", "allDataDateStart", "allDataDateEnd", "previousDateRange"],
  methods: {
    getClasses(direction) {
      return {
        positive: direction === "positive",
        negative: direction === "negative",
      };
    },
    formatDate(date) {
      return moment(date).format("MMMM YYYY");
    },
    formatDelta(current, median) {
      return getDelta(current, median);
    },
    formatLargeNumber(value) {
      // Nine Zeroes for Billions
      return Math.abs(Number(value)) >= 1.0e9
        ? (Math.abs(Number(value)) / 1.0e9).toFixed(2) + " Bil"
        : // Six Zeroes for Millions
        Math.abs(Number(value)) >= 1.0e6
        ? (Math.abs(Number(value)) / 1.0e6).toFixed(2) + " Mil"
        : // Three Zeroes for Thousands
        Math.abs(Number(value)) >= 1.0e3
        ? (Math.abs(Number(value)) / 1.0e3).toFixed(2) + " K"
        : this.formatCurrency(value, 0);
    },
    formatCurrency(value, dec) {
      let formatter = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: dec,
        minimumFractionDigits: dec,
      });
      const newValue =
        value > 0 ? ((value * 1000) / 1000).toFixed(dec) : "0.0000";
      return `${formatter.format(newValue)}`;
    },
    formatPercent(value, dec) {
      return value.toFixed(dec);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.metrics-widget {
  .info-icon {
    height: 14px;
    width: 14px;
    display: inline-block;
    position: relative;
    top: 1px;
    margin-left: 5px;
  }

  .content {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(4, 1fr);
    flex-wrap: wrap;
    width: 100%;
    gap: 30px;
    margin-top: 30px;
    margin-bottom: 20px;

    .metric {
      grid-column: span 1;

      @include media($medium-highest, down) {
        grid-column: span 2;
      }

      @include media($x-small-highest, down) {
        grid-column: span 4;
      }
    }
  }

  .metric-title {
    font-weight: 600;
    color: $navy;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 10px;
  }
  .metric-box {
    background-color: $light-gray;
    border-radius: $border-radius-2;
    padding: 20px 10px;
    font-size: 28px;
    font-weight: 500;
    line-height: 38px;
    color: $navy;
    text-align: center;
    max-width: 190px;
    margin: 0 auto;
  }
  .metric-delta {
    margin-top: 10px;
    color: $dark-gray;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    .positive {
      color: $green;
      font-size: 15px;
      margin-right: 5px;
    }
    .negative {
      color: $red;
      font-size: 15px;
      margin-right: 5px;
    }
  }
}
</style>
