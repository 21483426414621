<template>
  <div class="widget containers-violations" :class="size">
    <div class="title-with-btn">
      <h2 class="title">
        AdTech DNA Containers
        <NTooltip trigger="hover">
          <template #trigger>
            <div class="info-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M12 16v-4"></path>
                <path d="M12 8h.01"></path>
              </svg>
            </div>
          </template>
          Information about containers with most violations
        </NTooltip>
        with Most Violations
      </h2>
    </div>
    <div class="containers-list">
      <div v-for="container in containers" :key="container.name" class="container-item">
        <span class="name">{{ container.name }}</span>
        <div class="actions">
          <span class="violation-count">{{ container.violations }}</span>
          <router-link 
            :to="`/platform-governance/details/${container.id}?org=${encodeURIComponent(
              this.$store.state.organization
            )}`"
            class="view-link"
          >
            VIEW →
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NTooltip } from 'naive-ui';
import AdtechService from "@/services/AdtechService";

export default {
  name: "ContainersMostViolations",
  components: {
    NTooltip
  },
  props: {
    scans: {
      type: Array,
      required: true
    },
    size: {
      type: String,
      default: "half"
    }
  },
  data() {
  return {
    isDemoEnv: process.env.NODE_ENV === 'demo',
    localLoading: false,
    containerData: []
  }
},
computed: {
  currentScanId() {
    return this.scans?.[0]?.scanid;
  },
  filterParams() {
    return this.$store.getters.currentFilterParams;
  },
  containers() {
    if (this.isDemoEnv) {
      return [
        { id: 'ga4-skkn', name: 'GA4 - SKKN Stage - GA4', violations: 6 },
        { id: 'ga4-coty', name: 'GA4 - Coty_Luxury_Orveda_Serverside', violations: 3 },
        { id: 'cm360-zenith', name: 'CM360 - Zenith_Philosophy_LB_CA', violations: 2 },
        { id: 'ga4-coty-consumer', name: 'GA4 - Coty_Consumerbeauty_Rollup', violations: 2 }
      ]
    }
    return this.getTopContainers(this.containerData);
  }
},
methods: {
  getTopContainers(data) {
    return [...data]
      .sort((a, b) => b.violations - a.violations)
      .slice(0, 4);
  },

  async fetchContainerViolations() {
    if (this.isDemoEnv || !this.currentScanId) return;
    
    this.localLoading = true;
    try {
      const response = await AdtechService.call(
        "reports.custom.scanRuleRunsAggregate",
        {
          scanid: this.currentScanId,
          filter: this.filterParams || {},
          groupBy: ["containerid"] 
        }
      );
      if (response?.result?.['custom.scanRuleRunsAggregate']) {
        this.containerData = response.result['custom.scanRuleRunsAggregate']
          .map(item => ({
            id: item.group.containerid,
            name: item.group.container_name || 'Unknown Container',
            violations: item.violation_count || 0
          }))
          .filter(item => item.violations > 0);
      }
    } catch (error) {
      console.error('Error fetching container violations:', error);
    } finally {
      this.localLoading = false;
    }
  }
},
  watch: {
    currentScanId: {
      immediate: true,
      handler() {
        this.fetchContainerViolations();
      }
    },
    filterParams: {
      handler() {
        this.fetchContainerViolations();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.containers-violations {
  align-items: stretch;
  
  .title-with-btn {
    margin-bottom: 1.5rem;
    width: 100%; 
    
    .title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.1rem;
      margin: 0;
    }

    .info-icon {
      cursor: pointer;
      display: flex;
      align-items: center;
      
      svg {
        width: 16px;
        height: 16px;
        color: $dark-gray;
      }
    }
  }

  .containers-list {
    width: 100%;
    
    .container-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0.75rem 0;
      border-bottom: 1px solid $navy;
      transition: background-color 0.2s ease;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: rgba($navy, 0.1);
      }

      .name {
        color: $navy;
        font-weight: 500;
        flex: 1;
        font-size: 0.9rem;
      }

      .actions {
        display: flex;
        gap: 1rem;
        white-space: nowrap;

        .violation-count {
          display: inline-flex;
          justify-content: center;
          min-width: 20px;
          height: 20px;
          padding: 0 6px;
          border-radius: 10px;
          background-color: $red-lighter;
          color: $red;
          font-weight: 600;
          font-size: 0.8rem;
        }

        .view-link {
          color: $navy;
          text-decoration: none;
          font-weight: 500;
          font-size: 0.8rem;
          transition: color 0.2s ease;

          &:hover {
            color: $blue;
          }
        }
      }
    }
  }
}
</style>