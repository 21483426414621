<template>
  <div class="widget half inventory-source-type">
    <div class="title">
      <h2>
        Inventory Source Type
        <NTooltip placement="bottom" trigger="hover">
          <template #trigger>
            <DisplaySvg name="info" class="info-icon" />
          </template>
          <div class="info-text">
            Public inventory is commonly referred to as the Open Exchange
            Private inventory refers to a private deal (PMPs, Direct Deals,
            Preferred Deals, etc.)
          </div>
        </NTooltip>
      </h2>
    </div>
    <div class="content">
      <div class="graph">
        <div class="label">Percent of Spend</div>
        <Doughnut
          :data="chartData"
          :options="chartOptions"
          :style="chartStyles"
        />
      </div>
      <div class="key">
        <div>
          <p class="key-title">
            Public
            <NTooltip
              placement="bottom"
              trigger="hover"
              v-if="this.public_past !== null && previousDateRange"
            >
              <template #trigger>
                <DisplaySvg name="info" class="info-icon-small" />
              </template>
              <div class="info-text">
                Compared to previous month's ({{
                  formatDate(previousDateRange.dateRangeBegin)
                }}) public spend percentage of
                {{ formatPercent(this.public_past) }}.
              </div>
            </NTooltip>
          </p>
          <p class="key-text">
            <span>{{ formatPercent(this.public) }}</span>
            <Delta
              :past_count="this.public_past"
              :current_count="this.public"
              v-if="this.public_past !== null && previousDateRange"
            />
          </p>
        </div>
        <div>
          <p class="key-title">
            Private
            <NTooltip
              placement="bottom"
              trigger="hover"
              v-if="this.private_past !== null && previousDateRange"
            >
              <template #trigger>
                <DisplaySvg name="info" class="info-icon-small" />
              </template>
              <div class="info-text">
                Compared to the previous month's ({{
                  formatDate(previousDateRange.dateRangeBegin)
                }}) private percentage of
                {{ formatPercent(this.private_past) }}.
              </div>
            </NTooltip>
          </p>
          <p class="key-text">
            <span>{{ formatPercent(this.private) }}</span>
            <Delta
              :past_count="this.private_past"
              :current_count="this.private"
              v-if="this.private_past !== null && previousDateRange"
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { NTooltip } from "naive-ui";
import "chart.js/auto";
import { Doughnut } from "vue-chartjs";
import Delta from "@/components/Shared/Delta.vue";
import DisplaySvg from "@/components/Shared/DisplaySvg";

export default {
  name: "InventorySourceType",
  components: {
    Doughnut,
    Delta,
    NTooltip,
    DisplaySvg,
  },
  props: [
    "private",
    "public",
    "private_past",
    "public_past",
    "totalSpend",
    "totalPastSpend",
    "previousDateRange",
  ],
  methods: {
    formatPercent(num) {
      return `${num ? num.toFixed(1) : 0}%`;
    },
    formatCurrency(value, dec) {
      let nf = new Intl.NumberFormat("en-US");
      return `$${nf.format(((value * 100) / 100).toFixed(dec))}`;
    },
    formatDate(date) {
      return moment(date).format("MMMM YYYY");
    },
  },
  data() {
    return {
      chartData: {
        datasets: [
          {
            data: [this.public, this.private],
            backgroundColor: ["#4E957E", "#A1D3C5"],
            borderColor: ["#4E957E", "#A1D3C5"],
            borderWidth: 1,
            rotation: 180,
          },
        ],
      },
      chartOptions: {
        // This chart will not respond to any events
        // events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove']
        events: [],
        cutout: 55,
      },
      chartStyles: { borderJoinStyle: "none" },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.info-icon {
  height: 20px;
  width: 20px;
  display: inline-block;
  position: relative;
  top: 1px;
  margin-left: 5px;
}

.info-icon-small {
  height: 14px;
  width: 14px;
  display: inline-block;
  position: relative;
  top: 1px;
  margin-left: 5px;
  color: $dark-gray;
}

.inventory-source-type {
  .content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    margin-top: -10px;
    max-width: 460px;

    @include media(1620px, down) {
      max-width: 400px;
    }

    @include media($large-lowest, down) {
      flex-wrap: wrap;
      justify-content: center;
      max-width: none;
    }
    @include media($medium, down) {
      flex-wrap: nowrap;
      justify-content: space-between;
      max-width: 360px;
    }
    @include media($x-small-highest, down) {
      flex-wrap: wrap;
      justify-content: center;
      max-width: none;
    }
  }
}

.graph {
  width: 240px;
  height: 240px;
  position: relative;

  .label {
    color: $navy;
    font-size: 20px;
    font-weight: 600;
    width: 110px;
    text-align: center;
    position: absolute;
    height: 46px;
    line-height: 22px;
    top: 105px;
    left: 64px;
  }

  @include media(1620px, down) {
    width: 180px;
    height: 180px;

    .label {
      top: 78px;
      left: 35px;
    }
  }
}

.key {
  @include media(1620px, down) {
    width: 100%;
  }
  @include media($medium, down) {
    width: auto;
  }
  @include media($x-small-highest, down) {
    width: 100%;
  }

  > div {
    position: relative;
    padding-left: 48px;
    width: 190px;
    margin: 20px auto;
    &:before {
      content: "";
      position: absolute;
      height: 20px;
      width: 20px;
      background-color: $dark-teal;
      top: 12px;
      left: 16px;
    }
    &:last-child {
      &:before {
        background-color: $teal;
      }
    }
  }
  .key-title {
    font-weight: 500;
    line-height: 22px;
    font-size: 18px;
    margin: 0;
  }
  .key-text {
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    > span {
      color: $dark-gray;
    }
    div {
      display: inline-block;
      margin-left: 10px;
    }
  }
}
</style>
