<template>
  <div class="graph">
    <div class="score">
      {{ displayNumber || displayNumber === 0 ? displayNumber : `${score}%` }}
    </div>
    <Doughnut :data="chartData" :options="chartOptions" :style="chartStyles" />
  </div>
</template>

<script>
import "chart.js/auto";
import { Doughnut } from "vue-chartjs";

export default {
  name: "DoughnutChartScoreHalf",
  components: {
    Doughnut,
  },
  props: {
    score: {
      type: Number,
      required: true,
    },
  },
  methods: {
    getColor(score) {
      const hexCodes = {
        green: "#76ae00",
        yellow: "#e0c019",
        orange: "#f45d29",
        red: "#d1001f",
      };
      let color = {};
      if (score > 10 && score <= 50) {
        color = { hex: hexCodes.orange, name: "orange" };
      } else if (score > 50 && score <= 90) {
        color = { hex: hexCodes.yellow, name: "yellow" };
      } else if (score > 90) {
        color = { hex: hexCodes.green, name: "green" };
      } else {
        color = { hex: hexCodes.red, name: "red" };
      }
      return color;
    },
  },
  data() {
    const color = this.getColor(this.score).hex;
    return {
      chartData: {
        datasets: [
          {
            data: Array.isArray(this.score)
              ? this.score
              : [this.score, (this.score - 100) * -1],
            backgroundColor: [color, "#f4f6f6"],
            borderColor: [color, "#f4f6f6"],
            borderWidth: 1,
            rotation: -90,
          },
        ],
      },
      chartOptions: {
        // This chart will not respond to any events
        // events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove']
        events: [],
        circumference: 180,
        cutout: "70%",
      },
      chartStyles: { borderJoinStyle: "none" },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.graph {
  width: 380px;
  height: 380px;
  margin: -80px auto;
  position: relative;
  z-index: 1;

  .score {
    letter-spacing: -2px;
    font-weight: 700;
    text-align: center;
    position: absolute;
    color: $navy;
    top: 88px;
    left: 52px;

    padding: 40px 0;
    font-size: 50px;
    line-height: 50px;

    left: 50%;
    margin-left: -110px;
    top: 180px;
    height: 110px;
    width: 220px;

    border-top-left-radius: 110px;
    border-top-right-radius: 110px;
    background-color: $light-gray;
  }

  @include media($large, down) {
    width: 340px;
    height: 340px;

    .score {
      margin-left: -90px;
      top: 170px;
      height: 90px;
      width: 180px;

      padding: 32px 0;
      font-size: 44px;
      line-height: 44px;
    }
  }

  @include media($x-small-highest, down) {
    width: 240px;
    height: 240px;
    margin: -65px auto;

    .score {
      width: 140px;
      height: 70px;
      margin-left: -70px;
      top: 115px;

      padding: 25px 0;
      font-size: 36px;
      line-height: 36px;
    }
  }
}
</style>
