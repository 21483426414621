<template>
  <div class="overlay">
    <div class="content"><slot /></div>
    <div class="overlay-block" @click="closeClick"></div>
  </div>
</template>

<script>
export default {
  name: "Overlay",
  props: ["closeClick"],
  mounted() {
    if (this.closeClick) {
      document.addEventListener("keyup", this.exitOnEscKey, true);
    }
  },
  beforeUnmount() {
    if (this.closeClick) {
      document.removeEventListener("keyup", this.exitOnEscKey);
    }
  },
  methods: {
    exitOnEscKey(e) {
      if (e.keyCode == 27) {
        this.closeClick();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/_helpers.scss";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  .overlay-block {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 999999999;
    background-color: rgba(20, 55, 85, 0.6);
  }
  .content {
    position: relative;
    z-index: 9999999999;
  }
}
</style>
