<template>
  <div class="view-container violation-detail">
    <section class="view-header">
      <div class="half">
        <div class="title-eyebrow">
          <router-link to="/platform-governance/overview" class="eyebrow-link">
            Platform Governance &amp; Standards
          </router-link>
          <DisplaySvg name="caret-right" class="caret" />
          <router-link
            to="/platform-governance/violations"
            class="eyebrow-link"
          >
            Violations
          </router-link>
        </div>
        <div class="view-title-with-btn">
          <div class="view-title">Violation Summary</div>
          <router-link to="" class="button share" v-if="NODE_ENV === 'demo'">
            <DisplaySvg name="share" class="share-icon" />Share
          </router-link>
        </div>
      </div>
      <div class="half"></div>
    </section>

    <div class="workarea">
      <div class="widget full">
        <div v-if="error || loading">
          <div class="site-error" v-if="error || !loading">{{ error }}</div>
          <div class="site-loading" v-if="!error && loading">
            <LoadingSpinner /> Loading...
          </div>
        </div>
        <div class="violation-detail-wrapper" v-if="!error && !loading">
          <div class="severity">
            <DisplaySvg
              :name="
                violation.severity === 0
                  ? 'info'
                  : violation.severity === 5
                  ? 'critical'
                  : 'warning-solid'
              "
              v-bind:class="getSeverityClasses('severity-icon')"
            />
            <h2>Severity</h2>
            <h3 v-bind:class="getSeverityClasses()">
              {{ convertSeverityNum(violation.severity) }}
            </h3>
            <p>Last reported {{ violationTime.daysAgo }}</p>
            <p>{{ violationTime.formattedDate }}</p>
          </div>
          <div class="message">
            <ul>
              <li class="message-item">
                <DisplaySvg name="important-news" class="icon-circle" />
                <p>
                  <strong>Message</strong>
                  {{ violation.message }}
                </p>
              </li>
              <li class="message-item">
                <DisplaySvg name="help" class="icon-circle" />
                <p>
                  <strong>Fix Tip</strong>
                  {{ violation.fixtip }}
                </p>
              </li>
            </ul>
          </div>
          <div class="details">
            <SmallStripedTable :data="detailsTable" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import AdtechService from "@/services/AdtechService";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
import SmallStripedTable from "@/components/Shared/SmallStripedTable.vue";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { getDetailErrorMessage } from "@/ErrorMessaging";
import ruleViolationsFixture from "@/fixtures/ruleViolations.json";
import moment from "moment";

export default {
  name: "ViolationDetailView",
  components: { DisplaySvg, SmallStripedTable, LoadingSpinner },
  props: {},
  watch: {
    "$store.state.scans": function (newScans) {
      this.$nextTick(() => {
        if (newScans && newScans.error) {
          this.loading = false;
          this.error = getDetailErrorMessage(
            this.$store.state.organization,
            `violation ${this.$route.params.id}`
          );
        }

        if (newScans && newScans.length > 0 && !this.violation) {
          this.getViolation();
        }
      });
    },
    "$store.state.featurePermissions": function () {
      this.$nextTick(async () => {
        if (this.$store.state.featurePermissions) {
          // redirect to home if they do not have this feature enabled
          if (!this.$store.state.featurePermissions.platformGovernance) {
            router.push("/");
          }
        }
      });
    },
    "$store.state.organization": function () {
      if (
        encodeURIComponent(this.$route.query.org) !==
        encodeURIComponent(this.$store.state.organization)
      ) {
        router.push("/platform-governance/overview");
      }
    },
  },
  data() {
    return {
      NODE_ENV: process.env.NODE_ENV,
      id: this.$route.params.id,
      violation: null,
      detailsTable: [],
      error: null,
      loading: true,
      violationTime: {
        daysAgo: "",
        formattedDate: "",
      },
    };
  },
  methods: {
    getSeverityClasses(classname) {
      let classObj = {};
      if (classname) {
        classObj[`${classname}`] = true;
      }

      if (this.violation) {
        const severityClassesObj = {
          info: this.violation.severity === 0,
          low: this.violation.severity === 1,
          medium: this.violation.severity === 2,
          high: this.violation.severity === 3,
          severe: this.violation.severity === 4,
          critical: this.violation.severity === 5,
        };
        return { ...classObj, ...severityClassesObj };
      }

      return { ...classObj };
    },
    convertSeverityNum(num) {
      switch (num) {
        case 0:
          return "Info";
        case 1:
          return "Low";
        case 2:
          return "Medium";
        case 3:
          return "High";
        case 4:
          return "Severe";
        case 5:
          return "Critical";
        default:
          return num;
      }
    },
    transformAndSetData(data) {
      this.violation = data;
      const detailsObj = data.details;
      const containerData =
        this.$store.state.containers &&
        !this.$store.state.containers.error &&
        this.$store.state.containers.length > 0
          ? this.$store.state.containers.map((container) => {
              if (container.containerid === this.$route.query.containerid) {
                return container;
              }
            })
          : null;
      const detailsArray = [];

      if (containerData && containerData.length > 0 && containerData[0]) {
        detailsArray.push({
          id: `brand-${this.$route.query.containerid}`,
          label: "Brand",
          value: containerData[0].name,
        });
      }

      detailsArray.push({
        id: `ruleinstanceid-${this.$route.params.id}`,
        label: "Rule Instance Id",
        value: this.$route.params.id,
      });

      detailsArray.push({
        id: `violationnum-${this.$route.query.violationnum}`,
        label: "Violation Number",
        value: this.$route.query.violationnum,
      });

      for (const key in detailsObj) {
        const value = detailsObj[key];
        const label = `${key}`.replace(/([A-Z]+)/g, " $1");
        detailsArray.push({
          id: `${this.$route.query.violationnum}-${key}`,
          label: label[0].toUpperCase() + label.slice(1),
          value: value,
        });
      }

      const date = this.$store.state.scans[0].start_time;
      const daysAgo = moment().diff(date, "days");
      this.violationTime = {
        daysAgo: `${daysAgo} day${daysAgo > 1 ? "s" : ""} ago`,
        formattedDate: `${moment(date).format("MM/DD/YY")} at ${moment(
          date
        ).format("h:mm a")}`,
      };
      this.detailsTable = detailsArray;
    },
    getViolation() {
      if (process.env.NODE_ENV === "demo") {
        this.transformAndSetData(
          ruleViolationsFixture[this.$route.params.id - 1]
        );
        this.loading = false;
        return;
      }

      if (
        this.$store.state.scans &&
        this.$store.state.scans[0] &&
        process.env.NODE_ENV !== "demo"
      ) {
        AdtechService.call("ruleviolations.get", {
          scanid: this.$route.query.scanid,
          containerid: this.$route.query.containerid,
          ruleinstanceid: this.$route.params.id,
          violationnum: this.$route.query.violationnum,
          entityid: this.$route.query.entityid
        })
          .then((response) => {
            if (response.error) {
              this.triggerError({
                message: "ruleviolations.get - error obj returned from api",
                error: response.error,
              });
              return Promise.resolve();
            }

            this.transformAndSetData(response.result.violation);
            this.loading = false;
          })
          .catch((error) => {
            this.triggerError({
              message: "ruleviolations.get - catch",
              error: error,
            });
          });
      }
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.error = getDetailErrorMessage(
        this.$store.state.organization,
        `violation #${this.$route.params.id}`
      );
      this.loading = false;
    },
  },
  mounted() {
    if (this.$store.state.scans && this.$store.state.scans.error) {
      this.error = getDetailErrorMessage(
        this.$store.state.organization,
        `violation #${this.$route.params.id}`
      );
      this.loading = false;
    }

    if (this.$store.state.scans) {
      this.getViolation();
    }

    if (this.$store.state.featurePermissions) {
      // redirect to home if they do not have this feature enabled
      if (!this.$store.state.featurePermissions.platformGovernance) {
        router.push("/");
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.violation-detail-wrapper {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(8, 1fr);
  flex-wrap: wrap;
  width: 100%;
  gap: 1.5rem;
  align-items: center;
  .severity {
    grid-column: span 2;
    text-align: center;

    h2 {
      margin: 0 0 10px 0;
    }

    h3 {
      font-size: 16px;
      font-weight: 700;
      margin: 0 0 10px 0;
    }

    p {
      color: $dark-gray;
      margin: 0;
    }

    .severity-icon {
      width: 35px;
      margin: 5px auto 5px auto;
    }

    .info {
      color: $dark-gray;
    }

    .low,
    .medium {
      color: $yellow;
    }

    .high,
    .severe {
      color: $orange;
    }

    .critical {
      color: $red;
    }
  }
  .details {
    grid-column: span 3;
  }

  .message {
    grid-column: span 3;
    ul {
      padding: 0;
      margin: 0;
    }
    .message-item {
      display: flex;
      margin-bottom: 20px;

      > div {
        margin-top: 3px;
      }

      p {
        margin: 0 0 0px 16px;
        word-break: break-word;
        strong {
          display: block;
          margin: 0 0 3px 0;
        }
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  @include media($large-highest, down) {
    .message,
    .details,
    .severity {
      grid-column: span 8;
      max-width: 430px;
      margin: 0 auto;
    }

    .message-item {
      margin: 20px auto;
      &:last-child {
        margin: 20px auto !important;
      }
    }

    .details {
      margin: 20px auto 0 auto;
    }
  }
}
</style>
