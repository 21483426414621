<template>
  <div class="widget total-violations" :class="size">
    <h2 class="title">Performance By Categories Over Time</h2>
    <div class="graph">
      <Line :data="chartData" :options="chartOptions" :style="chartStyles" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { Line } from "vue-chartjs";
import "chartjs-adapter-date-fns";
import AdtechService from "@/services/AdtechService";
import ruleOverviewFixture from "@/fixtures/ruleOverview.json";

export default {
  name: "PerformanceByCategoryGraph",
  components: {
    Line,
  },
  watch: {
    "$store.state.scans": function () {
      this.$nextTick(async () => {
        if (this.$store.state.scans && this.chartData.datasets.length === 0) {
          this.chartData = await this.transformChartData();
        }
      });
    },
  },
  props: ["size"],
  data() {
    const colors = {
      navy: "#143755",
      blue: "#1462b8",
      lightBlue: "#68B0FF",
      teal: "#93D5C5",
      darkTeal: "#2D977D",
      white: "#ffffff",
      mediumGray: "#cccccc",
    };

    return {
      colors: ["#143755", "#1462b8", "#68B0FF", "#93D5C5", "#2D977D"],
      chartData: {
        labels: null,
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: "time",
            time: {
              unit: "day",
            },
          },
          y: {
            title: {
              display: true,
              text: "Violations",
              padding: 10,
              font: {
                family: "'Roboto', sans-serif",
                size: 16,
                weight: "bolder",
              },
            },
          },
        },
        plugins: {
          legend: {
            position: "top",
            labels: {
              color: colors.navy,
              boxWidth: 10,
              boxHeight: 10,
              pointStyleWidth: 10,
              padding: 20,
              font: {
                family: "'Roboto', sans-serif",
                size: 14,
              },
            },
          },
          tooltip: {
            backgroundColor: colors.white,
            titleColor: colors.navy,
            bodyColor: colors.navy,
            footerColor: colors.navy,
            usePointStyle: true,
            boxPadding: 5,
            boxWidth: 10,
            boxHeight: 10,
            borderColor: colors.mediumGray,
            borderWidth: 1,
            font: {
              family: "'Roboto', sans-serif",
              size: 13,
            },
          },
        },
      },
      chartStyles: {},
      ruleCategories: [],
    };
  },
  methods: {
    getUniqueScanDates() {
      const dateSet = new Set();
      this.$store.state.scans.forEach((scan) => {
        if (scan.start_time) {
          // Extract the date part from the start_time
          const dateOnly = scan.start_time.split("T")[0];
          dateSet.add(dateOnly);
        }
      });
      const uniqueDates = Array.from(dateSet);
      return uniqueDates;
    },
    async prepareChartData() {
      // TODO: error handling at component level
      let chartData = {};
      let chartDataFullDates = {};

      if ("error" in this.$store.state.scans) {
        return false;
      }

      // Create an array to hold all promises
      if (this.$store.state.scans && process.env.NODE_ENV !== "demo") {
        let promises = this.$store.state.scans.map((scan) => {
          return AdtechService.call("reports.scanRuleCategories", {
            scanid: scan.scanid,
          }).then((response) => {
            response.result.ruleCategories.forEach((category) => {
              if (!chartData[category.rulecategory]) {
                chartData[category.rulecategory] = {};
                chartDataFullDates[category.rulecategory] = [];
              }

              let date = scan.start_time.split("T")[0]; // Extract the date from start_time
              // see if the date already exists in the chartDataFullDates objects
              const foundIndex = chartDataFullDates[
                category.rulecategory
              ].findIndex((x) => x.date === date);

              if (chartData[category.rulecategory][date] && foundIndex > -1) {
                // if the dates are the same
                // only replace the data with the new date's value
                // if the start time is newer than the previous
                if (
                  moment(
                    chartDataFullDates[category.rulecategory][foundIndex]
                      .fullDate
                  ).isBefore(moment(scan.start_time))
                ) {
                  // replace the value in chartData
                  chartData[category.rulecategory][date] =
                    category.violation_count;
                  // remove and replace the value in chartDataFullDate
                  chartDataFullDates[category.rulecategory][foundIndex] = {
                    violationCount: category.violation_count,
                    date: date,
                    fullDate: scan.start_time,
                  };
                }
              } else {
                chartData[category.rulecategory][date] =
                  category.violation_count;
                chartDataFullDates[category.rulecategory].push({
                  violationCount: category.violation_count,
                  date: date,
                  fullDate: scan.start_time,
                });
              }
            });
          });
        });
        // Wait for all promises to resolve
        await Promise.all(promises);
      }

      // generating demo data
      if (process.env.NODE_ENV === "demo") {
        ruleOverviewFixture.forEach((category, index) => {
          if (!chartData[category.name]) {
            chartData[category.name] = {};
          }

          chartData[category.name]["2024-02-04"] = Math.floor(
            Math.random() * 3
          );
          chartData[category.name]["2024-02-08"] = Math.floor(
            Math.random() * 7 + 3
          );
          chartData[category.name]["2024-02-12"] = Math.floor(
            index === 2 ? Math.random() * 3 : Math.random() * 11 + 7
          );
          chartData[category.name]["2024-02-16"] = Math.floor(
            Math.random() * 16 + 11
          );
          chartData[category.name]["2024-02-20"] = Math.floor(
            Math.random() * 20 + 16
          );
        });
      }

      return chartData;
    },
    async transformChartData() {
      let rawData = await this.prepareChartData();
      let datasets = [];
      let uniqueDates = new Set();

      let index = 0;
      for (let category in rawData) {
        let data = [];

        for (let date in rawData[category]) {
          uniqueDates.add(date);
          data.push({ x: date, y: rawData[category][date] });
        }

        datasets.push({
          label: category,
          data: data,
          backgroundColor: this.colors[index],
          borderColor: this.colors[index],
          pointHoverBackgroundColor: this.colors[index],
        });

        index++;
      }

      let labels = Array.from(uniqueDates).sort();
      datasets.map((item) => {
        item.data = Array.from(item.data).sort(
          (a, b) => new Date(a.x) - new Date(b.x)
        );
      });

      return { labels, datasets };
    },
  },
  async mounted() {
    if (this.$store.state.scans) {
      this.chartData = await this.transformChartData();
    }
  },
};
</script>

<style lang="scss" scoped>
.graph {
  width: 100%;
  height: 300px;
}
</style>
