<template>
  <div class="tag-consent-overview widget full">
    <h2 class="title">Consent Violations by Consent Type</h2>
    <div v-if="error || loading">
      <div class="site-loading" v-if="!error && loading">
        <LoadingSpinner /> Loading...
      </div>
    </div>
    <div
      class="tag-consent-overview-wrapper"
      v-if="
        !error &&
        !loading &&
        isDataReady
      "
    >
      <!-- Global Privacy Control Section -->
      <div class="tag-consent-pie-section">
        <h3><span>Consent Type</span> Global Privacy Control</h3>
        <div>
          <div>
            <DoughnutChartScore
              :score="getScoreForType('tags', 'Global Privacy Control')"
              :displayNumber="getViolatingCount('tags', 'Global Privacy Control')"
              :highNumBad="true"
              size="medium"
            />
            <p>
              <strong>
                {{ getViolatingCount('tags', 'Global Privacy Control') }}
                Violating Tag{{ getViolatingCount('tags', 'Global Privacy Control') === 1 ? '' : 's' }}
              </strong>
            </p>
            <p>
              {{ getTotalCount('tags', 'Global Privacy Control') }}
              Total Tag{{ getTotalCount('tags', 'Global Privacy Control') === 1 ? '' : 's' }}
            </p>
          </div>
          <div>
            <DoughnutChartScore
              :score="getScoreForType('sites', 'Global Privacy Control')"
              :highNumBad="true"
              :displayNumber="getViolatingCount('sites', 'Global Privacy Control')"
              size="medium"
            />
            <p>
              <strong>
                {{ getViolatingCount('sites', 'Global Privacy Control') }}
                Violating Site{{ getViolatingCount('sites', 'Global Privacy Control') === 1 ? '' : 's' }}
              </strong>
            </p>
            <p>
              {{ getTotalCount('sites', 'Global Privacy Control') }}
              Total Site{{ getTotalCount('sites', 'Global Privacy Control') === 1 ? '' : 's' }}
            </p>
          </div>
        </div>
      </div>

      <!-- All Enabled Section -->
      <div class="tag-consent-pie-section">
        <h3><span>Consent Type</span> All Enabled</h3>
        <div>
          <div>
            <DoughnutChartScore
              :score="getScoreForType('tags', 'All Enabled')"
              :displayNumber="getViolatingCount('tags', 'All Enabled')"
              :highNumBad="true"
              size="medium"
            />
            <p>
              <strong>
                {{ getViolatingCount('tags', 'All Enabled') }}
                Violating Tag{{ getViolatingCount('tags', 'All Enabled') === 1 ? '' : 's' }}
              </strong>
            </p>
            <p>
              {{ getTotalCount('tags', 'All Enabled') }}
              Total Tag{{ getTotalCount('tags', 'All Enabled') === 1 ? '' : 's' }}
            </p>
          </div>
          <div>
            <DoughnutChartScore
              :score="getScoreForType('sites', 'All Enabled')"
              :highNumBad="true"
              :displayNumber="getViolatingCount('sites', 'All Enabled')"
              size="medium"
            />
            <p>
              <strong>
                {{ getViolatingCount('sites', 'All Enabled') }}
                Violating Site{{ getViolatingCount('sites', 'All Enabled') === 1 ? '' : 's' }}
              </strong>
            </p>
            <p>
              {{ getTotalCount('sites', 'All Enabled') }}
              Total Site{{ getTotalCount('sites', 'All Enabled') === 1 ? '' : 's' }}
            </p>
          </div>
        </div>
      </div>

      <!-- None Enabled Section -->
      <div class="tag-consent-pie-section">
        <h3><span>Consent Type</span> None Enabled</h3>
        <div>
          <div>
            <DoughnutChartScore
              :score="getScoreForType('tags', 'None Enabled')"
              :displayNumber="getViolatingCount('tags', 'None Enabled')"
              :highNumBad="true"
              size="medium"
            />
            <p>
              <strong>
                {{ getViolatingCount('tags', 'None Enabled') }}
                Violating Tag{{ getViolatingCount('tags', 'None Enabled') === 1 ? '' : 's' }}
              </strong>
            </p>
            <p>
              {{ getTotalCount('tags', 'None Enabled') }}
              Total Tag{{ getTotalCount('tags', 'None Enabled') === 1 ? '' : 's' }}
            </p>
          </div>
          <div>
            <DoughnutChartScore
              :score="getScoreForType('sites', 'None Enabled')"
              :highNumBad="true"
              :displayNumber="getViolatingCount('sites', 'None Enabled')"
              size="medium"
            />
            <p>
              <strong>
                {{ getViolatingCount('sites', 'None Enabled') }}
                Violating Site{{ getViolatingCount('sites', 'None Enabled') === 1 ? '' : 's' }}
              </strong>
            </p>
            <p>
              {{ getTotalCount('sites', 'None Enabled') }}
              Total Site{{ getTotalCount('sites', 'None Enabled') === 1 ? '' : 's' }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DoughnutChartScore from "@/components/Shared/DoughnutChartScore.vue";
import MetadataService from "@/services/MetadataService";
import { getConsentErrorMessage } from "@/ErrorMessaging";
import TagConsentFixture from "@/fixtures/consentOverview.json";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { normalizeTagConsentResults } from "@/Helpers.js";

export default {
  name: "TagConsentOverview",
  components: { DoughnutChartScore, LoadingSpinner },
  watch: {
    "$store.state.containers": function () {
      if (
        this.$store.state.containers &&
        this.$store.state.containers.length !== 0
      ) {
        this.getData();
      }
    },
  },
  computed: {
    isDataReady() {
      return (
        this.aggregatedData?.sites != null &&
        this.aggregatedData?.tags != null &&
        Object.keys(this.aggregatedData.sites).length > 0 &&
        Object.keys(this.aggregatedData.tags).length > 0
      );
    },
  },
  data() {
    return {
      error: null,
      loading: true,
      aggregatedData: {
        sites: null,
        tags: null,
      },
    };
  },
  methods: {
    getScoreForType(category, type) {
      if (!this.aggregatedData?.[category]?.[type]) return [0, 0];
      return [
        this.aggregatedData[category][type].violating || 0,
        this.aggregatedData[category][type].nonviolating || 0
      ];
    },
    getViolatingCount(category, type) {
      return this.aggregatedData?.[category]?.[type]?.violating || 0;
    },
    getTotalCount(category, type) {
      if (!this.aggregatedData?.[category]?.[type]) return 0;
      return (
        (this.aggregatedData[category][type].violating || 0) +
        (this.aggregatedData[category][type].nonviolating || 0)
      );
    },
    queryTags() {
      if (
        !this.$store.state.consentMonitoringContainer ||
        !this.$store.state.consentMonitoringContainer.containerid
      ) {
        this.triggerError({
          message:
            "TagConsentOverviewWidget - queryTags - this.$store.state.consentMonitoringContainer.containerid UNDEFINED",
        });
        return Promise.resolve();
      }
      return MetadataService.call("runConsentMonitoringQuery", {
        containerid: this.$store.state.consentMonitoringContainer.containerid,
        query: `
            SELECT
              *
            FROM __violations_by_tag_aggregated__
          `,
      });
    },
    querySites() {
      if (
        !this.$store.state.consentMonitoringContainer ||
        !this.$store.state.consentMonitoringContainer.containerid
      ) {
        this.triggerError({
          message:
            "TagConsentOverviewWidget - querySites - this.$store.state.consentMonitoringContainer.containerid UNDEFINED",
        });
        return Promise.resolve();
      }
      return MetadataService.call("runConsentMonitoringQuery", {
        containerid: this.$store.state.consentMonitoringContainer.containerid,
        query: `
            SELECT
              *
            FROM __violations_by_site_aggregated__
          `,
      });
    },
    getData() {
      this.loading = true;
      this.error = null;
      this.aggregatedData = {
        sites: null,
        tags: null,
      };

      Promise.all([this.queryTags(), this.querySites()])
        .then((responses) => {
          const [tagsResponse, sitesResponse] = responses;

          if (!tagsResponse || !sitesResponse) {
            this.triggerError({
              message: "runConsentMonitoringQuery - TagConsentOverviewWidget",
              info: "Tags Response or Sites Response is undefined",
            });
            return Promise.resolve();
          }

          if (tagsResponse.error || !tagsResponse.result) {
            this.triggerError({
              message:
                "runConsentMonitoringQuery - TagConsentOverviewWidget - error obj returned from api",
              error: tagsResponse.error,
              info: !tagsResponse.result
                ? "Tags Response No Result Obj Returned"
                : "Tags Response Error",
            });
            return Promise.resolve();
          }

          if (sitesResponse.error || !sitesResponse.result) {
            this.triggerError({
              message:
                "runConsentMonitoringQuery - TagConsentOverviewWidget - error obj returned from api",
              error: sitesResponse.error,
              info: !sitesResponse.result
                ? "Sites Response No Result Obj Returned"
                : "Sites Response Error",
            });
            return Promise.resolve();
          }

          this.aggregatedData = {
            tags: normalizeTagConsentResults(tagsResponse.result.queryResult.data),
            sites: normalizeTagConsentResults(sitesResponse.result.queryResult.data)
          };

          this.loading = false;
        })
        .catch((error) => {
          this.triggerError({
            message:
              "runConsentMonitoringQuery - TagConsentOverviewWidget - catch",
            error: error,
          });
        });
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.error = getConsentErrorMessage(this.$store.state.organization);
      this.loading = false;
    },
  },
  mounted() {
    if (process.env.NODE_ENV === "demo") {
      this.aggregatedData = TagConsentFixture;
      this.loading = false;
    } else if (
      this.$store.state.containers &&
      this.$store.state.containers.length !== 0
    ) {
      this.getData();
    }
  },
};
</script>

<style lang="scss" scoped>
/* Style section remains unchanged */
@import "@/styles/_helpers.scss";
.tag-consent-overview {
  h2 {
    margin: 10px 0 30px 0;
    width: 100%;
    text-align: left;
  }

  h3 {
    span {
      display: block;
      margin-bottom: 3px;
      font-weight: 500;
      font-size: 13px;
      color: $dark-gray;
      text-transform: uppercase;
    }
    font-size: 20px;
    margin: 0 0 20px 0;

    @include media($x-small-highest, down) {
      text-align: center;
    }
  }

  .tag-consent-overview-wrapper {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(6, 1fr);
    flex-wrap: wrap;
    width: 100%;
    gap: 1.5rem;

    > div {
      grid-column: span 2;
      @include media($large, down) {
        grid-column: span 3;
      }
      @include media(1080px, down) {
        grid-column: span 6;
      }
      @include media($small-highest, down) {
        grid-column: span 3;
      }
      @include media(770px, down) {
        grid-column: span 6;
      }
    }
  }

  .tag-consent-pie-section {
    border: 2px solid $light-gray;
    border-radius: 3px;
    padding: 16px;
    > div {
      display: grid;
      grid-auto-flow: dense;
      grid-template-columns: repeat(2, 1fr);
      flex-wrap: wrap;
      width: 100%;
      gap: 16px;
      text-align: center;

      > div {
        margin: 0 auto;
        grid-column: span 1;

        @include media($x-small-highest, down) {
          grid-column: span 2;
        }

        > div {
          &:first-child {
            margin-bottom: 20px;
          }
        }
      }
    }

    p {
      margin: 0;
      font-size: 14px;
    }
  }
}
</style>