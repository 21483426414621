<template>
  <div class="view-container tag-consent-overview">
    <section class="view-header">
      <div class="view-title">Tag &amp; Consent Monitoring</div>
      <div class="title-sub">
        <div class="view-sublinks">
          <LinkWithIcon
            icon="bar-plus-line-graph-data-up"
            text="Overview"
            link="/tag-consent/overview"
            v-bind:active="true"
          />
          <LinkWithIcon
            icon="list-ui"
            text="Details"
            :link="`/tag-consent/details?org=${encodeURIComponent(
              this.$store.state.organization
            )}`"
          />
        </div>
      </div>
    </section>
    <div class="workarea">
      <ViolationsByConsentTypeChart />
      <MostImprovedWidget />
      <TagConsentOverviewWidget />
    </div>
  </div>
</template>

<script>
import router from "@/router";
import TagConsentOverviewWidget from "@/components/TagConsent/TagConsentOverviewWidget.vue";
import LinkWithIcon from "@/components/Shared/LinkWithIcon.vue";
import ViolationsByConsentTypeChart from "@/components/TagConsent/ViolationsByConsentTypeChart.vue";
import MostImprovedWidget from "@/components/TagConsent/MostImprovedWidget.vue";

export default {
  name: "TagConsentDetailsView",
  data() {
    return {
      NODE_ENV: process.env.NODE_ENV,
      authCredentials: [],
      timePeriod: "time-period",
      region: "region",
      timePeriodOptions: [
        {
          label: "Time Period",
          value: "time-period",
        },
      ],
      regionOptions: [
        {
          label: "Region",
          value: "region",
        },
      ],
    };
  },
  components: {
    LinkWithIcon,
    TagConsentOverviewWidget,
    ViolationsByConsentTypeChart,
    MostImprovedWidget,
  },
  watch: {
    "$store.state.featurePermissions": function () {
      this.$nextTick(async () => {
        if (this.$store.state.featurePermissions) {
          // redirect to home if they do not have this feature enabled
          if (!this.$store.state.featurePermissions.tagConsent) {
            router.push("/");
          }
        }
      });
    },
  },
  mounted() {
    if (this.$store.state.featurePermissions) {
      // redirect to home if they do not have this feature enabled
      if (!this.$store.state.featurePermissions.tagConsent) {
        router.push("/");
      }
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/_variable.scss";
@import "@/styles/_helpers.scss";

.tag-consent-overview {
  .n-base-selection-input__content {
    font-weight: 700;
    color: $navy;
    text-transform: uppercase;
  }
}
</style>
