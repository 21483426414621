<template>
  <div class="widget half">
    <h2 class="title">
      Violating Sites By Consent Type Over Time
      <NTooltip placement="bottom" trigger="hover">
        <template #trigger>
          <DisplaySvg name="info" class="info-icon" />
        </template>
        <div class="info-text">
          Dates indicate the most recent scan results pulled by AdtechDNA.
        </div>
      </NTooltip>
    </h2>
    <div v-if="error || loading">
      <div class="site-error" v-if="error || !loading">{{ error }}</div>
      <div class="site-loading" v-if="!error && loading">
        <LoadingSpinner /> Loading...
      </div>
    </div>
    <div class="graph" v-if="!error && !loading">
      <Bar :data="chartData" :options="chartOptions" :style="chartStyles" />
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs";
import "chartjs-adapter-date-fns";
import MetadataService from "@/services/MetadataService";
import { getConsentErrorMessage } from "@/ErrorMessaging";
import DisplaySvg from "@/components/Shared/DisplaySvg";
import { NTooltip } from "naive-ui";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import consentChartFixture from "@/fixtures/consentChart.json";

export default {
  name: "ViolationsByConsentTypeChart",
  components: { Bar, NTooltip, DisplaySvg, LoadingSpinner },
  watch: {
    "$store.state.containers": function () {
      if (
        this.$store.state.containers &&
        this.$store.state.containers.length !== 0
      ) {
        this.getData();
      }
    },
  },
  computed: {
    groupedData() {
      return this.dataArray.reduce((acc, item) => {
        if (!acc[item.date]) {
          acc[item.date] = {};
        }
        if (!acc[item.date][item.group]) {
          acc[item.date][item.group] = [];
        }
        acc[item.date][item.group].push(item);
        return acc;
      }, {});
    },
  },
  data() {
    const colors = {
      navy: "#143755",
      blue: "#1462b8",
      lightBlue: "#68B0FF",
      teal: "#93D5C5",
      darkTeal: "#2D977D",
      white: "#ffffff",
      mediumGray: "#cccccc",
    };

    return {
      loading: true,
      colors: ["#68B0FF", "#93D5C5", "#2D977D"],
      chartData: {
        labels: null,
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
          },
          x: {
            type: "timeseries",
            time: {
              unit: "day",
              tooltipFormat: "MMM dd, yyyy",
              displayFormats: {
                day: "MMM dd",
              },
            },
          },
        },
        plugins: {
          legend: {
            position: "top",
            labels: {
              color: colors.navy,
              boxWidth: 10,
              boxHeight: 10,
              pointStyleWidth: 10,
              padding: 20,
              font: {
                family: "'Roboto', sans-serif",
                size: 14,
              },
            },
          },
          tooltip: {
            backgroundColor: colors.white,
            titleColor: colors.navy,
            bodyColor: colors.navy,
            footerColor: colors.navy,
            usePointStyle: true,
            boxPadding: 5,
            boxWidth: 10,
            boxHeight: 10,
            borderColor: colors.mediumGray,
            borderWidth: 1,
            font: {
              family: "'Roboto', sans-serif",
              size: 13,
            },
          },
        },
      },
      chartStyles: {},
      ruleCategories: [],
      dataArray: [],
      usableGroups: ['All Enabled', 'None Enabled', 'Global Privacy Control']
    };
  },
  methods: {
    getData() {
      this.loading = true; // Ensure loading is true at the beginning

      if (this.$store.state.containers == null) {
        this.error = getConsentErrorMessage(this.$store.state.organization);
        return false;
      }

      Promise.all([this.querySites()])
        .then((responses) => {
          const sitesResponse = responses[0]; // sites

          if (!sitesResponse) {
            return Promise.resolve();
          }

          if (sitesResponse.error) {
            this.triggerError({
              message:
                "runConsentMonitoringQuery - TagConsentOverviewWidget - error obj returned from api",
              error: sitesResponse.error,
              info: "Sites Response",
            });
            return Promise.resolve();
          }

          sitesResponse.result.queryResult.data.forEach((dataItem) => {
            const transformedData = {
              id: dataItem.f[0].v,
              date: dataItem.f[1].v,
              group: dataItem.f[2].v,
              violating: dataItem.f[3].v,
              nonviolating: dataItem.f[4].v,
            };
            this.dataArray.push(transformedData);
          });

          // Extract unique groups
          const groups = [...new Set(this.dataArray.map((item) => item.group))];

          this.chartData.labels = this.dataArray.map((item) => item.date);

          // Prepare datasets
          const datasets = groups
            .filter(group => this.usableGroups.includes(group))  
            .map(group => {
            const groupData = this.dataArray
              .filter((item) => item.group === group)
              .map((item) => ({
                x: new Date(item.date), // Ensure this is a Date object
                y: item.violating,
              }));

            return {
              label: group,
              data: groupData,
              backgroundColor: this.getColor(group),
              borderColor: this.getColor(group),
              pointHoverBackgroundColor: this.getColor(group),
              minBarLength: 5
            };
          });

          // No need to set `labels` as Chart.js will use the `x` values from the data points
          this.chartData = {
            datasets: datasets,
          };

          this.loading = false;
        })
        .catch((error) => {
          this.triggerError({
            message:
              "runConsentMonitoringQuery - TagConsentOverviewWidget - catch",
            error: error,
          });
        });
    },
    querySites() {
      if (
        !this.$store.state.consentMonitoringContainer ||
        !this.$store.state.consentMonitoringContainer.containerid
      ) {
        this.triggerError({
          message:
            "TagConsentOverviewWidget - querySites - this.$store.state.consentMonitoringContainer.containerid UNDEFINED",
        });
        return Promise.resolve();
      }
      return MetadataService.call("runConsentMonitoringQuery", {
        containerid: this.$store.state.consentMonitoringContainer.containerid,
        query: `
            SELECT
              *
            FROM __violations_by_site_aggregated__
          `,
      });
    },
    getColor(group) {
      const colors = {
        "All Enabled": "#2D977D",
        "None Enabled": "#93D5C5",
        Global: "#68B0FF",
        // Add more group colors here
      };
      return colors[group] || "#000000";
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.error = getConsentErrorMessage(this.$store.state.organization);
      this.loading = false;
    },
  },
  mounted() {
    if (process.env.NODE_ENV === "demo") {
      this.chartData = consentChartFixture;
      this.loading = false;
    } else if (
      this.$store.state.containers &&
      this.$store.state.containers.length !== 0
    ) {
      this.getData();
    }
  },
};
</script>

<style lang="scss" scoped>
.graph {
  width: 100%;
  height: 300px;
}

.info-icon {
  height: 20px;
  width: 20px;
  display: inline-block;
  position: relative;
  top: 1px;
  margin-left: 5px;
}
</style>
