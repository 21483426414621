<template>
  <div class="view-container media-performance">
    <div class="view-header">
      <div class="view-title-with-btn">
        <h1 class="view-title">Media Performance</h1>
        <MediaPerformanceFilters
          :dateRanges="dateRanges"
          :activeDateRange="activeDateRange"
          :updateDateRange="updateDateRange"
          :advertisers="advertisers"
          :activeAdvertiser="activeAdvertiser"
          :updateAdvertisers="updateAdvertisers"
          :insertionOrders="insertionOrders"
          :activeInsertionOrder="activeInsertionOrder"
          :updateInsertionOrder="updateInsertionOrder"
          :returnFiltersToDefault="returnFiltersToDefault"
          :loading="loading"
        />
      </div>
    </div>

    <div class="workarea" v-if="error || loading">
      <div class="widget full">
        <div class="site-error" v-if="error || !loading">{{ error }}</div>
        <div class="site-loading" v-if="!error && loading">
          <LoadingSpinner /> Loading...
        </div>
      </div>
    </div>

    <div class="workarea" v-if="!error && !loading">
      <SpendAndConversionsComparedToCPA :chartData="cpa_chart" />
      <TotalCostComparedToCompletedViewsOvertime :chartData="cpcv_chart" />
      <InventorySourceType
        :private="source_chart.private"
        :public="source_chart.public"
        :private_past="source_chart.private_past"
        :public_past="source_chart.public_past"
        :totalSpend="source_chart.totalSpend"
        :totalPastSpend="source_chart.totalPastSpend"
        :previousDateRange="previousDateRange"
      />
      <TotalSpendByChannel :chartData="channel_chart" />
      <MetricsWidget
        :metrics="metrics"
        :allDataDateStart="allDataDateStart"
        :allDataDateEnd="allDataDateEnd"
        :previousDateRange="previousDateRange"
      />
    </div>
  </div>
</template>

<script>
import router from "@/router";
import moment from "moment";
import AdtechService from "@/services/AdtechService";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import InventorySourceType from "@/components/MediaPerformance/InventorySourceType.vue";
import TotalSpendByChannel from "@/components/MediaPerformance/TotalSpendByChannel.vue";
import SpendAndConversionsComparedToCPA from "@/components/MediaPerformance/SpendAndConversionsComparedToCPA.vue";
import MediaPerformanceFilters from "@/components/MediaPerformance/MediaPerformanceFilters.vue";
import MetricsWidget from "@/components/MediaPerformance/MetricsWidget.vue";
import TotalCostComparedToCompletedViewsOvertime from "@/components/MediaPerformance/TotalCostComparedToCompletedViewsOvertime.vue";
import { getMediaPerformanceErrorMessage } from "@/ErrorMessaging";

// fixtures
import mediaPerformanceMetrics from "@/fixtures/mediaPerformanceMetrics.json";
import cpa_graphFixture from "@/fixtures/spendAndConversionsComparedToCPAGraphFixture.json";
import cpcv_graphFixture from "@/fixtures/totalCostComparedToCompletedViewsOvertimeGraphFixture.json";
import channel_graphFixture from "@/fixtures/totalSpendByChannelGraphFixture.json";

export default {
  name: "MediaPerformance",
  components: {
    SpendAndConversionsComparedToCPA,
    InventorySourceType,
    TotalSpendByChannel,
    MetricsWidget,
    MediaPerformanceFilters,
    TotalCostComparedToCompletedViewsOvertime,
    LoadingSpinner,
  },
  methods: {
    async getData() {
      if (this.getDataActive) return; // don't start new calls if there is already an active call

      // reset loading and error states
      this.getDataActive = true;
      if (!this.loading) {
        this.loading = true;
      }
      if (this.error) {
        this.error = null;
      }

      await this.getFilterDateOptions();
      if (this.error) return; // stop calls if there was an error
      await this.getFilterOptionsByActiveDate();
      if (this.error) return; // stop calls if there was an error
      await this.getPerformanceMetrics();

      // loaded!
      this.getDataActive = false;
      this.loading = false;
    },
    async returnFiltersToDefault() {
      await this.updateDateRange(
        this.dateRanges[this.dateRanges.length - 1].rangeValue
      );
      await this.updateAdvertisers("All");
      await this.updateInsertionOrder("All");
    },
    async updateDateRange(value) {
      // reset loading and error states
      this.getDataActive = true;
      if (!this.loading) {
        this.loading = true;
      }
      if (this.error) {
        this.error = null;
      }

      let selectedDateObj = this.dateRanges.find((o) => o.rangeValue === value);
      this.activeDateRange = selectedDateObj;

      await this.getFilterOptionsByActiveDate();
      await this.getPerformanceMetrics();

      // loaded!
      this.getDataActive = false;
      this.loading = false;
    },
    async updateAdvertisers(value) {
      // reset loading and error states
      this.getDataActive = true;
      if (!this.loading) {
        this.loading = true;
      }
      if (this.error) {
        this.error = null;
      }

      if (value === "All") {
        this.activeAdvertiser = null;
      } else {
        let selectedAdvertiserObj = this.advertisers.find(
          (o) => o.name === value
        );
        this.activeAdvertiser = selectedAdvertiserObj;
      }

      await this.getFilterOptionsByActiveDate("advertisers");
      await this.getPerformanceMetrics();

      // loaded!
      this.getDataActive = false;
      this.loading = false;
    },
    async updateInsertionOrder(value) {
      // reset loading and error states
      this.getDataActive = true;
      if (!this.loading) {
        this.loading = true;
      }
      if (this.error) {
        this.error = null;
      }

      if (value === "All") {
        this.activeInsertionOrder = null;
      } else {
        let selectedOrderObj = this.insertionOrders.find(
          (o) => o.name === value
        );
        this.activeInsertionOrder = selectedOrderObj;
      }

      await this.getFilterOptionsByActiveDate("insertionOrder");
      await this.getPerformanceMetrics();

      // loaded!
      this.getDataActive = false;
      this.loading = false;
    },
    getFilterDateOptions() {
      // fake filter options for demo env
      if (process.env.NODE_ENV === "demo") {
        const dateRanges = [
          {
            rangeType: "month",
            rangeValue: "2024-05",
            dateRangeBegin: "2024-05-01",
            dateRangeEnd: "2024-05-31",
            fractionAvailable: 1,
          },
          {
            rangeType: "month",
            rangeValue: "2024-06",
            dateRangeBegin: "2024-06-01",
            dateRangeEnd: "2024-06-30",
            fractionAvailable: 0.06666666666666667,
          },
        ];
        this.dateRanges = dateRanges;
        this.advertisers = [
          {
            key: "1234",
            name: "Advertiser #1",
          },
          {
            key: "1234",
            name: "Advertiser #2",
          },
        ];
        this.insertionOrders = [
          {
            key: "1234",
            name: "Insertion Order #1",
          },
          {
            key: "1234",
            name: "Insertion Order #2",
          },
        ];

        // set the active date range as the most recent
        this.activeDateRange = dateRanges[dateRanges.length - 1];
        this.allDataDateStart = dateRanges[0];
        this.allDataDateEnd = dateRanges[dateRanges.length - 1];

        return Promise.resolve();
      }

      // get available date ranges
      return AdtechService.call("pm1.getAvailableDateRanges", {
        orgid: this.$store.state.organization,
      })
        .then((dateRange_response) => {
          if (dateRange_response.error) {
            this.triggerError({
              message:
                "pm1.getAvailableDateRanges - error obj returned from api",
              error: dateRange_response.error,
            });
            return Promise.resolve();
          }

          const dateRanges = dateRange_response.result.dateRanges;
          // sort by date, most recent first
          dateRanges.sort(function (a, b) {
            return new Date(a.dateRangeBegin) - new Date(b.dateRangeBegin);
          });
          this.dateRanges = dateRanges;
          // set the active date range as the most recent
          this.activeDateRange = dateRanges[dateRanges.length - 1];

          // set the eariliest and latest date available
          // this is needed in order to fetch average deltas
          this.allDataDateStart = dateRanges[0];
          this.allDataDateEnd = dateRanges[dateRanges.length - 1];
        })
        .catch((error) => {
          this.triggerError({
            message: "pm1.getAvailableDateRanges - catch",
            error: error,
          });
        });
    },
    getFilterOptionsByActiveDate(update) {
      // get filters available for active date range and filters

      let filters = {};
      if (this.activeAdvertiser) {
        filters.advertiserid = this.activeAdvertiser.key;
      }

      if (this.activeInsertionOrder) {
        filters.insertionorderid = this.activeInsertionOrder.key;
      }

      if (!this.activeDateRange) {
        this.triggerError({
          message: "No active date range is defined",
        });
        return Promise.resolve();
      }

      return AdtechService.call("pm1.getAvailableFilters", {
        orgid: this.$store.state.organization,
        dateRangeBegin: this.activeDateRange.dateRangeBegin,
        dateRangeEnd: this.activeDateRange.dateRangeEnd,
        filters,
      })
        .then((filter_response) => {
          if (filter_response.error) {
            this.triggerError({
              message: "pm1.getAvailableFilters - error obj returned from api",
              error: filter_response.error,
            });
            return Promise.resolve();
          }

          const filterKeys = filter_response.result.filterKeys;

          // advertisers filter data
          if (
            filterKeys.advertiserid &&
            filterKeys.advertiserid.length > 0 &&
            update !== "advertisers"
          ) {
            this.advertisers = filterKeys.advertiserid;
          }

          // insertion order filter data
          if (
            filterKeys.insertionorderid &&
            filterKeys.insertionorderid.length > 0 &&
            update !== "insertionOrder"
          ) {
            this.insertionOrders = filterKeys.insertionorderid;
          }
        })
        .catch((error) => {
          this.triggerError({
            message: "pm1.getAvailableFilters - catch",
            error: error,
          });
        });
    },
    formatChartDateLabel(divisibleNum, date1, date2) {
      return divisibleNum > 1 && date1 !== date2
        ? `${moment(date1).format("MM/DD")} - ${moment(date2).format("MM/DD")}`
        : `${moment(date1).format("MM/DD")}`;
    },
    formatChartData(byDateData) {
      let cpa_chart = {
        labels: [],
        datasets: [
          {
            yAxisID: "y1",
            order: 1,
            label: "Total Spend",
            data: [],
            backgroundColor: "#1462b8",
            borderColor: "#1462b8",
            pointHoverBackgroundColor: "#1462b8",
          },
          {
            yAxisID: "y1",
            order: 2,
            label: "Total Conversions",
            data: [],
            backgroundColor: "#93d5c5",
            borderColor: "#93d5c5",
            pointHoverBackgroundColor: "#93d5c5",
          },
          {
            yAxisID: "y2",
            order: 0,
            type: "line",
            label: "CPA",
            data: [],
            backgroundColor: "#68B0FF",
            borderColor: "#68B0FF",
            pointHoverBackgroundColor: "#68B0FF",
          },
        ],
      };
      let cpcv_chart = {
        labels: [],
        datasets: [
          {
            yAxisID: "y1",
            order: 1,
            label: "Total Spend",
            data: [],
            backgroundColor: "#1462b8",
            borderColor: "#1462b8",
            pointHoverBackgroundColor: "#1462b8",
          },
          {
            yAxisID: "y2",
            order: 0,
            type: "line",
            label: "Cost Per Completed View",
            data: [],
            backgroundColor: "#68B0FF",
            borderColor: "#68B0FF",
            pointHoverBackgroundColor: "#68B0FF",
          },
        ],
      };

      // sort by date
      byDateData.sort((a, b) => new Date(a.date) - new Date(b.date));

      let divisibleNum =
        byDateData.length / 7 >= 4 ? 7 : byDateData.length / 3 >= 3 ? 3 : 1;
      let chart_index = 0;
      let current_dateRange = this.formatChartDateLabel(
        divisibleNum,
        byDateData[0]?.date,
        byDateData[divisibleNum - 1]?.date
      );
      let totalSpend = 0;
      let totalConversions = 0;
      let cpa = 0;
      let cpcv = 0;

      byDateData.map((item, index) => {
        totalSpend = totalSpend + item.revenue;
        totalConversions = totalConversions + item.totalconversions;
        cpa = cpa + item.cpa;
        cpcv = cpcv + item.cpcv;

        if ((index + 1) % divisibleNum == 0) {
          // set date label
          cpa_chart.labels[chart_index] = current_dateRange;

          // total spend
          cpa_chart.datasets[0].data[chart_index] = {
            x: current_dateRange,
            y: totalSpend,
          };
          cpcv_chart.datasets[0].data[chart_index] = {
            x: current_dateRange,
            y: totalSpend,
          };
          totalSpend = 0;

          // total conversions
          cpa_chart.datasets[1].data[chart_index] = {
            x: current_dateRange,
            y: totalConversions,
          };
          totalConversions = 0;

          // CPA
          cpa_chart.datasets[2].data[chart_index] = {
            x: current_dateRange,
            y: cpa,
          };
          cpa = 0;

          // CPCV
          cpcv_chart.datasets[1].data[chart_index] = {
            x: current_dateRange,
            y: cpcv,
          };
          cpcv = 0;

          // update the chart index and current_dateRange
          chart_index = chart_index + 1;

          current_dateRange = this.formatChartDateLabel(
            divisibleNum,
            byDateData[chart_index * divisibleNum]?.date,
            byDateData[chart_index * divisibleNum + divisibleNum - 1]?.date
          );
        }
      });

      this.cpa_chart = cpa_chart;
      this.cpcv_chart = cpcv_chart;
    },
    formatChannelChartData(byAdType) {
      const colorsArray = [
        "#1462b8",
        "#143755",
        "#68B0FF",
        "#93d5c5",
        "#2d977d",
      ];

      let channel_chart = {
        labels: [],
        datasets: [
          {
            yAxisID: "y",
            order: 1,
            backgroundColor: [],
            borderColor: [],
            pointHoverBackgroundColor: [],
            data: [],
          },
        ],
      };

      let currentColorIndex = 0;

      byAdType.map((item) => {
        const currentColor = colorsArray[currentColorIndex];
        channel_chart.datasets[0].backgroundColor.push(currentColor);
        channel_chart.datasets[0].borderColor.push(currentColor);
        channel_chart.datasets[0].pointHoverBackgroundColor.push(currentColor);
        channel_chart.datasets[0].data.push({
          x: item.adtype,
          y: item.revenue,
        });
        channel_chart.labels.push(item.adtype);

        if (currentColorIndex === colorsArray.length - 1) {
          currentColorIndex = 0;
        } else {
          currentColorIndex = currentColorIndex + 1;
        }
      });

      this.channel_chart = channel_chart;
    },
    getInventorySourceTypes(currentData, prevData, totalSpend, totalPastSpend) {
      let source_chart = {
        private: null,
        public: null,
        private_past: null,
        public_past: null,
        totalSpend: totalSpend,
        totalPastSpend: totalPastSpend,
      };

      const currentPublic = currentData.find(
        (i) => i.inventorysourcetype === "Public Exchange & Sub-Exchange"
      );

      const pastPublic = prevData
        ? prevData.find(
            (i) => i.inventorysourcetype === "Public Exchange & Sub-Exchange"
          )
        : null;

      if (totalSpend && currentPublic) {
        source_chart.public =
          (currentPublic.revenue.toFixed(2) / totalSpend.toFixed(2)) * 100;
        source_chart.private = 100 - source_chart.public;
      }

      if (totalPastSpend && pastPublic) {
        source_chart.public_past =
          (pastPublic.revenue.toFixed(2) / totalPastSpend.toFixed(2)) * 100;
        source_chart.private_past = 100 - source_chart.public_past;
      }

      return source_chart;
    },
    getPerformanceMetrics() {
      if (process.env.NODE_ENV === "demo") {
        this.metrics = mediaPerformanceMetrics;
        this.cpa_chart = cpa_graphFixture;
        this.cpcv_chart = cpcv_graphFixture;
        this.channel_chart = channel_graphFixture;
        this.source_chart = {
          private: 65,
          public: 45,
          private_past: 62,
          public_past: 48,
        };
        return Promise.resolve();
      }

      let filters = {};
      if (this.activeAdvertiser) {
        filters.advertiserid = this.activeAdvertiser.key;
      }

      if (this.activeInsertionOrder) {
        filters.insertionorderid = this.activeInsertionOrder.key;
      }

      return AdtechService.call("pm1.getMetrics", {
        orgid: this.$store.state.organization,
        dateRangeBegin: this.activeDateRange.dateRangeBegin,
        dateRangeEnd: this.activeDateRange.dateRangeEnd,
        filters: filters,
      })
        .then((response) => {
          if (response.error) {
            this.triggerError({
              message:
                "pm1.getMetrics (current month) - error obj returned from api",
              error: response.error,
            });
            return Promise.resolve();
          }

          this.formatChartData(response.result.byDate);
          this.formatChannelChartData(response.result.byAdType);

          // save the performance metrics numbers
          const metrics_response = response.result.overall;
          let metrics = {
            title: "Performance Metrics",
            impressions: metrics_response.impressions,
            impressions_average: null,
            cpm: metrics_response.cpm,
            cpm_average: null,
            total_spend: metrics_response.revenue,
            total_spend_average: null,
            cpcv: metrics_response.cpcv,
            cpcv_average: null,
            clicks: metrics_response.clicks,
            clicks_average: null,
            ctr: metrics_response.ctr,
            ctr_average: null,
            conversions: metrics_response.totalconversions,
            conversions_average: null,
            cpa: metrics_response.cpa,
            cpa_average: null,
          };

          // is there a previous month available to fetch?
          let currentDateRangeIndex = this.dateRanges.findIndex(
            (o) => o.dateRangeBegin === this.activeDateRange.dateRangeBegin
          );
          let hasPrevMonthData =
            currentDateRangeIndex >= 0 &&
            currentDateRangeIndex - 1 >= 0 &&
            this.dateRanges[currentDateRangeIndex - 1];

          if (hasPrevMonthData) {
            const preMonthObj = this.dateRanges[currentDateRangeIndex - 1];
            return AdtechService.call("pm1.getMetrics", {
              orgid: this.$store.state.organization,
              dateRangeBegin: preMonthObj.dateRangeBegin,
              dateRangeEnd: preMonthObj.dateRangeEnd,
              filters: filters,
            })
              .then((response_prev) => {
                if (response_prev.error) {
                  this.triggerError({
                    message:
                      "pm1.getMetrics (previous month) - error obj returned from api",
                    error: response_prev.error,
                  });
                  return Promise.resolve();
                }

                const metrics_response_prev = response_prev.result.overall;

                metrics.total_spend_average = metrics_response_prev.revenue;
                metrics.impressions_average = metrics_response_prev.impressions;
                metrics.cpm_average = metrics_response_prev.cpm;
                metrics.cpcv_average = metrics_response_prev.cpcv;
                metrics.clicks_average = metrics_response_prev.clicks;
                metrics.ctr_average = metrics_response_prev.ctr;
                metrics.conversions_average =
                  metrics_response_prev.totalconversions;
                metrics.cpa_average = metrics_response_prev.cpa;

                this.previousDateRange = preMonthObj;
                this.metrics = metrics;

                this.source_chart = this.getInventorySourceTypes(
                  response.result.bySourceType,
                  response_prev.result.bySourceType,
                  response.result.overall.revenue,
                  response_prev.result.overall.revenue
                );
              })
              .catch((error) => {
                this.triggerError({
                  message: "pm1.getMetrics (previous month) - catch",
                  error: error,
                });
              });
          }

          this.source_chart = this.getInventorySourceTypes(
            response.result.bySourceType,
            null,
            response.result.overall.revenue,
            null
          );

          this.previousDateRange = null;
          this.metrics = metrics;
          return Promise.resolve();
        })
        .catch((error) => {
          this.triggerError({
            message: "pm1.getMetrics (current month) - catch",
            error: error,
          });
          return Promise.resolve();
        });
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.getDataActive = false;
      this.loading = false;
      this.error = getMediaPerformanceErrorMessage(
        this.$store.state.organization
      );
    },
  },
  data() {
    return {
      error: null,
      loading: true,
      getDataActive: false,
      metrics: {},
      dateRanges: [],
      activeDateRange: null,
      advertisers: [],
      activeAdvertiser: null,
      insertionOrders: [],
      activeInsertionOrder: null,
      cpa_chart: {},
      cpcv_chart: {},
      channel_chart: {},
    };
  },
  mounted() {
    if (this.$store.state.featurePermissions) {
      // redirect to home if they do not have this feature enabled
      if (!this.$store.state.featurePermissions.mediaPerformance) {
        router.push("/");
      }
    }

    if (this.$store.state.organization) {
      this.getData();
    }
  },
  watch: {
    "$store.state.featurePermissions": function () {
      this.$nextTick(async () => {
        if (this.$store.state.featurePermissions) {
          // redirect to home if they do not have this feature enabled
          if (!this.$store.state.featurePermissions.mediaPerformance) {
            router.push("/");
          }
        }
      });
    },
    "$store.state.organization": function () {
      this.$nextTick(async () => {
        if (this.$store.state.organization) {
          this.getData();
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/_helpers.scss";
.media-performance {
  h1 {
    margin: 0;
  }
}
</style>
