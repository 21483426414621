<template>
  <div class="view-container platform-governance">
    <section class="view-header">
      <div class="view-title-with-btn">
        <div class="view-title">
          Platform Governance &amp; Standards - Executive Dashboard
        </div>
        <PlatformGovernanceFilter
          :loading="loading"
          @filter-change="handleFilterChange"
        />
      </div>
      <div class="view-sublinks" v-if="!error && !loading">
        <LinkWithIcon
          icon="bar-plus-line-graph-data-up"
          text="Overview"
          link="/platform-governance/overview"
          v-bind:active="true"
        />
        <LinkWithIcon
          icon="list-ui"
          text="Details"
          :link="`/platform-governance/details?org=${encodeURIComponent(
            this.$store.state.organization
          )}`"
        />
        <LinkWithIcon
          icon="warning"
          text="Violations"
          :link="`/platform-governance/violations?org=${encodeURIComponent(
            this.$store.state.organization
          )}`"
        />
        <!-- <LinkWithIcon
          icon="line-graph-data-up"
          text="Improve Score"
          :link="`/platform-governance/improve-score`"
        /> -->
      </div>
      <div>
        <LinkWithIcon
          icon="download"
          text="View Report Downloads"
          className="view-report-downloads-link"
          :link="`/platform-governance/download-reports?org=${encodeURIComponent(
            this.$store.state.organization
          )}`"
        />
      </div>
    </section>

    <div v-if="error" class="widget full">
      <div class="site-error">{{ error }}</div>
    </div>

    <div v-else-if="loading" class="widget full">
      <div class="site-loading">
        <LoadingSpinner /> Loading...
      </div>
    </div>

    <div v-else-if="noDataAvailable" class="widget full">
      <div class="site-message">
        Thank you for utilizing AdTechDNA. As of now, there are no Analytics containers set up to 
        retrieve data for the {{ this.$store.state.organization }} organization. 
        We encourage you to coordinate with your InfoTrust advisor to make sure your configuration 
        rules are properly established.
      </div>
    </div>

    <div class="workarea" v-else>
      <!-- <ComplianceScoreWithMisconfiguredSpendWidget :scans="filteredScans" size="half" /> -->
       <PerformanceByCategoryGraphWidget :scans="filteredScans" size="half" />

      <ViolationCountFilteredWidget :scans="filteredScans" size="half" />
      <ViolationCountBySeverityWidget 
        :scans="filteredScans" 
        :violation-data="aggregateData"
        size="half"
      />
      <ContainersWithMostViolationsWidget 
        :scans="filteredScans" 
        :violation-data="aggregateData"
        size="half" 
      />
      <GovernanceScoreboardWidget 
        :scans="filteredScans"
        :violation-data="aggregateData"
      />
    </div>
  </div>
</template>

<script>
import GovernanceScoreboardWidget from "@/components/PlatformGovernance/GovernanceScoreboardWidget";
import ViolationCountFilteredWidget from "@/components/PlatformGovernance/ViolationCountFilteredWidget.vue";
import ViolationCountBySeverityWidget from "@/components/PlatformGovernance/ViolationCountBySeverityWidget";
import ContainersWithMostViolationsWidget from "@/components/PlatformGovernance/ContainersWithMostViolationsWidget.vue";
import LinkWithIcon from "@/components/Shared/LinkWithIcon.vue";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import PlatformGovernanceFilter from "@/components/PlatformGovernance/PlatformGovernanceFilter.vue";
import { getDashboardErrorMessage } from "@/ErrorMessaging";
//import ComplianceScoreWithMisconfiguredSpendWidget from "@/components/Dashboard/ComplianceScoreWithMisconfiguredSpendWidget.vue";
import PerformanceByCategoryGraphWidget from "@/components/PlatformGovernance/PerformanceByCategoryGraphWidget.vue";

export default {
  name: "PlatformGovernanceView",
  components: {
    GovernanceScoreboardWidget,
    ViolationCountFilteredWidget,
    //ComplianceScoreWithMisconfiguredSpendWidget,
    PerformanceByCategoryGraphWidget,
    ViolationCountBySeverityWidget,
    ContainersWithMostViolationsWidget,
    LinkWithIcon,
    LoadingSpinner,
    PlatformGovernanceFilter
  },
  data() {
    return {
      authCredentials: [],
      error: null,
      loading: false,
      aggregateData: [],
      rulesData: [],
      noDataAvailable: false,
      isInitialized: false,
      currentFilters: null,
      isProcessingFilter: false
    };
  },
  computed: {
    filteredScans() {
      const scans = this.$store.state.scans;
      if (!Array.isArray(scans)) return [];
      return scans;
    },
    hasValidContainers() {
      const containers = this.$store.state.containers;
      return Array.isArray(containers) && containers.length > 0;
    }
  },
  methods: {
    async handleFilterChange(filters) {
      if (process.env.NODE_ENV === "demo" || this.isProcessingFilter) return;
      
      this.isProcessingFilter = true;
      this.loading = true;
      this.error = null;

      try {
        // Store current filters
        this.currentFilters = filters;

        // Guard clauses
        if (!this.hasValidContainers || !this.$store.state.scans?.length) {
          this.noDataAvailable = true;
          return;
        }

        // Update store filters first
        await this.$store.dispatch("updatePlatformGovernanceFilters", filters || {});

        // Ensure component is still mounted
        if (!this.isInitialized) return;

        // Then fetch filtered data with the current filters
        const response = await this.$store.dispatch("applyFilters", filters || {});

        // Validate response
        if (!response?.rules?.result || !response?.aggregate?.result) {
          console.warn('Invalid API response format:', response);
          return;
        }

        // Update data
        this.rulesData = response.rules.result['custom.scanRuleRuns'] || [];
        this.aggregateData = response.aggregate.result['custom.scanRuleRunsAggregate'] || [];

        // Check for empty data
        this.noDataAvailable = !this.rulesData.length && !this.aggregateData.length;

      } catch (error) {
        console.error('Error in handleFilterChange:', error);
        this.error = getDashboardErrorMessage(this.$store.state.organization);
      } finally {
        this.loading = false;
        this.isProcessingFilter = false;
      }
    },

    async refreshData() {
      if (process.env.NODE_ENV === "demo") {
        this.loading = false;
        return;
      }

      if (this.isProcessingFilter) return;
      
      this.loading = true;
      this.error = null;

      try {
        // Initialize data
        await Promise.all([
          this.$store.dispatch("getScans"),
          this.$store.dispatch("getContainers")
        ]);

        // Check containers
        if (!this.hasValidContainers) {
          this.noDataAvailable = true;
          return;
        }

        // Initialize filters and data
        if (this.$store.state.scans?.length > 0) {
          await this.$store.dispatch("fetchPlatformGovernanceFilterOptions");
          await this.handleFilterChange(this.$store.state.platformGovernanceFilters);
        } else {
          this.noDataAvailable = true;
        }

        this.isInitialized = true;
      } catch (error) {
        console.error('Error in refreshData:', error);
        this.error = getDashboardErrorMessage(this.$store.state.organization);
      } finally {
        this.loading = false;
      }
    },

    resetState() {
      this.error = null;
      this.loading = false;
      this.noDataAvailable = false;
      this.aggregateData = [];
      this.rulesData = [];
      this.currentFilters = null;
      this.isProcessingFilter = false;
    }
  },
  watch: {
    "$store.state.containers": {
      immediate: true,
      handler(newContainers) {
        if (!this.isInitialized || this.isProcessingFilter) return;
        
        if (newContainers?.error) {
          this.error = getDashboardErrorMessage(this.$store.state.organization);
        } else if (!this.hasValidContainers) {
          this.noDataAvailable = true;
        }
      }
    },
    "$store.state.scans": {
      immediate: true,
      async handler(newScans) {
        if (!newScans || !this.isInitialized || this.isProcessingFilter) return;
        
        if (newScans.error) {
          this.error = getDashboardErrorMessage(this.$store.state.organization);
          return;
        }

        if (newScans.length > 0 && this.hasValidContainers) {
          await this.handleFilterChange(this.$store.state.platformGovernanceFilters);
        } else if (!newScans.length) {
          this.noDataAvailable = true;
        }
      }
    },
    "$store.state.featurePermissions": {
      immediate: true,
      handler(newPermissions) {
        if (newPermissions?.platformGovernance === false) {
          this.$router.push("/");
        }
      }
    },
    "$route": {
      immediate: true,
      handler() {
        this.resetState();
      }
    }
  },
  beforeMount() {
    this.resetState();
  },
  mounted() {
    if (!this.$store.state.featurePermissions?.platformGovernance) {
      this.$router.push("/");
      return;
    }
    this.refreshData();
  },
  beforeUnmount() {
    this.resetState();
  },
  activated() {
    if (!this.isProcessingFilter && this.isInitialized) {
      this.refreshData();
    }
  },
  deactivated() {
    this.isProcessingFilter = false;
    this.loading = false;
    this.error = null;
  }
};
</script>

<style lang="scss">
@import "@/styles/_helpers.scss";

.platform-governance {
  .view-title-with-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
  }

  .site-message {
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    color: #721c24;
    padding: 1rem;
    border-radius: 4px;
    margin: 1rem 0;
  }
}
</style>