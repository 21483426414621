<template>
  <div>
    <AppliedFiltersTags 
      :filters="$store.state.platformGovernanceFilters"
      :onRemoveFilter="removeFilter"
    >
      <FilterDropdown
        id="platform-governance-filter"
        :filtersAppliedCount="$store.getters.platformGovernanceFiltersCount"
        :returnFiltersToDefault="returnFiltersToDefault"
        :disabled="loading"
      >
    <div class="platform-governance-filters">
      <!-- All Filters from API -->
      <template v-if="$store.state.platformGovernanceFilterOptions">
        <div 
          v-for="filter in $store.state.platformGovernanceFilterOptions" 
          :key="filter.dimension"
          class="select-with-label"
        >
          <label>{{ filter.dimension }}</label>
          <NSelect
            placeholder="All"
            :value="$store.state.platformGovernanceFilters[filter.dimension.toLowerCase()]"
            :options="getFilterOptions(filter.values)"
            :on-update:value="(value) => updateFilter(filter.dimension, value)"
          />
        </div>
      </template>
    </div>
  </FilterDropdown>
    </AppliedFiltersTags>
  </div>
</template>

<script>
import { NSelect } from "naive-ui";
import FilterDropdown from "@/components/Shared/FilterDropdown.vue";
import AppliedFiltersTags from "./AppliedFiltersTags.vue";

export default {
  name: "PlatformGovernanceFilter",
  components: {
    NSelect,
    FilterDropdown,
    AppliedFiltersTags,
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getFilterOptions(values) {
    if (!Array.isArray(values)) return [{ label: 'All', value: 'all' }];
    
    const options = [{ label: 'All', value: 'all' }];
    values.forEach(value => {
      if (value != null) { // Check for null/undefined values
        options.push({
          label: this.formatLabel(value),
          value: value
        });
      }
    });
    return options;
  },
  removeFilter(dimension) {
    const filterUpdate = {
      [dimension]: 'all'
    };
    this.$store.dispatch('updatePlatformGovernanceFilters', filterUpdate);
    this.emitFilterChange();
  },

    formatLabel(value) {
      // Handle null/undefined values
      if (value == null) return 'Unknown';
      
      // Convert numbers to strings
      const strValue = String(value);
      
      // If empty string, return default value
      if (!strValue.trim()) return 'Unknown';

      // Special handling for numbers that represent severity
      if (!isNaN(value)) {
        switch (strValue) {
          case "0": return "Info";
          case "1": return "Low";
          case "2": return "Medium";
          case "3": return "High";
          case "4": return "Severe";
          case "5": return "Critical";
          default: return strValue;
        }
      }

      // Handle regular strings
      try {
        return strValue.charAt(0).toUpperCase() + strValue.slice(1).toLowerCase();
      } catch (e) {
        console.error('Error formatting label:', e);
        return String(value);
      }
    },

    async updateFilter(dimension, value) {
    // Prevent unnecessary updates when selecting 'all'
    if (value === this.$store.state.platformGovernanceFilters[dimension.toLowerCase()]) {
      return;
    }

    const filterUpdate = {
      [dimension.toLowerCase()]: value
    };

    await this.$store.dispatch('updatePlatformGovernanceFilters', filterUpdate);
    this.emitFilterChange();
  },

  async returnFiltersToDefault() {
    await this.$store.dispatch('resetPlatformGovernanceFilters');
    this.emitFilterChange();
  },

    async emitFilterChange() {
    this.$emit('filter-change', this.$store.state.platformGovernanceFilters);
  }
  },
  async mounted() {
    await this.$store.dispatch('initializePlatformGovernanceFilters');
    this.emitFilterChange();
  },
  watch: {
    '$store.state.organization': async function() {
      // When org changes, we need to:
      // 1. Get new scans
      await this.$store.dispatch('getScans');
      // 2. Re-initialize filters with new data
      await this.$store.dispatch('initializePlatformGovernanceFilters');
    }
  }
};
</script>

<style lang="scss" scoped>
.platform-governance-filters {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  
  .select-with-label {
    width: 100%;
    min-width: 200px;
    max-width: none;
    
    label {
      margin-bottom: 0.5rem;
    }

    .n-select {
      width: 100%;
    }
  }
}
</style>