import axios from 'axios'
import store from '@/store/index.js'
const serviceBaseUrl = process.env.VUE_APP_METADATA_SERVICE_BASE_URL;

export default {
  async call(endpoint, data) {
    const apiCall = await axios.post(
      serviceBaseUrl,
      {
        "method": endpoint,
        "params": data
      },
      { 
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Bearer '+store.state.userData.accessToken
        }
      }
    );
    return apiCall.data;
  }
}