<template>
  <div :class="getClasses(size)"></div>
</template>

<script>
export default {
  name: "LoadingSpinner",
  props: {
    size: {
      type: String,
      required: false,
    },
  },
  methods: {
    getClasses(size) {
      let classes = {
        "loading-spinner": true,
        small: size === "small",
      };
      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.loading-spinner {
  display: inline-block;
  width: 60px;
  height: 60px;

  @keyframes loading-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &:after {
    content: " ";
    display: block;
    width: 26px;
    height: 26px;
    margin: 10px;
    border-radius: 50%;
    border: 4px solid $dark-gray;
    border-color: $dark-gray transparent $dark-gray transparent;
    animation: loading-spinner 1.2s linear infinite;
  }

  &.small {
    width: 12px;
    height: 12px;
    position: relative;
    top: 2px;
    &:after {
      width: 8px;
      height: 8px;
      margin: 0px;
      border: 2px solid $navy;
      border-color: $navy $navy $navy transparent;
      animation: loading-spinner 1.2s linear infinite;
    }
  }
}
</style>
