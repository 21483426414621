<template>
  <div class="big-number">
    <div v-bind:class="getNumberClasses()">{{ number }}</div>
    <div class="descriptor">{{ descriptor }}</div>
    <div class="descriptor" v-if="subdescriptor">{{ subdescriptor }}</div>
  </div>
</template>

<script>
export default {
  name: "BigNumber",
  props: {
    number: {
      type: [Number, String],
      required: true,
    },
    numberSize: {
      type: String,
      validator(value) {
        return ["large", "medium"].includes(value);
      },
      default: "large",
    },
    descriptor: {
      type: String,
      required: false,
    },
    subdescriptor: {
      type: String,
      required: false,
    },
  },
  methods: {
    getNumberClasses() {
      return {
        number: true,
        medium: this.numberSize === "medium",
        large: this.numberSize === "large",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.big-number {
  text-align: center;
  font-weight: 700;
  padding: 0.85rem;
  border-radius: $border-radius-1;
  background: $light-gray;
  margin-bottom: 1rem;
  color: $navy;

  .number {
    font-size: 48px;
    line-height: 1;
    color: currentcolor;

    &.medium {
      font-size: 26px;
      margin-bottom: 6px;
    }
  }

  .descriptor {
    font-size: 14px;
    font-weight: 300;
    color: currentcolor;
  }
}
</style>
