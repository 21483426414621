<template>
  <div class="display-svg">
    <InlineSvg :src="require(`@/assets/svgs/${name}.svg`)" />
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  components: { InlineSvg },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
