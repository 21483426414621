<template>
  <div class="small-striped-table">
    <div v-for="(item, index) in data" :key="`${item.id}-${index}`">
      <div>{{ item.label }}</div>
      <div>{{ item.value }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmallStripedTable",
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
.small-striped-table {
  > div {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(5, 1fr);
    flex-wrap: wrap;
    width: 100%;
    gap: 2rem;
    padding: 5px;
    color: $navy;
    &:nth-child(odd) {
      background-color: $light-gray;
    }
    > div {
      word-break: break-all;
      &:first-child {
        text-align: right;
        font-weight: 700;
        grid-column: span 2;
      }
      &:last-child {
        grid-column: span 3;
      }
    }
  }
}
</style>
