<template>
  <div class="widget spend-and-conversions-compared-to-cpa half">
    <div class="title-with-filter">
      <h2>
        Total Spend and Total Conversions Compared to CPA
        <NTooltip placement="bottom" trigger="hover">
          <template #trigger>
            <DisplaySvg name="info" class="info-icon" />
          </template>
          <div class="info-text">All Data Sourced from DV360</div>
        </NTooltip>
      </h2>
    </div>
    <div class="graph-wrapper">
      <div
        id="spend-and-conversions-compared-to-cpa-container"
        class="custom-legend"
      ></div>
      <div class="graph">
        <Bar
          :data="chartData"
          :options="chartOptions"
          :style="chartStyles"
          :plugins="chartPlugins"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs";
import "chartjs-adapter-date-fns";
import { generateCustomLegend } from "@/Helpers.js";
import DisplaySvg from "@/components/Shared/DisplaySvg";
import { NTooltip } from "naive-ui";

export default {
  name: "ImpressionsKPIByScoreGraphs",
  components: {
    Bar,
    NTooltip,
    DisplaySvg,
  },
  props: ["chartData"],
  data() {
    const colors = {
      yellow: "#e0c019",
      orange: "#f45d29",
      blue: "#1462b8",
      lightBlue: "#68B0FF",
      white: "#ffffff",
      mediumGray: "#cccccc",
      navy: "#143755",
      teal: "#93d5c5",
      darkTeal: "#2d977d",
    };

    const formatCurrency = (value, dec) => {
      let nf = new Intl.NumberFormat("en-US");
      return `$${nf.format(((value * 100) / 100).toFixed(dec))}`;
    };

    const formatLargeNumber = (value) => {
      // Nine Zeroes for Billions
      return Math.abs(Number(value)) >= 1.0e9
        ? (Math.abs(Number(value)) / 1.0e9).toFixed(2) + " Bil"
        : // Six Zeroes for Millions
        Math.abs(Number(value)) >= 1.0e6
        ? (Math.abs(Number(value)) / 1.0e6).toFixed(0) + " Mil"
        : // Three Zeroes for Thousands
        Math.abs(Number(value)) >= 1.0e3
        ? (Math.abs(Number(value)) / 1.0e3).toFixed(0) + " K"
        : Math.abs(Number(value));
    };

    // custom legend
    const htmlLegendPlugin = generateCustomLegend("media-performance");

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          display: true,
        },
        y1: {
          type: "linear",
          position: "left",
          title: {
            display: true,
            text: "Total Spend ($) and Total Conversions",
            padding: 10,
            font: {
              family: "'Roboto', sans-serif",
              size: 16,
              weight: "bolder",
            },
          },
        },
        y2: {
          type: "linear",
          position: "right",
          title: {
            display: true,
            text: "CPA",
            padding: 10,
            font: {
              family: "'Roboto', sans-serif",
              size: 16,
              weight: "bolder",
            },
          },
          // grid line settings
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
      },
      plugins: {
        htmlLegend: {
          // ID of the container to put the legend in
          containerID: "spend-and-conversions-compared-to-cpa-container",
        },
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: colors.white,
          titleColor: colors.navy,
          bodyColor: colors.navy,
          footerColor: colors.navy,
          usePointStyle: true,
          boxPadding: 5,
          boxWidth: 10,
          boxHeight: 10,
          borderColor: colors.mediumGray,
          borderWidth: 1,
          font: {
            family: "'Roboto', sans-serif",
            size: 13,
          },
          callbacks: {
            beforeTitle: function (context) {
              return context[0].dataset.label;
            },
            label: function (context) {
              if (context.dataset.label === "Total Spend") {
                return `${formatCurrency(context.parsed.y, 0)}`;
              }
              if (context.dataset.label === "CPA") {
                return `${formatCurrency(context.parsed.y, 2)}`;
              }
              return context.formattedValue;
            },
          },
        },
      },
    };

    // format tick values
    chartOptions.scales.y1.ticks = {
      callback: function (val) {
        return formatLargeNumber(val);
      },
    };

    chartOptions.scales.y2.ticks = {
      callback: function (val) {
        return formatCurrency(val, 2);
      },
    };

    return {
      chartPlugins: [htmlLegendPlugin],
      chartOptions: chartOptions,
      chartStyles: {},
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
.spend-and-conversions-compared-to-cpa {
  display: block;

  .info-icon {
    height: 20px;
    width: 20px;
    display: inline-block;
    position: relative;
    top: 1px;
    margin-left: 5px;
  }

  .title-with-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 30px;
    overflow: auto;
    min-width: 330px;

    h2 {
      text-align: center;
      width: 100%;
      margin: 10px 0 0 0;
      line-height: 30px;
      max-width: 390px;
      font-size: 24px;
      font-weight: 500;
      margin: 0 auto;
    }
    .filter-button {
      width: 110px;
    }
    .filter-icon {
      height: 16px;
      width: 16px;
      margin-right: 10px;
      position: relative;
      top: 2px;
    }
  }
}

.graphsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0;
  min-width: 510px;
  overflow-x: auto;
  overflow-y: hidden;

  @include media($large-lowest, down) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.graph-wrapper {
  width: 100%;
  min-width: 330px;
}

.graph {
  height: 320px;
}
</style>
