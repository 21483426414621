<template>
  <div class="widget half">
    <h2 class="title">Sites With Most Violations</h2>
    <div class="table">
      <div class="row header">
        <div class="website">Site</div>
        <div class="num">Violations</div>
      </div>
      <div class="row" v-for="site in improvedSites" :key="site.siteName">
        <div class="website">{{ site.siteName }}</div>
        <div class="num">{{ site.tagViolating }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import consentImprovedSitesFixture from "@/fixtures/consentImprovedSites.json";
import MetadataService from "@/services/MetadataService";
import { mapDataToReadable } from "@/Helpers.js";
import { getConsentErrorMessage } from "@/ErrorMessaging";

export default {
  name: "ViolationsByConsentTypeChart",
  components: {},
  watch: {
    "$store.state.containers": function () {
      if (
        (this.$store.state.containers &&
          this.$store.state.containers.length > 0) ||
        process.env.NODE_ENV === "demo"
      ) {
        this.getData();
      }
    },
  },
  data() {
    return {
      improvedSites: [],
    };
  },
  methods: {
    getData() {
      if (process.env.NODE_ENV === "demo") {
        this.improvedSites = consentImprovedSitesFixture;
      } else {
        if (
          !this.$store.state.consentMonitoringContainer ||
          !this.$store.state.consentMonitoringContainer.containerid
        ) {
          this.triggerError({
            message:
              "MostImprovedWidget - this.$store.state.consentMonitoringContainer.containerid UNDEFINED",
          });
          return Promise.resolve();
        }

        MetadataService.call("runConsentMonitoringQuery", {
          containerid: this.$store.state.consentMonitoringContainer.containerid,
          query: `
          SELECT
          domainName,
          COUNT(DISTINCT(tagViolating)) as violatingTags
          FROM __dashboard_view__
          GROUP BY domainName
          ORDER BY violatingTags DESC
          LIMIT 5;
          `,
        })
          .then((response) => {
            if (response.error) {
              this.triggerError({
                message:
                  "runConsentMonitoringQuery - MostImprovedWidget - error obj returned from api",
                error: response.error,
              });
              return Promise.resolve();
            }
            const lookup = {
              0: "siteName",
              1: "tagViolating",
            };
            this.improvedSites = mapDataToReadable(
              response.result.queryResult.data,
              lookup
            );
          })
          .catch((error) => {
            this.triggerError({
              message: "runConsentMonitoringQuery - MostImprovedWidget - catch",
              error: error,
            });
          });
      }
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.error = getConsentErrorMessage(this.$store.state.organization);
    },
  },
  mounted() {
    if (
      (this.$store.state.containers &&
        this.$store.state.containers.length > 0) ||
      process.env.NODE_ENV === "demo"
    ) {
      this.getData();
    }
  },
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
}
.table {
  width: 100%;
  max-width: 400px;
  margin: 30px 0;

  > div.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    margin: 0px;
    border-bottom: 1px solid #ccc;
  }

  .header {
    .num,
    .website {
      font-weight: 700;
    }
  }

  .website {
    width: 60%;
    color: $navy;
    font-size: 16px;
    line-height: 20px;
    word-break: break-all;
    padding-right: 10px;
  }
}
</style>
