<template>
  <div class="view-container whats-new">
    <section class="view-header">
      <h1 class="view-title">What's New With AdTech DNA</h1>
    </section>

    <div class="release" v-if="error || loading">
      <div class="site-error" v-if="error || !loading">{{ error }}</div>
      <div class="site-loading" v-if="!error && loading">
        <LoadingSpinner /> Loading...
      </div>
    </div>

    <div class="release" v-for="(release, index) in releases" :key="index">
      <span class="tag">ADTECH DNA: {{ release.tag_name }}</span>
      <h2>{{ release.name }}</h2>
      <span class="date">{{ formatDate(release.published_at) }}</span>
      <vue-markdown
        :source="release.body"
        :options="{ html: true }"
      ></vue-markdown>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import VueMarkdown from "vue-markdown-render";
import moment from "moment";

export default {
  name: "WhatsNew",
  components: {
    VueMarkdown,
    LoadingSpinner,
  },
  watch: {
    "$store.state.githubReleaseData": function () {
      this.$nextTick(async () => {
        if (
          this.$store.state.githubReleaseData &&
          this.$store.state.githubReleaseData[0] &&
          !this.$store.state.githubReleaseData[0].error
        ) {
          this.releases = this.$store.state.githubReleaseData;
          this.loading = false;
          this.updateLocalStorage(this.$store.state.githubReleaseData);
        }

        if (
          this.$store.state.githubReleaseData &&
          this.$store.state.githubReleaseData[0] &&
          this.$store.state.githubReleaseData[0].error
        ) {
          this.error = this.$store.state.githubReleaseData[0].error;
          this.loading = false;
        }
      });
    },
  },
  data() {
    return {
      loading: true,
      error: null,
      releases: [],
      NODE_ENV: process.env.NODE_ENV,
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("MMMM Do, YYYY");
    },
    updateLocalStorage(githubReleaseData) {
      const updatedAdtechReleases = githubReleaseData.map((item) => {
        return item.id;
      });
      localStorage.setItem(
        "ADTECH_RELEASES",
        JSON.stringify(updatedAdtechReleases)
      );
      this.$store.commit("setWhatsNewCount", 0);
    },
  },
  mounted() {
    if (
      this.$store.state.githubReleaseData &&
      this.$store.state.githubReleaseData[0] &&
      !this.$store.state.githubReleaseData[0].error
    ) {
      this.releases = this.$store.state.githubReleaseData;
      this.loading = false;
      this.updateLocalStorage(this.$store.state.githubReleaseData);
    }

    if (
      this.$store.state.githubReleaseData &&
      this.$store.state.githubReleaseData[0] &&
      this.$store.state.githubReleaseData[0].error
    ) {
      this.error = this.$store.state.githubReleaseData[0].error;
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/_helpers.scss";
.whats-new {
  .view-header {
    width: 100%;
    text-align: center;
  }
  .release {
    width: 100%;
    max-width: 790px;
    margin: 0 auto;
    background: $white;
    border-radius: 15px;
    padding: 1.5rem 2.5rem;
    box-shadow: $shadow;
    margin-bottom: 30px;
    color: $navy;
    h3 {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      margin: 10px 0;
    }
    p,
    ol,
    ul {
      margin: 10px 0;
    }
  }
  h2 {
    font-size: 28px;
    line-height: 36px;
    margin: 0;
    font-weight: 500;
  }
  > div {
    h2 {
      font-size: 24px;
      line-height: 32px;
      margin: 0;
      font-weight: 500;
    }
  }
  .tag {
    margin: 10px 0 0 0;
    color: $green;
    font-weight: 500;
    display: block;
    font-size: 18px;
    line-height: 26px;
  }
  .date {
    color: $dark-gray;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 30px;
    margin-top: 3px;
    display: block;
  }
}
</style>
